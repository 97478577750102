import { TFunction } from 'i18next';

const formatHours = (date: Date, t: TFunction, withSpace = false) => {
    if (!date) return;
    const hour = date.getHours();
    let min = String(date.getMinutes());
    if (min.length === 1) {
        min = `0${min}`;
    }
    const formattedHours = t('formattedHours', { hour, min });

    return withSpace ? formattedHours : formattedHours.replaceAll(' ', '');
};
export default formatHours;
