import { Project } from 'src/core/project/domain';

const getStatusKey = (project: Project | null) => {
    // console.log('project.status', project.status);
    if (!project) {
        return '';
    }

    let result: string = project.status;
    if (project.status === 'delivery_and_installation') {
        let deliveryCompleted = true;
        // if deliveries are expected
        if (project.deliveries && project.deliveries.length > 0) {
            // check if all deliveries received
            for (let i = 0; i < project.deliveries.length; i++) {
                if (project.deliveries[i].status !== 'received') {
                    deliveryCompleted = false;
                }
            }
        }
        console.log('isDeliveryCompleted', deliveryCompleted);
        // all delivery is received
        if (deliveryCompleted) {
            // check if survey is completed
            let isSurveyCompleted = false;

            if (project.survey && project.survey.status === 'completed') {
                isSurveyCompleted = true;
            }

            if (project.documents && project.documents.length > 0) {
                for (let i = 0; i < project.documents.length; i++) {
                    if (project.documents[i].type === 'survey') {
                        isSurveyCompleted = true;
                    }
                }
            }
            console.log('isSurveyCompleted', isSurveyCompleted);

            // check if pre-installation form is completed
            let isPreInstallationFormCompleted = false;
            // check if documents of type pre-installation are uploaded in the documents list
            if (project.documents && project.documents.length > 0) {
                for (let i = 0; i < project.documents.length; i++) {
                    if (project.documents[i].type === 'pre-installation') {
                        isPreInstallationFormCompleted = true;
                    }
                }
            }
            console.log('isPreInstallationFormCompleted', isPreInstallationFormCompleted);

            /* OLD CODE
            if (
                project.installation?.preInstallationStatus === 'completed' ||
                project.installation?.preInstallationStatus === 'signed'
            ) {
                isPreInstallationFormCompleted = true;
            }
            */

            // is signed off sheet signed
            /*
            let isSignOffSheetCompleted = false;
            if (project.documents && project.documents.length > 0) {
                for (let i = 0; i < project.documents.length; i++) {
                    if (project.documents[i].type === 'sign-off-sheet') {
                        isSignOffSheetCompleted = true;
                    }
                }
            }
            console.log('isSignOffSheetCompleted', isSignOffSheetCompleted);
            */
            // checking if installation is planned
            if (project.installation && project.installation.startDate) {
                // check installation start date
                if (new Date(project.installation.startDate) <= new Date()) {
                    // all received + installation planned and start date in the past
                    if (!isSurveyCompleted && !isPreInstallationFormCompleted) {
                        // no survey and no pre-installation form
                        result = 'waiting_for_pre_installation_form';
                    } else {
                        result = 'ready_to_install'; // all good to go
                    }
                } else {
                    // all received + installation planned but start date in the future
                    result = 'installation_planned';
                }
            } else {
                // no installation planned
                result = 'installation_to_plan';
            }
        } else {
            // not all deliveries are received
            result = 'shipping_in_progress';
        }
    }

    return result;
};
export default getStatusKey;
