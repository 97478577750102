import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en.json';
import fr from './translations/fr.json';
import es from './translations/es.json';
import { PropsWithChildren, useEffect } from 'react';
import { useAppAuth } from './contexts/auth-context';

i18next.use(initReactI18next).init({
    fallbackLng: 'fr',
    debug: false,
    resources: {
        en: {
            translation: en,
        },
        fr: {
            translation: fr,
        },
        es: {
            translation: es,
        },
    },
});
const supportedLanguage = ['en', 'fr', 'es'];
let authLang: string | undefined;
export function I18n(props: PropsWithChildren<{}>) {
    const { user } = useAppAuth();
    authLang = user?.ip_app_metadata?.lang;

    useEffect(() => {
        let userSelectedLang: string | null = sessionStorage.getItem('userSelectedLang');
        let lang: string = '';
        const [urlLang] = window.location.pathname.slice(1).split('/');
        if (supportedLanguage.includes(urlLang)) {
            lang = urlLang;
        } else if (userSelectedLang !== null) {
            lang = userSelectedLang;
        } else if (authLang !== undefined) {
            lang = authLang;
        } else if (supportedLanguage.includes(navigator.language.slice(0, 2))) {
            lang = navigator.language.slice(0, 2);
        }
        if (lang !== '') {
            changeLanguage(lang);
        }
    }, [user]);

    return <>{props.children}</>;
}

export function changeLanguage(lang: string) {
    // Not an elegant way to do, should be changed
    if (lang === undefined || lang === '' || !supportedLanguage.includes(lang)) {
        return;
    }
    i18next.changeLanguage(lang, (err, t) => {
        if (err) return console.error('something went wrong loading', err);
    });
}

export function setUserLang(lang: string) {
    sessionStorage.setItem('userSelectedLang', lang);
    changeLanguage(lang);
}

export function getUserLang(): string {
    return sessionStorage.getItem('userSelectedLang') ?? authLang ?? '';
}

export function getUrlLang(): string {
    let [urlLang] = window.location.pathname.slice(1).split('/');
    if (supportedLanguage.includes(urlLang)) {
        return '/' + urlLang;
    }
    return '';
}
