import { Stack } from '@mui/joy';
import React, { useContext, useEffect } from 'react';
import { ProjectsContext } from '../contexts/project-context';
import { projectActions } from 'src/core/project/service';
import { PageTemplate } from '../layout/PageTemplate';
import { useParams } from 'react-router-dom';
import BackButton from '../molecules/BackButton';
import Loader from '../molecules/Loader';
import GenericDataCard from '../molecules/projects/GenericDataCard';
import MissionCard from '../molecules/projects/MissionCard';
import HistoryCard from '../molecules/projects/HistoryCard';
import { useAppAuth } from '../contexts/auth-context';

export function ProjectGenericDataPage() {
    const { id } = useParams();
    const { authenticatedCall } = useAppAuth();
    const { projectState, dispatch } = useContext(ProjectsContext)!;

    useEffect(() => {
        if (id) {
            authenticatedCall(async (accessToken) => dispatch(projectActions.loadOne(accessToken, id)));
        }
    }, [dispatch, id, authenticatedCall]);

    return (
        <PageTemplate greyBackground>
            <Stack
                sx={{ height: '100%', paddingX: '20px', paddingY: '40px', gap: '16px' }}
                data-test-id="generic-data-page"
            >
                <BackButton path={`/projects/${projectState?.project?.id}`} label="projects.generic_data.back" />
                {projectState.project && projectState.project.id === id ? (
                    <>
                        <GenericDataCard project={projectState.project} />
                        <MissionCard project={projectState.project} />
                        <HistoryCard project={projectState.project} />
                    </>
                ) : (
                    <Loader />
                )}
            </Stack>
        </PageTemplate>
    );
}
