import { Alert, Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import { projectActions } from 'src/core/project/service';
import LeftBlackArrowIcon from 'src/ui/atoms/icons/LeftBlackArrow';
import { useAppAuth } from 'src/ui/contexts/auth-context';
import { ProjectsContext } from 'src/ui/contexts/project-context';
import { useCallback, useContext, useState } from 'react';
import { DateValue } from './DateModal';
import isDate from 'src/utils/isDate';

interface ModalButtonsProps {
    step: number;
    setStep: Function;
    checkNextDisabled: Function;
    setOpen: Function;
    projectId: string;
    startDate: DateValue;
    endDate: DateValue;
    initialize: Function;
    entity: 'survey' | 'installation';
    finalStep: number;
}

export default function ModalButtons({
    step,
    setStep,
    setOpen,
    checkNextDisabled,
    projectId,
    startDate,
    endDate,
    initialize,
    entity,
    finalStep,
}: ModalButtonsProps) {
    const { t } = useTranslation();
    const { authenticatedCall } = useAppAuth();
    const [error, setError] = useState<string | null>(null);
    const { dispatch } = useContext(ProjectsContext)!;
    const saveDates = useCallback(() => {
        const process = async () => {
            if (isDate(startDate) && isDate(endDate)) {
                try {
                    await authenticatedCall(async (accessToken) => {
                        if (entity === 'survey') {
                            await dispatch(projectActions.updateSurvey(accessToken, projectId, startDate, endDate));
                        } else if (entity === 'installation') {
                            await dispatch(
                                projectActions.updateInstallation(accessToken, projectId, startDate, endDate),
                            );
                        }
                    });
                    setOpen(false);
                    initialize();
                } catch (e: any) {
                    setError(e.error);
                }
            }
        };

        process();
    }, [startDate, endDate, authenticatedCall, dispatch, projectId, setOpen, initialize, entity]);

    return (
        <Stack alignItems="center">
            {step === finalStep ? (
                <>
                    <Button
                        color="primary"
                        sx={{ marginTop: '20px', marginBottom: '12px', width: '100%' }}
                        label={t(`date_modal.save`)}
                        onClick={saveDates}
                        data-test-id="save"
                    />
                    <Button
                        color="grey"
                        variant="outlined"
                        onClick={() => setStep(step - 1)}
                        label={t(`date_modal.modify`)}
                        sx={{ width: '100%' }}
                    />
                    {error && (
                        <Alert color="danger" sx={{ marginTop: '12px' }}>
                            {error}
                        </Alert>
                    )}
                </>
            ) : (
                <>
                    <Stack width="100%" direction="row" justifyContent="space-between">
                        {step !== 1 && (
                            <Button
                                color="secondary"
                                variant="soft"
                                sx={{ marginTop: '20px', marginBottom: '12px', width: '40px', padding: 0 }}
                                onClick={() => setStep(step - 1)}
                            >
                                <LeftBlackArrowIcon />
                            </Button>
                        )}
                        <Button
                            color="primary"
                            sx={{
                                marginTop: '20px',
                                marginBottom: '12px',
                                width: step !== 1 ? 'calc(100% - 45px)' : '100%',
                            }}
                            label={t('next')}
                            disabled={checkNextDisabled()}
                            onClick={() => setStep(step + 1)}
                            data-test-id="next"
                        />
                    </Stack>
                    <Button color="grey" variant="underlined" onClick={() => setOpen(false)} label={t('cancel')} />
                </>
            )}
        </Stack>
    );
}
