import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';

export const LoginButton = () => {
    const { t } = useTranslation();
    const { loginWithRedirect } = useAuth0();

    const handleLogin = async () => {
        sessionStorage.clear();
        await loginWithRedirect({
            appState: {
                returnTo: '/projects',
            },
        });
    };

    return (
        <Button onClick={handleLogin} data-test-id="login">
            {t('auth.signin_button')}
        </Button>
    );
};
