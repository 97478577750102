import { ActionExtractor, createAction } from '../action';
import { ProjectDataSource } from './data-source';
import { Billing, Delivery, Document, Project, Quote } from './domain';
import { AppUser } from 'src/ui/contexts/auth-context';
import { DocumentDto } from 'src/ui/molecules/documents/DocumentForm';

// Actions
export const projectActions = {
    loadAll: (accessToken: string) => createAction('loadAll', { accessToken }),
    loadOne: (accessToken: string, id: string) => createAction('loadOne', { accessToken, id }),
    accept: (accessToken: string, id: string) => createAction('accept', { accessToken, id }),
    refuse: (accessToken: string, id: string) => createAction('refuse', { accessToken, id }),
    updateStatus: (accessToken: string, id: string, status: string) =>
        createAction('updateStatus', { accessToken, id, status }),
    updateSurvey: (accessToken: string, id: string, startDate: Date, endDate: Date, status?: string) =>
        createAction('updateSurvey', { accessToken, id, startDate, endDate, status }),
    createQuote: (
        accessToken: string,
        projectId: string,
        reference: string,
        amount: number,
        description: string,
        documents: Array<DocumentDto>,
    ) => createAction('createQuote', { accessToken, projectId, reference, amount, description, documents }),
    createBilling: (
        accessToken: string,
        projectId: string,
        reference: string,
        amount: number,
        vatRate: number,
        documents: Array<DocumentDto>,
        purchaseOrderId?: string,
    ) =>
        createAction('createBilling', {
            accessToken,
            projectId,
            reference,
            amount,
            vatRate,
            documents,
            purchaseOrderId,
        }),

    createSurvey: (accessToken: string, id: string, file: string) =>
        createAction('createSurvey', { accessToken, id, file }),
    updateInstallation: (accessToken: string, id: string, startDate: Date, endDate: Date, status?: string) =>
        createAction('updateInstallation', { accessToken, id, startDate, endDate, status }),
    updateDelivery: (accessToken: string, delivery: Delivery) =>
        createAction('updateDelivery', { accessToken, delivery }),
    updateDeliveryStatus: (accessToken: string, delivery: Delivery) =>
        createAction('updateDeliveryStatus', { accessToken, delivery }),
    setCurrentPage: (page: number) => createAction('setCurrentPage', { page }),
    changeUserLanguage: (accessToken: string, lang: string) =>
        createAction('changeUserLanguage', { accessToken, lang }),
    retrieveFileByS3Key: (accessToken: string, projectId: string, s3Key: string) =>
        createAction('retrieveFileByS3Key', { accessToken, projectId, s3Key }),
    createDocuments: (accessToken: string, documents: Array<DocumentDto>) =>
        createAction('createDocuments', { accessToken, documents }),
};

export type ProjectAction = ActionExtractor<typeof projectActions>;

// State
export type ProjectState = {
    project: Project | null;
    user: AppUser | null;
    projects: Array<Project>;
    documents: { [id: string]: string | null };
    files: { [id: string]: string | null };
    error: unknown;
    page: number;
};

export const projectInitialState: ProjectState = {
    project: null,
    user: {},
    projects: [],
    documents: {},
    files: {},
    error: undefined,
    page: 1,
};

// Reducer
export type ProjectReducer = (state: ProjectState, action: ProjectAction) => Promise<ProjectState>;
export const projectReducer =
    (projectDatasource: ProjectDataSource) =>
    async (state: ProjectState, action: ProjectAction): Promise<ProjectState> => {
        switch (action.type) {
            case 'loadAll': {
                return {
                    ...state,
                    projects: await projectDatasource.retrieveProjects(action.payload.accessToken),
                };
            }
            case 'loadOne': {
                const project = await projectDatasource.retrieveProject(action.payload.accessToken, action.payload.id);
                return {
                    ...state,
                    project: project,
                };
            }
            case 'accept': {
                return {
                    ...state,
                    project: await projectDatasource.acceptProject(action.payload.accessToken, action.payload.id),
                };
            }
            case 'refuse': {
                return {
                    ...state,
                    project: await projectDatasource.refuseProject(action.payload.accessToken, action.payload.id),
                };
            }
            case 'updateStatus': {
                return {
                    ...state,
                    project: await projectDatasource.updateStatus(
                        action.payload.accessToken,
                        action.payload.id,
                        action.payload.status,
                    ),
                };
            }
            case 'updateSurvey': {
                return {
                    ...state,
                    project: await projectDatasource.updateSurvey(
                        action.payload.accessToken,
                        action.payload.id,
                        action.payload.startDate,
                        action.payload.endDate,
                        action.payload.status,
                    ),
                };
            }
            case 'updateInstallation': {
                return {
                    ...state,
                    project: await projectDatasource.updateInstallation(
                        action.payload.accessToken,
                        action.payload.id,
                        action.payload.startDate,
                        action.payload.endDate,
                        action.payload.status,
                    ),
                };
            }
            case 'updateDelivery': {
                let updatedDelivery: Delivery | null = await projectDatasource.updateDelivery(
                    action.payload.accessToken,
                    action.payload.delivery,
                );
                if (updatedDelivery != null && state.project) {
                    const deliveries = state.project.deliveries.map((delivery) =>
                        delivery.id === updatedDelivery?.id
                            ? { ...updatedDelivery, items: action.payload.delivery.items }
                            : delivery,
                    );

                    return {
                        ...state,
                        project: {
                            ...state.project,
                            deliveries: deliveries,
                        },
                    };
                }
                return {
                    ...state,
                };
            }
            case 'updateDeliveryStatus': {
                let updatedDelivery: Delivery | null = await projectDatasource.updateDeliveryStatus(
                    action.payload.accessToken,
                    action.payload.delivery,
                );
                if (updatedDelivery != null && state.project) {
                    const deliveries = state.project.deliveries.map((delivery) =>
                        delivery.id === updatedDelivery?.id
                            ? { ...updatedDelivery, items: action.payload.delivery.items }
                            : delivery,
                    );

                    return {
                        ...state,
                        project: {
                            ...state.project,
                            deliveries: deliveries,
                        },
                    };
                }
                return {
                    ...state,
                };
            }
            case 'createQuote': {
                // call create quote API
                const newQuote: Quote | null = await projectDatasource.createQuote(
                    action.payload.accessToken,
                    action.payload.projectId,
                    action.payload.reference,
                    action.payload.amount,
                    action.payload.description,
                );

                if (newQuote) {
                    let newDocuments: Document[] = [];
                    // process documents in sequence
                    for (let document of action.payload.documents) {
                        // set the new billing id as parent entity id on the document
                        // and call create document API
                        document.parentEntityId = newQuote.id;
                        document.title = `${newQuote.reference} - ${document.name}`;

                        const newDocument: Document | null = await projectDatasource.createDocument(
                            action.payload.accessToken,
                            document,
                        );
                        // append document object to the project documents array
                        if (newDocument) {
                            newDocuments.push(newDocument);
                        }
                    }

                    return {
                        ...state,
                        project: {
                            ...state.project!,
                            quotes: [...state.project!.quotes, newQuote],
                            documents: [...state.project!.documents, ...newDocuments],
                        },
                    };
                }

                return state;
            }
            case 'createBilling': {
                // call create billing API
                const newBilling: Billing | null = await projectDatasource.createBilling(
                    action.payload.accessToken,
                    action.payload.projectId,
                    action.payload.reference,
                    action.payload.amount,
                    action.payload.vatRate,
                    action.payload.purchaseOrderId,
                );

                if (newBilling) {
                    let newDocuments: Document[] = [];
                    // process documents in sequence

                    for (let i = 0; i < action.payload.documents.length; i++) {
                        // set the new billing id as parent entity id on the document
                        // and call create document API
                        action.payload.documents[i].parentEntityId = newBilling.id;
                        action.payload.documents[i].title = `${newBilling.reference} ${i + 1}`;

                        const newDocument: Document | null = await projectDatasource.createDocument(
                            action.payload.accessToken,
                            action.payload.documents[i],
                        );
                        // append document object to the project documents array
                        if (newDocument) {
                            newDocuments.push(newDocument);
                        }
                    }

                    return {
                        ...state,
                        project: {
                            ...state.project!,
                            billings: [...state.project!.billings, newBilling],
                            documents: [...state.project!.documents, ...newDocuments],
                        },
                    };
                }

                return state;
            }
            case 'createSurvey': {
                const newSurvey = await projectDatasource.createSurvey(action.payload.accessToken, action.payload.id);
                if (newSurvey) {
                    return {
                        ...state,
                        project: {
                            ...state.project!,
                            survey: newSurvey,
                        },
                    };
                }

                return state;
            }
            case 'setCurrentPage': {
                return {
                    ...state,
                    page: action.payload.page,
                };
            }
            case 'changeUserLanguage': {
                return {
                    ...state,
                    user: await projectDatasource.changeUserLanguage(action.payload.accessToken, action.payload.lang),
                };
            }

            case 'retrieveFileByS3Key': {
                const fileContent = await projectDatasource.retrieveFileByS3Key(
                    action.payload.accessToken,
                    action.payload.projectId,
                    action.payload.s3Key,
                );
                return {
                    ...state,
                    files: {
                        ...state.files!,
                        [action.payload.s3Key]: fileContent,
                    },
                };
            }
            case 'createDocuments': {
                const newDocuments: any[] = [];

                // process files in sequence
                for (let document of action.payload.documents) {
                    const newFile = await projectDatasource.createDocument(action.payload.accessToken, document);
                    if (document) {
                        newDocuments.push(newFile);
                    }
                }

                if (newDocuments) {
                    return {
                        ...state,
                        project: {
                            ...state.project!,
                            documents: state.project?.documents!.concat(...newDocuments)!,
                        },
                    };
                }

                return state;
            }
        }
    };
