import { Project } from 'src/core/project/domain';
import TwoActionsCard from '../TwoActionsCard';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/joy';
import { ReactNode, useContext, useState } from 'react';
import DateModal from '../modal/DateModal';
import formatDate from '../../../utils/formatDate';
import Toaster from '../Toaster';
import { useNavigate } from 'react-router-dom';
import { getUserLang } from 'src/ui/i18n';
import { ProjectsContext } from 'src/ui/contexts/project-context';

export default function TechnicalActionsCard({ project }: { project: Project }) {
    const { t } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarText] = useState<string>(t('survey.file_not_found'));
    const navigate = useNavigate();
    const { projectState } = useContext(ProjectsContext)!;

    let primaryLabel: string | undefined;
    let secondaryLabel: string | undefined;
    let title: string | ReactNode | undefined;
    let subtitle: string | ReactNode | undefined;
    let onPrimaryButtonClick: Function | undefined;
    let onSecondaryButtonClick: Function | undefined = () => {};
    let primaryRightArrow: boolean | undefined;
    let secondaryRightArrow: boolean | undefined;
    let primaryActionDataTestId: string = 'primary-action';
    let secondaryActionDataTestId: string = 'secondaryAction';

    // production : https://installer.chargeguru.com
    const inProduction = window.location.hostname && window.location.hostname === 'installer.chargeguru.com';
    // qar url for production by default
    let qarUrl = 'https://qar.chargeguru.com/inputDate.php?opportunityId=' + project.reference;
    // tous les autres
    if (!inProduction) {
        qarUrl =
            'https://staging-qar.chargeguru.com/inputDate.php?opportunityId=' + project.reference + '&sandbox=integ';
    }

    if (project.status === 'survey_to_plan') {
        const mandatory = project.surveyMandatory;
        onSecondaryButtonClick = undefined;
        secondaryLabel = `survey.plan_${mandatory ? 'mandatory' : 'non_mandatory'}_manual_action`;
        title = `survey.plan_${mandatory ? 'mandatory' : 'non_mandatory'}_title`;
        subtitle = `survey.plan_${mandatory ? 'mandatory' : 'non_mandatory'}_subtitle`;

        if (mandatory) {
            // standard path
            primaryLabel = 'survey.plan_mandatory_action';
            onPrimaryButtonClick = () => {
                window.open(qarUrl, '_blank');
            };
            primaryRightArrow = true;
            primaryActionDataTestId = 'plan_survey';
        } else {
            secondaryRightArrow = true;
            secondaryActionDataTestId = 'plan_survey';
            onSecondaryButtonClick = () => {
                window.open(qarUrl, '_blank');
            };
        }
    } else if (project.status === 'survey_planned') {
        onPrimaryButtonClick = () => {
            navigate('survey');
        };
        primaryActionDataTestId = 'start_survey';

        onSecondaryButtonClick = () => {
            window.open(qarUrl, '_blank');
        };
        secondaryActionDataTestId = 'plan_survey';

        title = (
            <Stack>
                <Typography level="h4">{t('survey.planned_title')}</Typography>
                {/* {project.survey && project.survey.startDate && (
                    <Typography level="h4">{formatDate(project.survey.startDate, false, true)}</Typography>
                )} */}
            </Stack>
        );
        subtitle = (
            <Stack>
                <Typography level="body-lg">{project.address.street1}</Typography>
                <Typography level="body-lg">
                    {project.address.postalCode}&nbsp;{project.address.city}
                </Typography>
            </Stack>
        );
        secondaryLabel = 'survey.planned_modify_action';
        primaryRightArrow = true;
        primaryLabel = 'survey.planned_action';
    } else if (!['project_proposed', 'project_rejected', 'survey_to_plan', 'survey_planned'].includes(project.status)) {
        onPrimaryButtonClick = () => {
            navigate('survey');
        };
        onSecondaryButtonClick = undefined;
        title = (
            <Stack>
                <Typography level="h4">{t('survey.conducted')}</Typography>
            </Stack>
        );
        subtitle = t('survey.conducted_date', {
            date: formatDate(
                project.survey?.startDate,
                false,
                true,
                getUserLang(),
                projectState.project?.address.countryCode,
            ),
        });
        primaryLabel = 'survey.see_files';
        primaryActionDataTestId = 'download_survey_pdf';
        // color = 'secondary';
    } else {
        return;
    }
    return (
        <>
            <TwoActionsCard
                onPrimaryButtonClick={onPrimaryButtonClick}
                onSecondaryButtonClick={onSecondaryButtonClick}
                primaryActionDataTestId={primaryActionDataTestId}
                secondaryActionDataTestId={secondaryActionDataTestId}
                primaryLabel={primaryLabel}
                secondaryLabel={secondaryLabel}
                title={title}
                subtitle={subtitle}
                primaryRightArrow={primaryRightArrow}
                secondaryRightArrow={secondaryRightArrow}
            />
            <DateModal open={open} setOpen={setOpen} projectId={project.id} entity="survey" askEndDate={false} />
            <Toaster text={snackbarText} color="danger" open={snackbarOpen} setOpen={setSnackbarOpen} />
        </>
    );
}
