import { createContext, PropsWithChildren } from 'react';
import { BackendApi } from 'src/providers/data-source-backend';

export const BackendDataSourceContext = createContext<BackendApi | null>(null);

export function BackendDataSourceProvider(
    props: PropsWithChildren<{
        dataSourceBackend: BackendApi;
    }>,
) {
    return (
        <BackendDataSourceContext.Provider value={props.dataSourceBackend}>
            {props.children}
        </BackendDataSourceContext.Provider>
    );
}
