import { Card, Grid, Stack, Typography } from '@mui/joy';
import { Project, getClient } from 'src/core/project/domain';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';

interface InfoLineProps {
    label: string;
    value: string | ReactNode;
    dataTestId?: string;
    toBlur?: boolean;
}

function InfoLine({ label, value, dataTestId, toBlur }: InfoLineProps) {
    const { t } = useTranslation();

    return (
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
            <Grid xs={4}>
                <Typography color="grey" level="title-sm">
                    {t(label)}
                </Typography>
            </Grid>
            <Grid xs={8}>
                {typeof value === 'string' ? (
                    <Typography style={{ filter: toBlur ? 'blur(5px)' : '' }} data-test-id={dataTestId}>
                        {value}
                    </Typography>
                ) : (
                    <>{value}</>
                )}
            </Grid>
        </Grid>
    );
}

interface InputProps {
    project: Project;
}

export default function GenericDataCard({ project }: InputProps) {
    const { t } = useTranslation();
    const client: any = getClient(project.status, project.client);

    return (
        <Card variant="plain">
            <Typography level="h4">{t('projects.generic_data.data_title')}</Typography>
            <InfoLine
                label="projects.generic_data.client"
                value={
                    client.clientType === 'company'
                        ? `${client.clientName} - ${client.firstName} ${client.lastName} - ${client.parentName}`
                        : `${client.clientFirstName} ${client.clientLastName}`
                }
                toBlur={client.toBlur}
                dataTestId="project-client"
            />
            <InfoLine label="projects.generic_data.ref" value={project.reference} dataTestId="project-ref" />
            <InfoLine
                label="projects.generic_data.address"
                value={
                    <Stack>
                        <Typography data-test-id="project-address-1">{`${project.address.street1} ${project.address.street2} ${project.address.street3}`}</Typography>
                        <Typography data-test-id="project-address-2">{`${project.address.postalCode} ${project.address.country}`}</Typography>
                    </Stack>
                }
            />
            <InfoLine
                label="projects.generic_data.contact"
                value={
                    <Stack>
                        <Typography style={{ filter: client.toBlur ? 'blur(5px)' : '' }} data-test-id="project-phone">
                            {client.clientPhone}
                        </Typography>
                        <Typography style={{ filter: client.toBlur ? 'blur(5px)' : '' }} data-test-id="project-email">
                            {client.clientEmail}
                        </Typography>
                    </Stack>
                }
            />
        </Card>
    );
}
