const CloseIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group 37">
                <path
                    id="Ligne 4"
                    d="M17.7724 2.22762L2.22754 17.7725"
                    stroke="black"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                />
                <path
                    id="Ligne 5"
                    d="M17.7724 17.7724L2.22754 2.22754"
                    stroke="black"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                />
            </g>
        </svg>
    );
};

export default CloseIcon;
