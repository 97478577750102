import { Projects } from './pages/Projects';
import { Home } from './pages/Home';
import { ProjectOverviewPage } from './pages/ProjectOverviewPage';
import { ProjectGenericDataPage } from './pages/ProjectGenericDataPage';
import { Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { LoaderPage } from './pages/Loader';
import { type ComponentType } from 'react';
import { ProjectTechnicalDataPage } from './pages/ProjectTechnicalDataPage';
import { ProfileAndSettings } from './pages/ProfileAndSettings';
import { ProjectDeliveryPage } from './pages/ProjectDeliveryPage';
import { InstallationDataPage } from './pages/InstallationDataPage';
import { SurveyPage } from './pages/SurveyPage';
import { PreInstallationPage } from './pages/PreInstallationPage';
import { SignOffSheetPage } from './pages/SignOffSheetPage';
import { BillingsPage } from './pages/BillingsPage';
import { BillingPage } from './pages/BillingPage';

import { QuotesPage } from './pages/QuotesPage';
import { QuotePage } from './pages/QuotePage';

import { LegalDocumentsPage } from './pages/LegalDocumentsPage';
import { NewBillingsPage } from './pages/NewBillingsPage';

export const AuthenticationGuard = ({ Component }: { Component: ComponentType }) => {
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

    if (isLoading) {
        return <LoaderPage />;
    }

    if (!isAuthenticated) {
        loginWithRedirect({
            appState: {
                returnTo: window.location.pathname,
            },
        });
        return;
    }

    return <Component />;
};

export function AppRoutes() {
    const { isLoading, error } = useAuth0();
    if (error) {
        console.log(error);
    }

    if (isLoading) {
        return <LoaderPage />;
    }

    return (
        <Routes>
            <Route path="/:lang?" element={<Home />} />
            <Route path="/profile" element={<AuthenticationGuard Component={ProfileAndSettings} />} />
            <Route path="/projects" element={<AuthenticationGuard Component={Projects} />} />
            <Route path="/projects/:id" element={<AuthenticationGuard Component={ProjectOverviewPage} />} />
            <Route
                path="/projects/:id/generic-data"
                element={<AuthenticationGuard Component={ProjectGenericDataPage} />}
            />
            <Route
                path="/projects/:id/technical-data"
                element={<AuthenticationGuard Component={ProjectTechnicalDataPage} />}
            />
            <Route path="/projects/:id/survey/" element={<AuthenticationGuard Component={SurveyPage} />} />
            <Route
                path="/projects/:id/technical-data/survey/"
                element={<AuthenticationGuard Component={SurveyPage} />}
            />
            <Route path="/projects/:id/quotes/" element={<AuthenticationGuard Component={QuotesPage} />} />
            <Route path="/projects/:id/quotes/:quoteId" element={<AuthenticationGuard Component={QuotePage} />} />

            <Route path="/projects/:id/delivery" element={<AuthenticationGuard Component={ProjectDeliveryPage} />} />
            <Route
                path="/projects/:id/installation"
                element={<AuthenticationGuard Component={InstallationDataPage} />}
            />
            <Route
                path="/projects/:id/pre-installation"
                element={<AuthenticationGuard Component={PreInstallationPage} />}
            />
            <Route path="/projects/:id/sign-off-sheet" element={<AuthenticationGuard Component={SignOffSheetPage} />} />
            <Route
                path="/projects/:id/legal-documents"
                element={<AuthenticationGuard Component={LegalDocumentsPage} />}
            />
            <Route path="/projects/:id/billings" element={<AuthenticationGuard Component={BillingsPage} />} />
            <Route path="/projects/:id/billings/:billingId" element={<AuthenticationGuard Component={BillingPage} />} />
            <Route
                path="/projects/:id/purchase-orders/:purchaseOrderId/new-billings"
                element={<AuthenticationGuard Component={NewBillingsPage} />}
            />
            <Route path="/projects/:id/new-billings" element={<AuthenticationGuard Component={NewBillingsPage} />} />
        </Routes>
    );
}
