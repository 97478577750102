import { Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';

import Button from '../Button';
import UploadIcon from 'src/ui/atoms/icons/UploadIcon';

interface FileInputProps {
    onChange: (files: File[]) => void;
    dataTestId?: string;
    files?: File[];
    setFiles?: (files: File[]) => void;
}
export default function FileInput({ onChange, dataTestId, files, setFiles }: FileInputProps) {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const onFileUploadClick = () => {
        fileInputRef.current && fileInputRef.current.click();
    };
    const { t } = useTranslation();

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const uploadedFiles = e.target.files ? Array.from(e.target.files) : [];
        const validFiles: File[] = [];
        const maxSizeMb = 15;

        uploadedFiles.forEach((file) => {
            if (file.size > maxSizeMb * 1024 * 1024) {
                alert(t('file_management.file_size_exceeds_limit') + ' : ' + maxSizeMb + ' MB.');
            } else {
                validFiles.push(file);
            }
        });

        if (validFiles.length && setFiles) {
            setFiles([...validFiles]);
        }
        onChange(validFiles);
        e.target.value = '';
    };

    return (
        <>
            <input
                type="file"
                multiple
                onChange={handleFileChange}
                style={{ display: 'none' }}
                ref={fileInputRef}
                accept=".pdf, image/*"
                data-test-id={dataTestId}
            />
            <Button
                color="grey"
                variant="outlined"
                onClick={onFileUploadClick}
                sx={{ padding: '32px', height: '152px' }}
            >
                <Stack alignItems="center">
                    <UploadIcon />
                    <Typography color="grey" level="body-md">
                        {t('quote.upload')}
                    </Typography>
                </Stack>
            </Button>
        </>
    );
}
