// import { saveAs } from 'file-saver';
import { useContext, useEffect, useState } from 'react';
import { Card, Modal, ModalClose, ModalDialog, Stack, Typography } from '@mui/joy';
import { Picture } from 'src/core/project/domain';
import { useTranslation } from 'react-i18next';
import { theme } from 'src/ui/theme';
// import PlusIcon from 'src/ui/atoms/icons/PlusIcon';
// import Button from '../Button';
// import NewPictureModal from '../forms/NewPictureModal';
import ModalHeader from '../ModalHeader';
import { useAppAuth } from 'src/ui/contexts/auth-context';
import { projectActions } from 'src/core/project/service';
import { ProjectsContext } from 'src/ui/contexts/project-context';

function PictureModal({ src, name, open, setOpen }: { src: string; name: string; open: boolean; setOpen: Function }) {
    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <ModalDialog layout="fullscreen" color="secondary" variant="solid" sx={{ padding: 0 }}>
                <ModalHeader label={name} />
                <Stack height="100%" justifyContent="center" sx={{ overflow: 'scroll' }}>
                    <ModalClose />
                    <img alt={name} src={src} style={{ height: '100%', objectFit: 'contain' }} />
                </Stack>
            </ModalDialog>
        </Modal>
    );
}

function PictureCard({ pic: { id, name } }: { pic: Picture }) {
    const [open, setOpen] = useState<boolean>(false);
    const { projectState } = useContext(ProjectsContext)!;

    return (
        <Card
            orientation="vertical"
            size="md"
            variant="solid"
            sx={{
                backgroundColor: theme.palette.grey[100],
            }}
        >
            {projectState.project?.id && projectState.files['pic-' + id] && (
                <>
                    <img
                        src={projectState.files['pic-' + id]!}
                        alt={name}
                        onClick={() => {
                            setOpen(true);
                        }}
                        style={{ borderRadius: '5px' }}
                    />
                    <Typography level="body-lg" sx={{ color: theme.palette.neutral[900], fontWeight: 700 }}>
                        {name}
                    </Typography>
                    <PictureModal src={projectState.files['pic-' + id]!} name={name} open={open} setOpen={setOpen} />
                </>
            )}
        </Card>
    );
}

export default function PicturesCard({ pictures }: { pictures: Picture[] }) {
    const { t } = useTranslation();
    const { authenticatedCall } = useAppAuth();
    const { dispatch } = useContext(ProjectsContext)!;
    const projectId = useContext(ProjectsContext)!.projectState.project?.id || '';
    useEffect(() => {
        pictures.reduce(
            (promise, picture) =>
                promise.then(() =>
                    authenticatedCall(async (accessToken) =>
                        dispatch(projectActions.retrieveFileByS3Key(accessToken, projectId, 'pic-' + picture.id)),
                    ),
                ),
            Promise.resolve(),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authenticatedCall]);

    return (
        <Card variant="plain">
            <Typography level="h4">{t('projects.technical_data.pictures')}</Typography>
            <Typography level="body-sm">{t('projects.technical_data.pictures_subtitle')}</Typography>
            {pictures && pictures.map((picture, index) => <PictureCard pic={picture} key={`picture-${index}`} />)}
        </Card>
    );
}
