import { Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import formatDate from 'src/utils/formatDate';
import formatHours from 'src/utils/formatHours';
import isDate from 'src/utils/isDate';
import { DateValue } from './DateModal';
import { getUserLang } from 'src/ui/i18n';
import { useContext } from 'react';
import { ProjectsContext } from 'src/ui/contexts/project-context';

interface ModalSummaryProps {
    startDate: DateValue;
    endDate: DateValue;
}

export default function ModalSummary({ startDate, endDate }: ModalSummaryProps) {
    const { t } = useTranslation();
    const { projectState } = useContext(ProjectsContext)!;

    return (
        <>
            <Typography level="body-lg" sx={{ marginTop: '20px' }}>
                {t(`date_modal.modal_summary_subtitle`)}
            </Typography>
            <Typography level="title-lg" sx={{ marginTop: '12px' }}>
                {t(`date_modal.modal_summary_start_date_label`)}
            </Typography>
            {isDate(startDate) && (
                <Typography level="title-lg">
                    {t(`date_modal.modal_summary_date`, {
                        date: formatDate(
                            startDate,
                            false,
                            true,
                            getUserLang(),
                            projectState.project?.address.countryCode,
                        ),
                        hour: formatHours(startDate, t),
                    })}
                </Typography>
            )}
            {isDate(endDate) && (
                <>
                    <Typography level="title-lg" sx={{ marginTop: '12px' }}>
                        {t(`date_modal.modal_summary_end_date_label`)}
                    </Typography>
                    <Typography level="title-lg" sx={{ marginBottom: '200px' }}>
                        {t(`date_modal.modal_summary_date`, {
                            date: formatDate(
                                endDate,
                                false,
                                true,
                                getUserLang(),
                                projectState.project?.address.countryCode,
                            ),
                            hour: formatHours(endDate, t),
                        })}
                    </Typography>
                </>
            )}
        </>
    );
}
