const FlagDeutschIcon = () => {
    return (
        <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3995_2881)">
                <rect width="30" height="7.33" fill="#000000" />
                <rect y="7.33" width="30" height="7.34" fill="#DD0000" />
                <rect y="14.67" width="30" height="7.33" fill="#FFCC00" />
            </g>
            <defs>
                <clipPath id="clip0_3995_2881">
                    <rect width="30" height="22" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default FlagDeutschIcon;
