import { useTranslation } from 'react-i18next';
import { PageTemplate } from '../layout/PageTemplate';
import { Card, Grid, Radio, RadioGroup, Sheet, Stack, Switch, Typography, styled } from '@mui/joy';
import Tag from '../molecules/Tag';
import { useAppAuth } from '../contexts/auth-context';
import { LoaderPage } from './Loader';
import { getUserLang, setUserLang } from '../i18n';
import FlagFrenchIcon from '../atoms/icons/FlagFrenchIcon';
import FlagSpainIcon from '../atoms/icons/FlagSpainIcon';
import { projectActions } from 'src/core/project/service';
import { useContext } from 'react';
import { ProjectsContext } from '../contexts/project-context';
import React from 'react';

function InfoBlock({ title, data, isPassword }: { title: string; data?: string; isPassword?: boolean }) {
    const { t } = useTranslation();
    return (
        <Stack data-test-id={`profile-page-installer-${title}`} data-test-value={data}>
            <Typography level="body-sm" textColor="grey">
                {t(`profile.personal_data.${title}`)}
            </Typography>
            <Typography level="title-md" fontSize={isPassword ? '40px' : '14px'} textColor="secondary.500">
                {data ? data : '-'}
            </Typography>
        </Stack>
    );
}

function NotificationLine({ title, disabled }: { title: string; disabled?: boolean }) {
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography level="body-md" sx={{ opacity: disabled ? 0.7 : 1 }}>
                {title}
            </Typography>
            <Switch disabled={disabled} color="grey" size="lg" />
        </Stack>
    );
}

export function ProfileAndSettings() {
    const [radioDisabled, setRadioDisabled] = React.useState(false);
    const { t } = useTranslation();
    const { user, authenticatedCall } = useAppAuth();
    const { dispatch } = useContext(ProjectsContext)!;
    if (!user) {
        return <LoaderPage />;
    }

    const defaultLang = getUserLang();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let lang = event.target.value;
        setUserLang(lang);
        setRadioDisabled(true);
        authenticatedCall(async (accessToken) => dispatch(projectActions.changeUserLanguage(accessToken, lang))).then(
            () => setRadioDisabled(false),
        );
    };

    const Item = styled(Sheet)(({ theme }) => ({
        padding: theme.spacing(2),
        margin: 4,
        borderRadius: 6,
    }));

    return (
        <PageTemplate withBottomBar={true} greyBackground>
            <Stack
                sx={{ justifyContent: 'space-between', height: '100%', paddingX: '20px', paddingY: '40px' }}
                data-test-id="profile-and-settings-page"
            >
                <Typography level="h1" data-test-id="account_page" textColor="secondary.500">
                    {t('profile.title')}
                </Typography>
                <Card variant="plain" sx={{ marginTop: '10px' }}>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography level="h4" textColor="secondary.500">
                            {t('profile.personal_data.title')}
                        </Typography>
                        {/* <Button disabled variant="underlined" color="grey" label={t('profile.personal_data.edit')} /> */}
                    </Stack>
                    <Stack
                        direction="row"
                        width="100%"
                        flexWrap="wrap"
                        justifyContent="space-between"
                        gap="20px"
                        alignContent="flex-start"
                    >
                        <Stack direction="column" spacing={2}>
                            <InfoBlock title="name" data={user.name} />
                            <InfoBlock title="connexion_id" data={user.email} />
                        </Stack>
                        <Stack spacing={2}>
                            <InfoBlock title="job" data={user['job']} />
                            <InfoBlock title="password" data="•••••••••" isPassword />
                        </Stack>
                    </Stack>
                </Card>

                <Card variant="plain" sx={{ marginTop: '20px' }}>
                    <Typography level="h4" textColor="secondary.500">
                        {t('profile.language.title')}
                    </Typography>
                    <RadioGroup value={defaultLang}>
                        <Grid container spacing={2} columns={2}>
                            {[
                                [
                                    <>
                                        <FlagFrenchIcon /> <Typography fontSize={'18px'}>Français</Typography>
                                    </>,
                                    'fr',
                                ],
                                [
                                    <>
                                        <FlagSpainIcon /> <Typography fontSize={'18px'}>Español</Typography>
                                    </>,
                                    'es',
                                ],
                            ].map((item, index) => (
                                <Grid key={index} xs={1}>
                                    <Item sx={{ bgcolor: 'grey.100' }}>
                                        <Radio
                                            disabled={radioDisabled}
                                            overlay
                                            value={item[1]}
                                            label={item[0]}
                                            variant={'outlined'}
                                            color={'grey'}
                                            onChange={handleChange}
                                            slotProps={{
                                                radio: ({ checked }) => ({
                                                    sx: () => ({
                                                        color: checked ? 'primary.500' : 'grey',
                                                        zIndex: 0,
                                                    }),
                                                }),
                                                action: ({ checked }) => ({
                                                    sx: () => ({
                                                        ...(checked && {
                                                            border: '2px solid',
                                                            borderColor: 'primary.500',
                                                            zIndex: 0,
                                                        }),
                                                    }),
                                                }),
                                            }}
                                        />
                                    </Item>
                                </Grid>
                            ))}
                        </Grid>
                    </RadioGroup>
                </Card>

                <Card variant="plain" sx={{ marginTop: '20px' }}>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography level="h4" textColor="secondary.500">
                            {t('profile.notifications.title')}
                        </Typography>
                        <Tag color="grey" label={t('profile.notifications.soon')}></Tag>
                    </Stack>
                    <Stack spacing={3} marginTop="10px">
                        <NotificationLine title={t('profile.notifications.new_projects')} disabled />
                        <NotificationLine title={t('profile.notifications.changes_project')} disabled />
                        <NotificationLine title={t('profile.notifications.calendar_reminders')} disabled />
                        <NotificationLine title={t('profile.notifications.new_message')} disabled />
                        <NotificationLine title={t('profile.notifications.administratif_docs')} disabled />
                        <NotificationLine title={t('profile.notifications.new_features')} disabled />
                    </Stack>
                </Card>
            </Stack>
        </PageTemplate>
    );
}
