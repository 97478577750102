import { Stack, Card, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Project } from '../../../core/project/domain';
import formatDate from 'src/utils/formatDate';
import formatHours from 'src/utils/formatHours';
import { getUserLang } from 'src/ui/i18n';

interface InputProps {
    project: Project;
}

export default function HistoryCard({ project }: InputProps) {
    const { t } = useTranslation();

    const getTestValue = (parentEntityName: string, value: string) => {
        switch (parentEntityName) {
            case 'installation':
                return 'installation_planned';
            case 'survey':
                return 'survey_rescheduled';
            default:
                return value;
        }
    };

    const getHistoryLabel = (parentEntityName: string, value: string) => {
        switch (parentEntityName) {
            case 'installation':
                return t(`projects.generic_data.history.installation_planned`, {
                    date: formatDate(new Date(value), false, false, getUserLang(), project.address.countryCode),
                    hour: formatHours(new Date(value), t, false),
                });
            case 'project':
                return t(`projects.generic_data.history.${value}`);
            case 'survey':
                return t(`projects.generic_data.history.survey_rescheduled`, {
                    date: formatDate(new Date(value), false, false, getUserLang(), project.address.countryCode),
                    hour: formatHours(new Date(value), t, false),
                });
        }
    };

    return (
        <Card variant="plain">
            <Typography level="h4">{t('projects.generic_data.history_title')}</Typography>
            {project.history
                ?.toSorted((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
                .map(({ parentEntityName, newValue, date }, index) => (
                    <Stack sx={{ flexDirection: 'row' }} key={`history-${index}`}>
                        <Typography
                            data-test-id={`history-${index}`}
                            data-test-value={getTestValue(parentEntityName, newValue)}
                        >
                            <span style={{ fontWeight: 'bold' }}>{getHistoryLabel(parentEntityName, newValue)}</span>
                            &nbsp;{`- ${formatDate(date, false, false, getUserLang(), project.address.countryCode)}`}
                        </Typography>
                    </Stack>
                ))}
        </Card>
    );
}
