import { Project } from 'src/core/project/domain';
import { BackendApi } from 'src/providers/data-source-backend';

const downloadFile = (
    project: Project | null,
    s3Key: string,
    title: string,
    dataSourceBackend: BackendApi,
    accessToken: string,
) => {
    const projectId = project ? project.id : '';
    dataSourceBackend.retrieveFileByS3Key(accessToken, projectId, s3Key).then(async (res) => {
        if (!res) {
            return;
        }

        if (!res?.includes(';base64,')) {
            throw new Error('File not found' + res);
        }

        let mimeType = res.split(';base64,')[0].split('data:')[1];
        let fileExtension = mimeType.split('/')[1];
        const link = document.createElement('a');
        link.href = res;
        link.download = title + '.' + fileExtension;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    });
};

export default downloadFile;
