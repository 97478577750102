const ProjectIcon = () => {
    return (
        <svg
            width="20"
            height="24"
            viewBox="0 0 20 24"
            fill="current"
            stroke="current"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Frame 58">
                <rect id="Rectangle 2587" x="0.5" y="0.5" width="19" height="23" rx="1.5" />
                <path id="Line 8" d="M4 6H16" strokeLinecap="round" />
                <path id="Line 9" d="M4 10H16" strokeLinecap="round" />
                <path id="Line 10" d="M4 14H16" strokeLinecap="round" />
                <path id="Line 11" d="M4 18H9" strokeLinecap="round" />
            </g>
        </svg>
    );
};

export default ProjectIcon;
