const FlagPortugalIcon = () => {
    return (
        <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3995_2881)">
                <rect width="12" height="22" fill="#006600" />
                <rect x="12" width="18" height="22" fill="#FF0000" />
                <circle cx="12" cy="11" r="4" fill="#FFCC00" stroke="black" strokeWidth="0.5" />
            </g>
            <defs>
                <clipPath id="clip0_3995_2881">
                    <rect width="30" height="22" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default FlagPortugalIcon;
