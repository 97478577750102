import axios, { AxiosError, type AxiosInstance } from 'axios';

export const controller = new AbortController();

export const buildAxiosInstance = (baseUrl: string): AxiosInstance => {
    const instance = axios.create({
        signal: controller.signal,
        baseURL: baseUrl,
    });
    instance.interceptors.request.use(null, logRequestError);
    instance.interceptors.response.use(null, logResponseError);
    return instance;
};

const logRequestError = (error: AxiosError): Promise<AxiosError> => {
    console.error(error);
    return Promise.reject(error);
};

const logResponseError = (error: AxiosError): Promise<void> => {
    console.error(error);
    return Promise.reject(error);
};
