import { Box, Radio } from '@mui/joy';
import CheckedIcon from '../../atoms/icons/RadioButtonIcon';
import UncheckedIcon from '../../atoms/icons/UncheckedIcon';

interface InputProps {
    selectedValue: string | number | boolean;
    choices: Array<{ label: string; value: string | number | boolean }>;
    onChange: (value: string | number | boolean) => void;
}

export default function RadioInput({ selectedValue, choices, onChange }: InputProps) {
    const handleChange = (selectedValue: string | number | boolean) => {
        onChange(selectedValue); // Call the onChange handler passed from the parent component
    };

    return (
        <Box sx={{ display: 'flex', gap: 2 }}>
            {choices.map(({ label, value }) => (
                <Radio
                    sx={{
                        borderRadius: '4px',
                        backgroundColor: selectedValue === value ? 'primary.100' : 'grey.100',
                        borderColor: 'grey.200',
                        height: '45px',
                        padding: '12px',
                    }}
                    checked={selectedValue === value}
                    onChange={() => handleChange(value)}
                    value={value}
                    name="radio-buttons"
                    variant="soft"
                    checkedIcon={<CheckedIcon />}
                    uncheckedIcon={<UncheckedIcon />}
                    label={label}
                    key={`option-${value}`}
                />
            ))}
        </Box>
    );
}
