import { Box, Button, Card, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Quote, QuoteStatus } from 'src/core/project/domain';
import { useContext } from 'react';
import { ProjectsContext } from 'src/ui/contexts/project-context';
import formatDate from 'src/utils/formatDate';
import { getUserLang } from 'src/ui/i18n';
import QuoteStatusTag from './QuoteStatusTag';
import { useNavigate } from 'react-router-dom';
import EyeIcon from 'src/ui/atoms/icons/EyeIcon';

export default function QuoteCard({
    item,
    small,
    showDetailsButton,
}: {
    item: Quote | null;
    small?: boolean;
    showDetailsButton?: boolean;
}) {
    const { t } = useTranslation();
    const { projectState } = useContext(ProjectsContext)!;
    const navigate = useNavigate();

    const quoteId = item?.id;
    const projectId = projectState?.project?.id ?? '';

    const onClick = () => {
        navigate(`/projects/${projectId}/quotes/${quoteId}`);
    };

    return (
        <Card variant={small ? 'outlined' : 'plain'} sx={{ margin: 1 }}>
            <Stack flexDirection="row" justifyContent="space-between">
                <Typography level="h4" data-test-id={`quote-reference-0`} data-test-value={item?.reference}>
                    {t(`quote.title`, { reference: item?.reference })}
                </Typography>
                <QuoteStatusTag status={item?.status as QuoteStatus} />
            </Stack>
            {item?.amount && (
                <Stack>
                    <Typography color="grey" level="title-sm">
                        {t(`quote.amount_short`)}
                    </Typography>
                    <Typography level="title-sm" data-test-id={`quote-amount-0`}>
                        {item?.amount}
                    </Typography>
                </Stack>
            )}
            {item?.date && (
                <Stack>
                    <Typography color="grey" level="title-sm">
                        {t(`quote.date`)}
                    </Typography>
                    <Typography level="title-sm" data-test-id={`quote-date-0`}>
                        {formatDate(item.date, false, false, getUserLang(), projectState.project?.address.countryCode)}
                    </Typography>
                </Stack>
            )}

            {showDetailsButton && (
                <Button
                    color="grey"
                    variant="soft"
                    startDecorator={
                        <Box>
                            <EyeIcon />
                        </Box>
                    }
                    onClick={onClick}
                />
            )}
        </Card>
    );
}
