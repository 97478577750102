import React from 'react';
import { PageTemplate } from '../layout/PageTemplate';
import { Card, Link, List, ListItem, Stack, Typography } from '@mui/joy';
import { theme } from '../theme';
import ChargeGuruLogo from '../atoms/icons/ChargeGuruBigLogo';
import { useTranslation } from 'react-i18next';
import RadioButtonIcon from '../atoms/icons/RadioButtonIcon';
import guru from '../atoms/images/guru-3d 1.png';
import { useAppAuth } from '../contexts/auth-context';
import { LoginButton } from '../molecules/LoginButton';
import { LogoutButton } from '../molecules/LogoutButton';

interface InfoLineProps {
    label: string;
}

function InfoLine({ label }: InfoLineProps) {
    const { t } = useTranslation();

    return (
        <Stack direction="row">
            <RadioButtonIcon />
            <Typography level="body-lg" sx={{ marginLeft: '12px' }}>
                {t(label)}
            </Typography>
        </Stack>
    );
}

export function Home() {
    const { t } = useTranslation();
    const { isAuthenticated } = useAppAuth();
    return (
        <PageTemplate withBottomBar={false}>
            <Stack
                sx={{
                    background: `linear-gradient(180deg, ${theme.palette.neutral[50]} 3.76%, rgba(255, 255, 255, 0.00) 16.79%), radial-gradient(222.6% 119.7% at 15.59% 15.13%, ${theme.palette.primary[300]} 0%, ${theme.palette.warning[50]} 100%)`,
                    minHeight: '100vh',
                    padding: '100px 20px 21px 20px',
                    justifyContent: 'space-between',
                }}
                data-test-id="home-page"
            >
                <Stack
                    sx={{
                        justifyContent: 'flex-start',
                    }}
                >
                    <ChargeGuruLogo />
                    <Typography level="h3" sx={{ padding: '20px 0' }}>
                        {t('home.title')}
                    </Typography>
                    <List
                        sx={{
                            '--ListItem-minHeight': '21px',
                            background: 'transparent',
                            flexGrow: 'unset',
                            height: '150px',
                            paddingLeft: '0',
                        }}
                    >
                        {[1, 2, 3, 4, 5].map((element) => (
                            <ListItem sx={{ background: 'transparent', paddingLeft: '0' }} key={`home-dot-${element}`}>
                                <InfoLine label={`home.point_${element}`} />
                            </ListItem>
                        ))}
                    </List>
                    <Card
                        sx={{
                            background: `radial-gradient(109.43% 105.32% at 13.83% 61.79%, ${theme.palette.warning[50]} 34.35%, ${theme.palette.primary[500]} 100%)`,
                            border: 'none',
                            marginTop: '20px',
                            paddingLeft: '0',
                            paddingBottom: '0',
                        }}
                    >
                        <Stack direction="row">
                            <img src={guru} alt="guru-3d" style={{ borderBottomLeftRadius: '8px' }} />
                            <Stack justifyContent="center" paddingBottom="16px" paddingRight="24px">
                                <Typography level="h3">{t('home.card_title')}</Typography>
                                <Typography level="body-lg">{t('home.card_subtitle')}</Typography>
                            </Stack>
                        </Stack>
                    </Card>
                </Stack>
                <Stack paddingTop="25px">
                    {!isAuthenticated && <LoginButton />}
                    {isAuthenticated && <LogoutButton />}
                    <Stack direction="row" justifyContent="center" marginTop="12px">
                        <Typography level="body-sm">{t('auth.no_account_text')}</Typography>
                        <Link
                            href={'mailto:' + t('auth.contact_email')}
                            color="grey"
                            level="title-sm"
                            sx={{ marginLeft: '10px' }}
                        >
                            {t('auth.contact')}
                        </Link>
                    </Stack>
                </Stack>
            </Stack>
        </PageTemplate>
    );
}
