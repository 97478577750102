const LinkIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.9995 5.99997V9.99945C10.9995 10.5517 10.5518 10.9995 9.99948 10.9995H2.00052C1.44823 10.9995 1.00052 10.5517 1.00052 9.99945V2.00049C1.00052 1.4482 1.44823 1.00049 2.00052 1.00049H6M8.49974 1.00049H10.9995V3.50023"
                stroke="#1B2A50"
                strokeWidth="1.2"
                strokeLinecap="round"
            />
            <path d="M10.8745 1.12543L6 5.99997" stroke="#1B2A50" strokeWidth="1.2" strokeLinecap="round" />
        </svg>
    );
};

export default LinkIcon;
