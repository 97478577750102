const EyeIcon = () => {
    return (
        <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.2188 3.24878C12.4004 3.46363 12.5 3.73585 12.5 4.01715C12.5 4.29844 12.4004 4.57066 12.2188 4.78551L12.2179 4.78656C11.4411 5.69049 9.30272 7.83966 6.48389 7.83966H6.45168C4.45305 7.83966 2.54158 6.77273 0.782804 4.7529L0.781187 4.75105C0.599607 4.5362 0.5 4.26397 0.5 3.98267C0.5 3.70137 0.599621 3.42917 0.781201 3.21432L0.782089 3.21327C1.55891 2.30934 3.69727 0.160156 6.5161 0.160156H6.54831C8.54694 0.160156 10.4584 1.22709 12.2172 3.24692L12.2188 3.24878ZM11.4677 4.16819C11.1164 4.58106 10.4726 5.25148 9.61829 5.82047C8.75299 6.39676 7.6814 6.86268 6.48446 6.86268H6.45224C4.78054 6.86268 3.1184 5.9672 1.5273 4.1263C1.50131 4.08367 1.48752 4.03468 1.48752 3.98466C1.48752 3.9344 1.50144 3.88518 1.52765 3.84242C1.87757 3.43014 2.52376 2.75545 3.38283 2.1833C4.24813 1.60702 5.31972 1.14109 6.51666 1.14109H6.54888C8.22052 1.14109 9.8826 2.03651 11.4736 3.87726C11.4993 3.91966 11.5129 3.96832 11.5129 4.01799C11.5129 4.07093 11.4974 4.12272 11.4684 4.16701L11.4677 4.16819ZM6.48451 5.81088C7.48425 5.81088 8.29469 5.00044 8.29469 4.0007C8.29469 3.00097 7.48425 2.19052 6.48451 2.19052C5.48478 2.19052 4.67433 3.00097 4.67433 4.0007C4.67433 5.00044 5.48478 5.81088 6.48451 5.81088Z"
                fill="#17181A"
            />
        </svg>
    );
};

export default EyeIcon;
