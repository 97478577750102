import { SvgIcon } from '@mui/joy';
import FullInstallationIcon from '../atoms/icons/FullInstallationIcon';
import MaintenanceVisitIcon from '../atoms/icons/MaintenanceVisitIcon';
import TechnicalVisitIcon from '../atoms/icons/TechnicalVisitIcon';

interface TypologyIconProps {
    marginRight?: string;
    typology: string;
}

export default function TypologyIcon({ typology, marginRight = '12px' }: TypologyIconProps) {
    return (
        <SvgIcon
            sx={{
                marginTop: '4px',
                marginRight,
            }}
        >
            {typology === 'full installation' && <FullInstallationIcon />}
            {typology === 'installation' && <FullInstallationIcon />}
            {typology === 'maintenance visit' && <MaintenanceVisitIcon />}
            {typology === 'technical visit' && <TechnicalVisitIcon />}
        </SvgIcon>
    );
}
