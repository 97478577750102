import { Card, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Document } from 'src/core/project/domain';
import Button from '../Button';
import { useContext } from 'react';
import { ProjectsContext } from 'src/ui/contexts/project-context';
import { useAppAuth } from 'src/ui/contexts/auth-context';
import downloadFile from 'src/utils/downloadFile';
import { BackendDataSourceContext } from 'src/ui/contexts/backend-data-source-context';
import PdfIcon from 'src/ui/atoms/icons/PdfIcon';
import ImageIcon from 'src/ui/atoms/icons/ImageIcon';
import EyeIcon from 'src/ui/atoms/icons/EyeIcon';

function DocumentSection({ item }: { item: Document }) {
    const { t } = useTranslation();
    const { authenticatedCall } = useAppAuth();
    const { projectState } = useContext(ProjectsContext)!;
    const dataSourceBackend = useContext(BackendDataSourceContext)!;

    const onClick = () => {
        const project = projectState.project;
        if (item && item.id) {
            authenticatedCall(async (accessToken) => {
                downloadFile(project, item.fileKey, item.title, dataSourceBackend, accessToken);
            });
        }
    };

    function getIconForMimeType(mimeType: string | undefined) {
        if (!mimeType) return <EyeIcon />;
        const type = mimeType.toLowerCase();
        if (type.includes('pdf')) return <PdfIcon />;
        if (type.includes('image')) return <ImageIcon />;
        return <EyeIcon />;
    }

    // render pdf icon when pdf

    return (
        <Stack>
            <Typography color="grey" level="title-sm"></Typography>
            <Button
                label={t(`file_management.view_file`)}
                color="grey"
                variant="soft"
                startDecorator={
                    <Typography color="grey" level="title-sm">
                        {getIconForMimeType(item.mimeType)}
                    </Typography>
                }
                onClick={onClick}
            />
        </Stack>
    );
}

export function DocumentCards({ documents }: { documents: Array<Document> }) {
    useTranslation();

    return <>{documents && documents.map((doc, index) => <DocumentCard item={doc} key={`${doc.fileKey}`} />)}</>;
}

export default function DocumentCard({ item, small }: { item: Document; small?: boolean }) {
    return (
        <Card variant={small ? 'outlined' : 'plain'} sx={{ margin: 1 }}>
            <Stack flexDirection="row" justifyContent="space-between">
                <Typography level="h4">{item.title}</Typography>
            </Stack>
            {!small && <DocumentSection item={item} />}
        </Card>
    );
}
