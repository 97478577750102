import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QuoteStatus } from '../../../core/project/domain';
import Tag from '../Tag';

export default function QuoteStatusTag({ status }: { status: QuoteStatus }) {
    const { t } = useTranslation();

    const color = useMemo(() => {
        switch (status) {
            case 'rejected':
                return 'danger';
            case 'signed':
                return 'primary';
            default:
                return 'grey';
        }
    }, [status]);

    return <Tag color={color} label={t(`quote.status.${status}`)} />;
}
