import { Card, Typography } from '@mui/joy';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

//import Button from '../Button';
import { theme } from 'src/ui/theme';

export default function QuoteStatusCard({ status, onClick }: { status: string; onClick: () => void }) {
    const { t } = useTranslation();
    let titleCard;
    let subtitleCard;
    const color = useMemo(() => {
        switch (status) {
            case 'waiting_for_new_quote':
                return theme.palette.warning[100];
            case 'quote_signed':
                return theme.palette.primary[100];
            default:
                return '';
        }
    }, [status]);

    const borderColor = useMemo(() => {
        switch (status) {
            case 'waiting_for_new_quote':
                return theme.palette.warning[500];
            case 'quote_signed':
                return theme.palette.primary[700];
            default:
                return '';
        }
    }, [status]);

    if (
        ['project_accepted', 'survey_conducted', 'waiting_for_new_quote', 'quote_proposed', 'quote_validated'].includes(
            status,
        )
    ) {
        titleCard = t(`quote.${status}.title`);
        subtitleCard = t(`quote.${status}.subtext`);
    } else {
        titleCard = t('quote.quote_signed.title');
        subtitleCard = t('quote.quote_signed.subtext');
    }

    return (
        <Card
            variant={status === 'waiting_for_new_quote' || status === 'quote_signed' ? 'outlined' : 'plain'}
            sx={{
                backgroundColor: color,
                borderColor,
            }}
            color="warning"
            data-test-id={`quote-status-${status}`}
        >
            <Typography level="h4">{titleCard}</Typography>
            <Typography>{subtitleCard}</Typography>
            {
                // status === 'waiting_for_new_quote' && (
                // <Button label={t('quote.rejected.action')} color="neutral" onClick={onClick} />
                //)
            }
        </Card>
    );
}
