import React, { PropsWithChildren } from 'react';
import { Box, Stack } from '@mui/joy';
import useScrollToTop from '../../utils/useScrollToTop';
import { theme } from '../theme';
import BottomBar from './BottomBar';
import TypeformEmbedCSAT from '../molecules/TypeformEmbedCSAT';

interface PageTemplateProps extends PropsWithChildren {
    greyBackground?: boolean;
    withBottomBar?: boolean;
}

export function PageTemplate({ withBottomBar = true, greyBackground = false, ...props }: PageTemplateProps) {
    const sx = greyBackground ? { backgroundColor: theme.palette.grey[100] } : {};
    useScrollToTop();

    return (
        <Stack justifyContent="space-between" sx={{ minHeight: '100vh', ...sx }}>
            {props.children}
            <div id="csat">
                <TypeformEmbedCSAT />
            </div>
            {withBottomBar && (
                <Box position="sticky" bottom="0" width="100%" marginTop="20px">
                    <BottomBar />
                </Box>
            )}
        </Stack>
    );
}
