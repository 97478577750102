import { Card } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';

import { projectActions } from '../../../core/project/service';
import { useAppAuth } from '../../contexts/auth-context';
import { ProjectsContext } from '../../contexts/project-context';
import FloatingLabelInput from '../forms/FloatingLabelInput';
import Button from '../Button';
import DocumentForm, { DocumentDto } from '../documents/DocumentForm';

export interface BillingFormItem {
    reference: string;
    amount: number;
    vatRate: number;
    description?: string;
}

export default function BillingForm({
    projectId,
    purchaseOrderId,
    callback,
    maxAmount,
    isLocked,
}: {
    projectId: string;
    purchaseOrderId?: string;
    callback?: () => void;
    maxAmount: number;
    isLocked: boolean;
}) {
    const defaultAmount = isLocked ? maxAmount : 0;
    const { t } = useTranslation();
    const { authenticatedCall } = useAppAuth();
    const { dispatch } = useContext(ProjectsContext)!;
    const [billingToCreate, setBillingToCreate] = useState<BillingFormItem>({
        reference: '',
        amount: defaultAmount,
        vatRate: 0,
    });
    const [loading, setLoading] = useState(false);
    const [billingDocuments, setBillingDocuments] = useState<DocumentDto[]>([]);
    const [formKey, setFormKey] = useState(0);

    const submit = async () => {
        try {
            await authenticatedCall(async (accessToken) =>
                dispatch(
                    projectActions.createBilling(
                        accessToken,
                        projectId,
                        billingToCreate.reference,
                        billingToCreate.amount,
                        billingToCreate.vatRate,
                        billingDocuments,
                        purchaseOrderId,
                    ),
                ),
            );

            // reset billing form completely
            setBillingToCreate({ reference: '', amount: 0, vatRate: 0 });
            setBillingDocuments([]);
            setFormKey((prevKey) => prevKey + 1);

            if (callback) {
                callback();
            }
        } catch (e: any) {
            console.error(e.error);
        } finally {
            setLoading(false);
        }
    };

    const overMaxAmount = billingToCreate?.amount > maxAmount;

    return (
        <Card variant="plain" key={formKey}>
            {/* <Typography level="h4" alignSelf="center">
                {t('billing.new_billing')}
            </Typography> */}
            <FloatingLabelInput
                label={t('billing.reference')}
                value={billingToCreate?.reference}
                onChange={(e) => setBillingToCreate({ ...billingToCreate, reference: e.target.value })}
                data-test-id="reference-input"
            />
            <FloatingLabelInput
                label={t('billing.amount')}
                value={billingToCreate?.amount}
                type="number"
                onChange={(e) => setBillingToCreate({ ...billingToCreate, amount: parseFloat(e.target.value ?? 0) })}
                data-test-id="amount-input"
                color={overMaxAmount ? 'danger' : undefined}
                helperText={overMaxAmount ? t('billing.amount_exceeded') : undefined}
                isError={overMaxAmount}
                disabled={isLocked}
            />
            <FloatingLabelInput
                label={t('billing.vat_rate')}
                value={billingToCreate?.vatRate}
                type="number"
                onChange={(e) => setBillingToCreate({ ...billingToCreate, vatRate: parseFloat(e.target.value) })}
                data-test-id="vat-rate-input"
            />
            <DocumentForm
                projectId={projectId}
                parentEntityName="billing"
                parentEntityId=""
                type="billing"
                types={[]}
                showTitleInput={false}
                showSubmitButton={false}
                callback={(documents: DocumentDto[]) => {
                    setBillingDocuments(documents);
                }}
            />
            <Button
                onClick={submit}
                disabled={
                    !billingToCreate.reference ||
                    !billingToCreate.amount ||
                    billingToCreate.vatRate === null ||
                    billingToCreate.vatRate === undefined ||
                    !billingDocuments ||
                    billingDocuments.length === 0 ||
                    loading ||
                    billingToCreate.amount > maxAmount
                }
                loading={loading}
                label={t('billing.submit')}
                data-test-id="submit"
            />
        </Card>
    );
}
