import { createContext, PropsWithChildren, Dispatch } from 'react';
import { useAsyncReducer } from '../async-reducer';
import { ProjectAction, ProjectState, projectInitialState, projectReducer } from 'src/core/project/service';

export const ProjectsContext = createContext<{ projectState: ProjectState; dispatch: Dispatch<ProjectAction> } | null>(
    null,
);

export function ProjectsProvider(
    props: PropsWithChildren<{
        projectsReducer: ReturnType<typeof projectReducer>;
    }>,
) {
    const [projectState, dispatch] = useAsyncReducer(props.projectsReducer, projectInitialState);

    return <ProjectsContext.Provider value={{ projectState, dispatch }}>{props.children}</ProjectsContext.Provider>;
}
