const FlagItaliaIcon = () => {
    return (
        <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3995_2881)">
                <rect width="30" height="22" fill="#FFFFFF" />
                <rect width="10" height="22" fill="#008C45" />
                <rect x="20" width="10" height="22" fill="#CD212A" />
            </g>
            <defs>
                <clipPath id="clip0_3995_2881">
                    <rect width="30" height="22" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default FlagItaliaIcon;
