const LocationIconBox = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.075 11.8335C15.0016 12.185 15.5566 12.6327 15.5566 13.1201C15.5566 14.2506 12.5716 15.1671 8.88936 15.1671C5.20717 15.1671 2.22217 14.2506 2.22217 13.1201C2.22217 12.6327 2.77717 12.185 3.70377 11.8335M10.2228 5.83299C10.2228 6.56943 9.6258 7.16643 8.88936 7.16643C8.15292 7.16643 7.55592 6.56943 7.55592 5.83299C7.55592 5.09655 8.15292 4.49955 8.88936 4.49955C9.6258 4.49955 10.2228 5.09655 10.2228 5.83299ZM8.88936 12.5002C9.92648 12.5002 13.5564 10.5544 13.5564 6.36641C13.5564 3.6995 11.4822 1.83267 8.88936 1.83267C6.29656 1.83267 4.22233 3.6995 4.22233 6.36641C4.22233 10.5544 7.85224 12.5002 8.88936 12.5002Z"
                stroke="#8C94A7"
                strokeWidth="0.999857"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default LocationIconBox;
