import { AxiosInstance } from 'axios';
import { Logger } from 'src/core/logger';

export class BackendLogger implements Logger {
    constructor(private readonly axiosConnector: AxiosInstance) {}

    info(message: unknown, ...optionalParams: unknown[]): void {
        console.info(message, ...optionalParams);
    }

    debug(message: unknown, ...optionalParams: unknown[]): void {
        console.debug(message, ...optionalParams);
    }

    error(message: unknown, ...optionalParams: unknown[]): void {
        console.error(message, ...optionalParams);

        this.sendLogs('error', Date.now(), this.formatLog(message, ...optionalParams));
    }

    formatLog(message: unknown, ...optionalParams: unknown[]): string {
        return `${message} ${optionalParams.length > 0 ? JSON.stringify(optionalParams) : ''}`;
    }

    async sendLogs(level: 'info' | 'debug' | 'error', timestamp: number, message: string): Promise<void> {
        try {
            await this.axiosConnector.post(`/front-logs`, {
                timestamp,
                level,
                message,
            });
        } catch (err) {
            console.error(err);
        }
    }
}
