const VIPIcon = () => {
    return (
        <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                id="Vector"
                d="M15.1321 3.74744C15.0807 3.70314 15.0181 3.67393 14.9512 3.66306C14.8842 3.65219 14.8156 3.66009 14.7529 3.68588L10.5396 5.42685L8.32335 0.834342C8.29311 0.771892 8.24588 0.719225 8.18709 0.682373C8.12829 0.645522 8.06031 0.625977 7.99092 0.625977C7.92153 0.625977 7.85354 0.645522 7.79475 0.682373C7.73596 0.719225 7.68873 0.771892 7.65849 0.834342L5.44226 5.42931L1.23637 3.68588C1.17396 3.66198 1.10624 3.65545 1.04041 3.667C0.974589 3.67854 0.913131 3.70773 0.862588 3.75145C0.812044 3.79517 0.774309 3.85178 0.753403 3.91526C0.732497 3.97873 0.729203 4.04669 0.743872 4.11189L1.97511 9.31016V12.0041C1.975 12.0992 2.01154 12.1906 2.07713 12.2594C2.14271 12.3282 2.23229 12.369 2.32724 12.3735H13.6546C13.7526 12.3735 13.8465 12.3346 13.9158 12.2653C13.9851 12.196 14.024 12.1021 14.024 12.0041V9.31016L15.2552 4.11189C15.2707 4.0457 15.2674 3.9765 15.2456 3.9121C15.2239 3.8477 15.1845 3.79066 15.1321 3.74744ZM13.2926 11.6273H2.704V9.63767H13.2926V11.6273ZM13.369 8.89154H2.62027L1.62051 4.64378L5.48413 6.25177C5.57095 6.28819 5.66844 6.28984 5.75645 6.25639C5.84445 6.22293 5.91623 6.15692 5.95692 6.07201L7.99092 1.84395L10.0249 6.06216C10.0656 6.14707 10.1374 6.21308 10.2254 6.24654C10.3134 6.27999 10.4109 6.27834 10.4977 6.24192L14.3613 4.64378L13.369 8.89154Z"
                fill="#1B2A50"
                stroke="#1B2A50"
                strokeWidth="0.5"
            />
        </svg>
    );
};

export default VIPIcon;
