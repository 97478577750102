import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Logger } from 'src/core/logger';
import { ProjectDataSource } from 'src/core/project/data-source';
import { AppUser } from 'src/ui/contexts/auth-context';
import { Billing, Delivery, Document, Project, Quote, Survey } from 'src/core/project/domain';
import { DocumentDto } from 'src/ui/molecules/documents/DocumentForm';

export class BackendApi implements ProjectDataSource {
    constructor(
        private readonly axiosConnector: AxiosInstance,
        private readonly logger: Logger,
    ) {}

    private authenticated(accessToken: string): AxiosRequestConfig {
        return {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        };
    }

    async retrieveProjects(accessToken: string): Promise<Array<Project>> {
        try {
            const res = await this.axiosConnector.get(`/projects`, this.authenticated(accessToken));
            return res.data;
        } catch (err) {
            this.logger.error(err);
            throw err;
        }
    }

    async retrieveProject(accessToken: string, id: string): Promise<Project | null> {
        try {
            const res = await this.axiosConnector.get(`/projects/${id}`, this.authenticated(accessToken));
            return res.data;
        } catch (err) {
            this.logger.error(err);
            throw err;
        }
    }

    async acceptProject(accessToken: string, id: string): Promise<Project | null> {
        try {
            const res = await this.axiosConnector.put(
                `/projects/${id}/status`,
                { value: 'project_accepted' },
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            this.logger.error(err);
            throw err;
        }
    }

    async refuseProject(accessToken: string, id: string): Promise<Project | null> {
        try {
            const res = await this.axiosConnector.put(
                `/projects/${id}/status`,
                { value: 'project_rejected' },
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            this.logger.error(err);
            throw err;
        }
    }

    async updateStatus(accessToken: string, id: string, status: string): Promise<Project | null> {
        try {
            const res = await this.axiosConnector.put(
                `/projects/${id}/status`,
                { value: status },
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            this.logger.error(err);
            throw err;
        }
    }

    async updateSurvey(
        accessToken: string,
        id: string,
        startDate?: Date,
        endDate?: Date,
        status?: string,
    ): Promise<Project | null> {
        try {
            const res = await this.axiosConnector.patch(
                `/projects/${id}/survey`,
                { startDate, endDate, status },
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            this.logger.error(err);
            throw err;
        }
    }

    async updateInstallation(
        accessToken: string,
        id: string,
        startDate: Date,
        endDate: Date,
        status?: string,
    ): Promise<Project | null> {
        try {
            const res = await this.axiosConnector.put(
                `/projects/${id}/installation`,
                { startDate, endDate, status },
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            this.logger.error(err);
            throw err;
        }
    }

    async updateDelivery(accessToken: string, delivery: Delivery): Promise<Delivery | null> {
        try {
            const res = await this.axiosConnector.patch(
                `/deliveries/${delivery.id}`,
                { delivery: delivery },
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            this.logger.error(err);
            throw err;
        }
    }

    async updateDeliveryStatus(accessToken: string, delivery: Delivery): Promise<Delivery | null> {
        try {
            const res = await this.axiosConnector.put(
                `/deliveries/${delivery.id}/status`,
                { value: delivery.status },
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            this.logger.error(err);
            throw err;
        }
    }

    async createQuote(
        accessToken: string,
        projectId: string,
        reference: string,
        amount: number,
        description: string,
    ): Promise<Quote | null> {
        try {
            const res = await this.axiosConnector.post(
                '/quotes',
                { projectId, reference, amount, description },
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            this.logger.error(err);
            throw err;
        }
    }

    async createBilling(
        accessToken: string,
        projectId: string,
        reference: string,
        amount: number,
    ): Promise<Billing | null> {
        try {
            const res = await this.axiosConnector.post(
                '/billings',
                { projectId, reference, amount },
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            this.logger.error(err);
            throw err;
        }
    }

    async createSurvey(accessToken: string, projectId: string): Promise<Survey | null> {
        try {
            const res = await this.axiosConnector.post(`/survey`, { projectId }, this.authenticated(accessToken));
            return res.data;
        } catch (err) {
            this.logger.error(err);
            throw err;
        }
    }

    async changeUserLanguage(accessToken: string, lang: string): Promise<AppUser | null> {
        try {
            const res = await this.axiosConnector.put(
                `/users/${accessToken}/lang`,
                { lang },
                this.authenticated(accessToken),
            );
            return res.data;
        } catch (err) {
            this.logger.error(err);
            throw err;
        }
    }

    async retrieveFileByS3Key(accessToken: string, projectId: string, s3Key: string): Promise<string | null> {
        try {
            const res = await this.axiosConnector.get(`/files/${projectId}/${s3Key}`, this.authenticated(accessToken));
            return res.data;
        } catch (err) {
            this.logger.error(err);
            throw err;
        }
    }
    async createDocument(accessToken: string, document: DocumentDto): Promise<Document> {
        try {
            const res = await this.axiosConnector.post(`/files`, document, this.authenticated(accessToken));
            return res.data;
        } catch (err) {
            this.logger.error(err);
            throw err;
        }
    }
}
