import { Grid, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { theme } from 'src/ui/theme';

function InfoLineValue({
    value,
    dataTestId,
}: {
    value: string | number | boolean | undefined | null;
    dataTestId?: string;
}) {
    const { t } = useTranslation();
    let valueOverridden = value === undefined || value === null ? '-' : value;
    if (typeof value === 'boolean') {
        valueOverridden = value ? t('true') : t('false');
    }
    return (
        <Typography fontWeight="700" sx={{ color: theme.palette.neutral[900] }} data-test-id={dataTestId}>
            {valueOverridden}
        </Typography>
    );
}

export default function InfoLine({
    label,
    value,
    dataTestId,
}: {
    label: string;
    value: string | string[] | undefined | number | boolean | null;
    dataTestId?: string;
}) {
    const { t } = useTranslation();

    return (
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
            <Grid xs={6}>
                <Typography level="body-md">{t(label)}</Typography>
            </Grid>
            <Grid xs={6}>
                {Array.isArray(value) ? (
                    value.map((v) => <InfoLineValue value={v} dataTestId={dataTestId} />)
                ) : (
                    <InfoLineValue value={value} dataTestId={dataTestId} />
                )}
            </Grid>
        </Grid>
    );
}
