const FlagEnglishIcon = () => {
    return (
        <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3995_2881)">
                <rect width="30" height="22" fill="#012169" />
                <path d="M0 0L30 22M30 0L0 22" stroke="white" strokeWidth="4" />
                <path d="M0 0L30 22M30 0L0 22" stroke="#C8102E" strokeWidth="2" />
                <path d="M15 0V22M0 11H30" stroke="white" strokeWidth="6" />
                <path d="M15 0V22M0 11H30" stroke="#C8102E" strokeWidth="4" />
            </g>
            <defs>
                <clipPath id="clip0_3995_2881">
                    <rect width="30" height="22" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default FlagEnglishIcon;
