import { Stack, SvgIcon, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../molecules/Button';
import LeftGreyArrowIcon from '../atoms/icons/LeftGreyArrow';

interface BackButtonProps {
    path: string;
    label: string;
    page?: number;
}

function BackButton({ path, label, page }: BackButtonProps) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Button
            color="grey"
            variant="plain"
            sx={{ paddingLeft: '0', paddingRight: '0', width: 'fit-content' }}
            onClick={() => navigate(path, { state: { page } })}
        >
            <Stack direction="row" alignItems="center">
                <SvgIcon
                    alignmentBaseline="central"
                    sx={{ marginRight: '8px', width: '13px', height: '11px' }}
                    viewBox="0 0 13 11"
                >
                    <LeftGreyArrowIcon />
                </SvgIcon>
                <Typography level="title-lg" sx={{ color: 'grey.500' }}>
                    {t(label)}
                </Typography>
            </Stack>
        </Button>
    );
}

export default BackButton;
