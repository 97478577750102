import { useContext, useEffect } from 'react';
import { ProjectsContext } from '../contexts/project-context';
import { projectActions } from 'src/core/project/service';
import { PageTemplate } from '../layout/PageTemplate';
import Loader from '../molecules/Loader';
import { Stack } from '@mui/joy';
import { DocumentCards } from '../molecules/documents/DocumentCard';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { useAppAuth } from '../contexts/auth-context';
import BackButton from '../molecules/BackButton';
import DocumentForm from '../molecules/documents/DocumentForm';
import QuoteCard from '../molecules/quote/QuoteCard';

export function QuotePage() {
    const { projectState, dispatch } = useContext(ProjectsContext)!;
    const { id, quoteId } = useParams();
    const { authenticatedCall } = useAppAuth();
    useEffect(() => {
        if (id) {
            authenticatedCall(async (accessToken) => dispatch(projectActions.loadOne(accessToken, id)));
        }
    }, [dispatch, id, authenticatedCall]);
    const project = projectState.project;

    const parentEntityId = quoteId;
    const parentEntityName = 'quote';
    const type = 'quote';
    const documents =
        project && project.documents
            ? project.documents.filter((doc) => {
                  return doc.type === type && doc.parentEntityId === quoteId;
              })
            : [];
    const quote = project?.quotes.find((quote) => quote?.id === quoteId);

    return (
        <PageTemplate greyBackground>
            <Stack
                sx={{ height: '100%', paddingX: '20px', paddingY: '40px', gap: '16px' }}
                data-test-id="documents-page"
            >
                <BackButton path={`/projects/${project?.id}/quotes`} label={t(`projects.generic_data.back`)} />

                <br />
                <QuoteCard item={quote ?? null} small />
                <br />
                {project && project.id ? (
                    <>
                        <DocumentCards documents={documents} />
                        <DocumentForm
                            projectId={project.id}
                            parentEntityName={parentEntityName ?? ''}
                            parentEntityId={parentEntityId ?? ''}
                            type={type ?? ''}
                            showTitleInput={true}
                            showSubmitButton={true}
                            callback={() => {
                                authenticatedCall(async (accessToken) =>
                                    dispatch(projectActions.loadOne(accessToken, project.id)),
                                );
                            }}
                        />
                    </>
                ) : (
                    <Loader />
                )}
            </Stack>
        </PageTemplate>
    );
}
