import { Radio, RadioGroup, Sheet, Stack, Typography, radioClasses } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { DateValue } from './DateModal';
import { theme } from 'src/ui/theme';
import isDate from 'src/utils/isDate';

export type slotMapping = {
    hour: number;
    min: number;
};
const slotMappingTab: slotMapping[] = [
    { hour: 7, min: 0 },
    { hour: 7, min: 30 },
    { hour: 8, min: 0 },
    { hour: 8, min: 30 },
    { hour: 9, min: 0 },
    { hour: 9, min: 30 },
    { hour: 10, min: 0 },
    { hour: 10, min: 30 },
    { hour: 11, min: 0 },
    { hour: 11, min: 30 },
    { hour: 12, min: 0 },
    { hour: 12, min: 30 },
    { hour: 13, min: 0 },
    { hour: 13, min: 30 },
    { hour: 14, min: 0 },
    { hour: 14, min: 30 },
    { hour: 15, min: 0 },
    { hour: 15, min: 30 },
    { hour: 16, min: 0 },
    { hour: 16, min: 30 },
    { hour: 17, min: 0 },
    { hour: 17, min: 30 },
    { hour: 18, min: 0 },
    { hour: 18, min: 30 },
    { hour: 19, min: 0 },
    { hour: 19, min: 30 },
    { hour: 20, min: 0 },
];

function isRadioDisabled(slot: slotMapping, hour: number, min: number): boolean {
    if (slot.hour < hour) {
        return true;
    }
    if (slot.hour === hour && slot.min < min) {
        return true;
    }
    return false;
}

interface SlotButtonProps {
    slot: number;
    hourSelected: number | null;
    handleChange: Function;
    disable?: boolean;
    activeStartDate?: Date;
}

function SlotButton({ handleChange, slot, hourSelected, disable }: SlotButtonProps) {
    const { t } = useTranslation();

    return (
        <Sheet
            key={slot}
            sx={{
                position: 'relative',
                minWidth: 70,
                height: 36,
                flexShrink: 0,
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '--joy-focus-outlineOffset': '4px',
                [`& .${radioClasses.checked}`]: {
                    [`& .${radioClasses.label}`]: {
                        fontWeight: '700',
                    },
                    [`& .${radioClasses.action}`]: {
                        border: 'none',
                        background: theme.palette.primary[500],
                    },
                },
                [`& .${radioClasses.action}`]: {
                    borderColor: theme.palette.grey[200],
                },
            }}
        >
            <Radio
                color="neutral"
                overlay
                disableIcon
                disabled={disable}
                checked={slot === hourSelected}
                value={slot}
                label={t('formattedHours', {
                    hour: slotMappingTab[slot].hour,
                    min: slotMappingTab[slot].min,
                })}
                key={slot}
                onChange={() => handleChange(slot)}
                data-test-id={`date-slot-${slot.toString()}`}
            />
        </Sheet>
    );
}

interface HoursChoiceProps {
    date: DateValue;
    setDate: Function;
    setIsSelected: Function;
    hourSelected: number | null;
    setHourSelected: Function;
    setEndDate: Function | null;
    activeStartDate?: Date;
}

export default function HoursChoice({
    date,
    setDate,
    setIsSelected,
    hourSelected,
    setHourSelected,
    setEndDate,
    activeStartDate,
}: HoursChoiceProps) {
    const { t } = useTranslation();

    const handleChange = (value: number) => {
        setHourSelected(value);
        if (isDate(date)) {
            date.setHours(slotMappingTab[value].hour);
            date.setMinutes(slotMappingTab[value].min);
            setDate(date);
            setIsSelected(true);
            if (setEndDate != null) {
                setEndDate(date);
            }
        }
    };

    let startHour: number = 0;
    let startMinute: number = 0;
    if (activeStartDate) {
        startHour = activeStartDate.getHours();
        startMinute = activeStartDate.getMinutes();
    }

    return (
        <Stack marginTop="20px">
            <RadioGroup
                aria-labelledby="product-size-attribute"
                defaultValue="M"
                sx={{ gap: 2, mb: 2, flexWrap: 'wrap', flexDirection: 'row', marginLeft: '2px', marginRight: '2px' }}
            >
                <Stack>
                    <Typography level="body-xs">{t('date_modal.morning')}&nbsp;:</Typography>
                    <Stack direction="row" flexWrap="wrap" justifyContent="space-between" gap="12px" minWidth="220px">
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((slot) => (
                            <SlotButton
                                slot={slot}
                                handleChange={handleChange}
                                hourSelected={hourSelected}
                                disable={
                                    activeStartDate && isRadioDisabled(slotMappingTab[slot], startHour, startMinute)
                                }
                                key={slot}
                            />
                        ))}
                    </Stack>
                    <Typography level="body-xs" sx={{ marginTop: '20px' }}>
                        {t('date_modal.afternoon')}&nbsp;:
                    </Typography>
                    <Stack direction="row" flexWrap="wrap" justifyContent="space-between" gap="12px" minWidth="220px">
                        {[12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26].map((slot) => (
                            <SlotButton
                                slot={slot}
                                handleChange={handleChange}
                                hourSelected={hourSelected}
                                disable={
                                    activeStartDate && isRadioDisabled(slotMappingTab[slot], startHour, startMinute)
                                }
                                key={slot}
                            />
                        ))}
                    </Stack>
                </Stack>
            </RadioGroup>
        </Stack>
    );
}
