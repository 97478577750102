import { Card, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';

import { projectActions } from '../../../core/project/service';
import { useAppAuth } from '../../contexts/auth-context';
import { ProjectsContext } from '../../contexts/project-context';
import FloatingLabelInput from '../forms/FloatingLabelInput';
import Button from '../Button';
import DocumentForm, { DocumentDto } from '../documents/DocumentForm';

export interface BillingFormItem {
    reference: string;
    amount: number;
    description?: string;
}

export default function BillingForm({ projectId, callback }: { projectId: string; callback?: () => void }) {
    const { t } = useTranslation();
    const { authenticatedCall } = useAppAuth();
    const { dispatch } = useContext(ProjectsContext)!;
    const [billingToCreate, setBillingToCreate] = useState<BillingFormItem>({ reference: '', amount: 0 });
    const [loading, setLoading] = useState(false);
    const [billingDocuments, setBillingDocuments] = useState<DocumentDto[]>([]);
    const [formKey, setFormKey] = useState(0);

    const submit = async () => {
        try {
            await authenticatedCall(async (accessToken) =>
                dispatch(
                    projectActions.createBilling(
                        accessToken,
                        projectId,
                        billingToCreate.reference,
                        billingToCreate.amount,
                        billingDocuments,
                    ),
                ),
            );

            // reset billing form completely
            setBillingToCreate({ reference: '', amount: 0 });
            setBillingDocuments([]);
            setFormKey((prevKey) => prevKey + 1);

            if (callback) {
                callback();
            }
        } catch (e: any) {
            console.error(e.error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Card variant="plain" key={formKey}>
            <Typography level="h4" alignSelf="center">
                {t('billing.new_billing')}
            </Typography>
            <FloatingLabelInput
                label={t('billing.reference')}
                value={billingToCreate?.reference}
                onChange={(e) => setBillingToCreate({ ...billingToCreate, reference: e.target.value })}
                data-test-id="reference-input"
            />
            <FloatingLabelInput
                label={t('billing.amount')}
                value={billingToCreate?.amount}
                type="number"
                onChange={(e) => setBillingToCreate({ ...billingToCreate, amount: parseFloat(e.target.value) })}
                data-test-id="amount-input"
            />
            <DocumentForm
                projectId={projectId}
                type="billing"
                parentEntityName="billing"
                parentEntityId=""
                showTitleInput={false}
                showSubmitButton={false}
                callback={(documents: DocumentDto[]) => {
                    setBillingDocuments(documents);
                }}
            />
            <Button
                onClick={submit}
                disabled={
                    !billingToCreate.reference ||
                    !billingToCreate.amount ||
                    !billingDocuments ||
                    billingDocuments.length === 0 ||
                    loading
                }
                loading={loading}
                label={t('billing.submit')}
                data-test-id="submit"
            />
        </Card>
    );
}
