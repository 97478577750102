export interface Project {
    user: User;
    id: string;
    client: Client;
    address: Address;
    installerAddress: Address;
    status: ProjectStatus;
    description: string;
    reference: string;
    isFocus: boolean;
    type: ProjectType;
    typology: ProjectTypology;
    survey?: Survey;
    installation?: Installation;
    surveyMandatory?: boolean;
    proposedConfiguration: ProjectConfiguration;
    proposedEstimate: ProjectEstimate;
    proposedEnergyManagement: ProjectEnergyManagement;
    actualConfiguration: ProjectConfiguration;
    actualEstimate: ProjectEstimate;
    actualEnergyManagement: ProjectEnergyManagement;
    technicalContact: TechnicalContact;
    pictures: Array<Picture>;
    purchaseOrders?: Array<PurchaseOrder>;
    history: Array<History>;
    missions: Array<ProjectMission>;
    deliveries: Array<Delivery>;
    quotes: Array<Quote>;
    documents: Array<Document>;
    billings: Array<Billing>;
    extraCostSurvey?: boolean;
    remoteSurvey?: boolean;
}

export type ProjectType =
    | 'company'
    | 'multi_family_home'
    | 'single_home'
    | 'public_establishment'
    | 'hotel'
    | 'guesthouse'
    | 'bed_and_breakfast'
    | 'shopping_center'
    | 'golf'
    | 'restaurant'
    | 'parking'
    | 'camping'
    | 'other'
    | 'store'
    | 'supermarket'
    | 'dealership'
    | 'car_leasing';

export interface User {
    id: string;
    authId: string;
    companyId?: string;
    email?: string;
}

export interface ProjectMission {
    id: string;
    title: string;
    order: number;
}

export interface Picture {
    name: string;
    type: 'proposal' | 'survey' | 'installation';
    id: string;
}

export interface History {
    id: string;
    oldValue: string;
    newValue: string;
    fieldName: string;
    date: string;
    parentEntityId: string;
    parentEntityName: string;
}

export interface Document {
    id: string;
    name: string;
    title: string;
    fileKey: string;
    size: number;
    mimeType: string;
    type: string;
    parentEntityId: string;
    parentEntityName: string;
    createdAt: string;
    updatedAt: string;
}

export type QuoteStatus = 'new' | 'review_pending' | 'validated' | 'rejected' | 'signed';

export interface Quote {
    id: string;
    reference: string;
    amount: number;
    description: string;
    status: QuoteStatus;
    date: Date;
}

export type BillingStatus = 'review_pending' | 'rejected' | 'validated' | 'paid';
export interface Billing {
    id: string;
    reference: string;
    amount: number;
    status: BillingStatus;
    date: Date;
}

export type DeliveryStatus = 'shipped' | 'received';

export interface Delivery {
    id: string;
    referenceNumber: string;
    address: Address;
    hasIssues: boolean;
    status: DeliveryStatus;
    trackingUrl?: string;
    items: Array<DeliveryItem>;
}

export interface DeliveryItem {
    name: string;
    serialNumbers: Array<string>;
    quantity: number;
    received: boolean;
}

export interface Survey {
    id: string;
    status: string;
    startDate: string; // yyyy-mm-dd
    endDate: string; // yyyy-mm-dd
    formLink?: string;
    pdfKey?: string;
    cancellationReason?: string;
}

export interface Installation {
    id: string;
    startDate: string; // yyyy-mm-dd
    endDate: string; // yyyy-mm-dd

    status: InstallationStatus;
    formLink?: string;
    pdfKey?: string;

    preInstallationStatus?: string;
    preInstallationFormLink?: string;
    preInstallationFileS3Key?: string;

    electricalInstallation: ElectricalInstallation;
    installedChargingStations: InstalledChargingStations;
    protectionAndPowerSupply: ProtectionAndPowerSupply;
    installationHandoverToClient: InstallationHandoverToClient;
    clientSignature: ClientSignature;
}

export interface InstalledChargingStations {
    numberOfChargingStations?: number;
    numberOfChargingPoints?: number;
    chargingStations?: Array<ChargingStation>;
}

export interface ChargingStation {
    brand: string;
    model: string;
    serialNumber: string;
    manufactureDate: string; // yyyy-mm-dd
}

export interface ProtectionAndPowerSupply {
    type?: string;
    rating?: string;
    reference?: string;
    section?: string;
    numberOfPhases?: string;
    landValue?: string;
    measurementPointsLocation?: string;
    powerManagementAdjustmentInstruction?: string;
    installationSupport?: InstallationSupport;
}

export type InstallationSupport = 'Wall' | 'Floor' | 'Pole' | 'Other';

export interface InstallationHandoverToClient {
    installationCompletedAsExpected?: boolean;
    installationHasBeenTested?: boolean;
    vehicleModelUsedForTesting?: string;
    clientHasRecommendedPower?: boolean;
    clientReceptionOfDocumentation?: boolean;
    clientReceptionOfKeys?: boolean;
    clientHasReserves?: boolean;
}

export interface ClientSignature {
    name?: string;
    signature?: string;
    date?: string;
}

export interface ElectricalInstallation {
    electricalInstallation?: string;
    clientPowerSubscription?: string;
    connectionCircuitBreakerRating?: string;
}

export interface ProjectEnergyManagement {
    ticLink?: string | null;
}

export interface ProjectConfiguration {
    electricalInstallationType?: string | null;
    protectionsType?: string | null;
    subscribedPower?: number | null;
    consumptionPeak?: number | null;
    groundValue?: number | null;
    neutralValue?: string | null;
    existingSheath?: boolean | null;
    protectionBrand?: string | null;
    unblockedSheath?: boolean | null;
}

export interface ProjectEstimate {
    chargerNumber?: number | null;
    chargePower?: number | null;
    totalPowerOfInstalledChargers?: number | null;
    installationType?: string | null;
    mainUser?: string | null;
    chargersPowerSource?: string | null;
    additionnalSwitchBoard?: boolean | null;
    addingSurgeProtector?: boolean | null;
}

export interface TechnicalContact {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
}

export interface PurchaseOrder {
    id: string;
    amount: number;
}

export interface Client {
    id: string;
    name: string;
    parentName: string;
    firstName: string;
    lastName: string;
    address: Address;
    isVIP: boolean;
    type: string;
    phone: string;
    email: string;
}

export interface Address {
    street1: string;
    street2: string;
    street3: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    countryCode: string;
}

export type ProjectStatus =
    | 'project_proposed'
    | 'project_rejected'
    | 'project_accepted'
    | 'survey_planned'
    | 'survey_conducted'
    | 'quote_proposed'
    | 'waiting_for_new_quote'
    | 'quote_validated'
    | 'quote_signed'
    | 'quote_obsolete'
    | 'delivery'
    | 'installation'
    | 'delivery_and_installation' // obsolete
    | 'waiting_for_legal_documents_upload'
    | 'waiting_for_billing_upload'
    | 'waiting_for_billing_validation'
    | 'project_completed'
    | 'project_lost';

export type ProjectTypology = 'maintenance visit' | 'full installation' | 'technical visit';

export type SurveyStatus = 'date_proposed_to_client' | 'waiting_for_execution' | 'completed';
export type InstallationStatus = 'date_proposed_to_client' | 'waiting_for_execution' | 'completed';

export const getClient = (status: ProjectStatus, client: Client) => {
    let clientName: string;
    let clientParentName: string;
    let clientFirstName: string;
    let clientLastName: string;
    let clientPhone: string;
    let clientEmail: string;
    let clientType: string;
    let toBlur: boolean;
    switch (status) {
        case 'project_proposed':
        case 'project_rejected':
        case 'project_lost':
            clientName = 'Anonymous';
            clientParentName = 'Anonymous';
            clientFirstName = 'Anonymous';
            clientLastName = 'Anonymous';
            clientPhone = '0000000000';
            clientEmail = 'anonymous@mail.com';
            clientType = 'individual';
            toBlur = true;
            break;
        default:
            clientName = client.name;
            clientParentName = client.parentName;
            clientFirstName = client.firstName;
            clientLastName = client.lastName;
            clientPhone = client.phone;
            clientEmail = client.email;
            clientType = client.type;
            toBlur = false;
            break;
    }
    return {
        clientName,
        clientParentName,
        clientFirstName,
        clientLastName,
        clientPhone,
        clientEmail,
        clientType,
        toBlur,
    };
};

export const getPercentageForProjectStatus = (status: ProjectStatus) => {
    let percentage = 0;

    switch (status) {
        case 'project_proposed':
            percentage = 10;
            break;
        case 'project_accepted':
            percentage = 15;
            break;
        case 'survey_planned':
            percentage = 20;
            break;
        case 'survey_conducted':
        case 'quote_proposed':
        case 'waiting_for_new_quote':
        case 'quote_validated':
            percentage = 40;
            break;
        case 'project_rejected':
        case 'project_lost':
            percentage = 0;
            break;
        case 'delivery':
        case 'installation':
            // case 'waiting_for_sign_off_sheet_signature':
            percentage = 60;
            break;
        case 'waiting_for_billing_upload':
        case 'waiting_for_billing_validation':
            percentage = 80;
            break;
        case 'project_completed':
            percentage = 100;
            break;
        default:
            percentage = 0;
            break;
    }

    return { percentage };
};
