const ProjectIcon = () => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" stroke="#1B2A50" xmlns="http://www.w3.org/2000/svg">
            <g id="Group 30">
                <path
                    id="Ellipse 280"
                    d="M13.9216 3.22266C15.3055 4.19168 16.3471 5.57394 16.8972 7.17133C17.4472 8.76872 17.4774 10.4992 16.9834 12.1148C16.4895 13.7305 15.4968 15.1482 14.1475 16.165C12.7983 17.1817 11.1618 17.7452 9.47263 17.7747C7.78345 17.8041 6.1283 17.2981 4.7444 16.3291C3.36049 15.3601 2.31889 13.9778 1.76886 12.3804C1.21883 10.783 1.18863 9.05251 1.68257 7.4369C2.17651 5.82129 3.16924 4.40352 4.51849 3.38679"
                    fill="none"
                    stroke="#1B2A50"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
                <line
                    id="Line 5"
                    x1="9.67383"
                    y1="0.972656"
                    x2="9.67383"
                    y2="9.47266"
                    fill="none"
                    stroke="#1B2A50"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
            </g>
        </svg>
    );
};

export default ProjectIcon;
