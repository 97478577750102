import { Button, List, ListItem, Stack, Typography } from '@mui/joy';
import { ChevronLeftIcon } from '../atoms/icons/ChevronLeftIcon';
import { ChevronRightIcon } from '../atoms/icons/ChevronRightIcon';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ProjectsContext } from '../contexts/project-context';
import { projectActions } from 'src/core/project/service';

interface InputProps {
    count: number;
    pageSize: number;
    onPageChange: (fromIdx: number, toIdx: number) => void;
    fromId: number;
    entity?: 'project';
}

function minIndex(selectedPageId: number, pageCount: number): number {
    return (selectedPageId - 1) * pageCount;
}

function maxIndex(selectedPageId: number, pageSize: number, itemCount: number): number {
    return Math.min((selectedPageId - 1) * pageSize + pageSize - 1, itemCount);
}

export default function Pagination({ count, pageSize, onPageChange, fromId, entity }: InputProps) {
    const { dispatch, projectState } = useContext(ProjectsContext)!;
    const [selectedPageId, setSelectedPageId] = useState(projectState.page);
    useEffect(() => {
        setSelectedPageId(projectState.page);
        if (fromId === 0) {
            setSelectedPageId(1);
            dispatch(projectActions.setCurrentPage(1));
        }
    }, [dispatch, fromId, projectState.page, selectedPageId]);
    const pageCount = Math.max(Math.ceil(count / pageSize), 1);
    let pageIds: number[] = [];
    for (let i = 1; i <= pageCount; i++) {
        if (pageCount > 5) {
            if (
                i === 1 ||
                i === pageCount ||
                i === selectedPageId ||
                i === selectedPageId + 1 ||
                i === selectedPageId - 1
            ) {
                pageIds.push(i);
            } else if (selectedPageId === 1 && (i === selectedPageId + 2 || i === selectedPageId + 3)) {
                pageIds.push(i);
            } else if (selectedPageId === 2 && i === selectedPageId + 2) {
                pageIds.push(i);
            } else if (selectedPageId === pageCount - 1 && i === selectedPageId - 2) {
                pageIds.push(i);
            } else if (selectedPageId === pageCount && (i === selectedPageId - 2 || i === selectedPageId - 3)) {
                pageIds.push(i);
            }
        } else {
            pageIds.push(i);
        }
    }
    const onPrevious = useCallback(() => {
        if (selectedPageId <= 1) {
            return;
        }
        const newSelectedPageId = selectedPageId - 1;
        if (entity === 'project') dispatch(projectActions.setCurrentPage(newSelectedPageId));
        setSelectedPageId(newSelectedPageId);
        onPageChange(minIndex(newSelectedPageId, pageSize), maxIndex(newSelectedPageId, pageSize, count));
    }, [selectedPageId, setSelectedPageId, onPageChange, pageSize, count, dispatch, entity]);
    const onNext = useCallback(() => {
        if (selectedPageId >= pageCount) {
            return;
        }
        const newSelectedPageId = selectedPageId + 1;
        if (entity === 'project') dispatch(projectActions.setCurrentPage(newSelectedPageId));
        setSelectedPageId(newSelectedPageId);
        onPageChange(minIndex(newSelectedPageId, pageSize), maxIndex(newSelectedPageId, pageSize, count));
    }, [selectedPageId, setSelectedPageId, onPageChange, pageCount, pageSize, count, dispatch, entity]);
    const onArbitraryChange = useCallback(
        (pageId: number) => () => {
            if (entity === 'project') dispatch(projectActions.setCurrentPage(pageId));
            setSelectedPageId(pageId);
            onPageChange(minIndex(pageId, pageSize), maxIndex(pageId, pageSize, count));
        },
        [setSelectedPageId, onPageChange, pageSize, count, dispatch, entity],
    );

    return (
        <Stack direction="row" alignItems="center" justifyContent="center">
            <Button
                color="neutral"
                disabled={selectedPageId === 1}
                onClick={onPrevious}
                sx={{ opacity: selectedPageId === 1 ? 0.5 : 1 }}
                variant="plain"
            >
                <ChevronLeftIcon size={14} />
            </Button>
            <List orientation="horizontal" sx={{ paddingInlineStart: '8px', paddingInlineEnd: '8px', flexGrow: 0 }}>
                {pageIds.map((pageId, index) => (
                    <ListItem sx={{ paddingInlineStart: '2px', paddingInlineEnd: '2px' }} key={pageId}>
                        {pageId !== 1 && pageId - 1 !== pageIds[index - 1] && (
                            <Typography level="title-sm">. . .</Typography>
                        )}
                        {pageId === selectedPageId ? (
                            <Button color="neutral" onClick={onArbitraryChange(pageId)} variant="soft">
                                {pageId}
                            </Button>
                        ) : (
                            <Button color="neutral" onClick={onArbitraryChange(pageId)} variant="plain">
                                {pageId}
                            </Button>
                        )}
                    </ListItem>
                ))}
            </List>
            <Button
                color="neutral"
                disabled={selectedPageId === pageCount}
                onClick={onNext}
                sx={{ opacity: selectedPageId === pageCount ? 0.5 : 1 }}
                variant="plain"
                data-test-id="pagination-next"
            >
                <ChevronRightIcon size={14} />
            </Button>
        </Stack>
    );
}
