import { extendTheme } from '@mui/joy/styles';

// https://mui.com/joy-ui/customization/theme-colors/

export const theme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    100: '#E5FEFA',
                    300: '#BFFCF3',
                    500: '#00F4CD',
                    700: '#07816D',
                    solidColor: 'var(--joy-palette-secondary-500)',
                    solidHoverBg: 'var(--joy-palette-primary-300)',
                    solidActiveBg: 'var(--joy-palette-primary-100)',

                    softBg: 'var(--joy-palette-primary-300)',
                    softColor: 'var(--joy-palette-secondary-500)',
                    softHoverBg: 'var(--joy-palette-primary-100)',
                    softActiveBg: 'var(--joy-palette-primary-500)',

                    outlinedActiveBg: 'var(--joy-palette-primary-300)',
                    plainActiveBg: 'var(--joy-palette-primary-300)',
                },
                secondary: {
                    100: '#DFEBFD',
                    300: '#CCD6E6',
                    500: '#1B2A50',
                    outlinedBorder: 'var(--joy-palette-secondary-500)',
                    outlinedColor: 'var(--joy-palette-secondary-500)',
                    outlinedHoverBg: 'var(--joy-palette-secondary-100)',
                    outlinedHoverBorder: 'var(--joy-palette-secondary-500)',
                    outlinedActiveBg: 'var(--joy-palette-secondary-300)',

                    plainColor: 'var(--joy-palette-secondary-500)',
                    plainHoverBg: 'var(--joy-palette-secondary-100)',
                    plainActiveBg: 'var(--joy-palette-secondary-300)',

                    softBg: 'var(--joy-palette-secondary-300)',
                    softColor: 'var(--joy-palette-secondary-500)',
                    softHoverBg: 'var(--joy-palette-secondary-50)',
                    softActiveBg: 'var(--joy-palette-secondary-100)',

                    solidBg: 'var(--joy-palette-secondary-500)',
                    solidColor: 'var(--joy-palette-neutral-50)',
                    solidHoverBg: 'var(--joy-palette-secondary-300)',
                    solidActiveBg: 'var(--joy-palette-secondary-100)',

                    // // Disabled
                    outlinedDisabledBorder: 'var(--joy-palette-neutral-200)',
                    outlinedDisabledColor: 'var(--joy-palette-neutral-400)',
                    plainDisabledColor: 'var(--joy-palette-neutral-400)',
                    softDisabledBg: 'var(--joy-palette-neutral-50)',
                    softDisabledColor: 'var(--joy-palette-neutral-400)',
                    solidDisabledBg: 'var(--joy-palette-neutral-100)',
                    solidDisabledColor: 'var(--joy-palette-neutral-400)',
                },
                danger: {
                    50: '#FFDCDC',
                    300: '#FE8A8A',
                    500: '#FD5050',

                    outlinedActiveBg: 'var(--joy-palette-danger-300)',
                    outlinedHoverBg: 'var(--joy-palette-danger-50)',
                    outlinedBorder: 'var(--joy-palette-danger-500)',
                    outlinedColor: 'var(--joy-palette-danger-500)',

                    plainColor: 'var(--joy-palette-danger-500)',
                    plainActiveBg: 'var(--joy-palette-danger-300)',
                    plainHoverBg: 'var(--joy-palette-danger-50)',

                    softColor: 'var(--joy-palette-danger-500)',
                    softHoverBg: 'var(--joy-palette-danger-300)',
                    softHoverColor: 'var(--joy-palette-neutral-50)',
                    softActiveBg: 'var(--joy-palette-danger-50)',
                    softActiveColor: 'var(--joy-palette-danger-300)',

                    solidActiveBg: 'var(--joy-palette-danger-50)',
                    solidColor: 'var(--joy-palette-neutral-50)',
                    solidBg: 'var(--joy-palette-danger-500)',
                    solidHoverBg: 'var(--joy-palette-danger-300)',
                },
                grey: {
                    100: '#F0F2F7',
                    200: '#C5C9D3',
                    500: '#8C94A7',

                    outlinedActiveBg: 'var(--joy-palette-grey-200)',
                    outlinedHoverBg: 'var(--joy-palette-grey-100)',
                    outlinedBorder: 'var(--joy-palette-grey-500)',
                    outlinedColor: 'var(--joy-palette-grey-500)',

                    plainColor: 'var(--joy-palette-grey-500)',
                    plainActiveBg: 'var(--joy-palette-grey-200)',
                    plainHoverBg: 'var(--joy-palette-grey-100)',

                    softColor: 'var(--joy-palette-neutral-900)',
                    softBg: 'var(--joy-palette-grey-100)',
                    softHoverBg: 'var(--joy-palette-grey-200)',
                    softActiveBg: 'var(--joy-palette-grey-500)',

                    solidActiveBg: 'var(--joy-palette-grey-100)',
                    solidColor: 'var(--joy-palette-neutral-900)',
                    solidBg: 'var(--joy-palette-grey-500)',
                    solidHoverBg: 'var(--joy-palette-grey-200)',

                    // // Disabled
                    outlinedDisabledBorder: 'var(--joy-palette-neutral-200)',
                    outlinedDisabledColor: 'var(--joy-palette-neutral-400)',
                    plainDisabledColor: 'var(--joy-palette-neutral-400)',
                    softDisabledBg: 'var(--joy-palette-neutral-50)',
                    softDisabledColor: 'var(--joy-palette-neutral-400)',
                    solidDisabledBg: 'var(--joy-palette-neutral-100)',
                    solidDisabledColor: 'var(--joy-palette-neutral-400)',
                },
                neutral: {
                    50: '#FFFFFF',
                    900: '#17181A',
                    solidColor: 'var(--joy-palette-neutral-50)',
                    solidBg: 'var(--joy-palette-neutral-900)',
                    solidHoverBg: 'var(--joy-palette-grey-500)',
                    solidActiveBg: 'var(--joy-palette-grey-200)',

                    outlinedColor: 'var(--joy-palette-neutral-900)',
                    outlinedBorder: 'var(--joy-palette-neutral-900)',
                    outlinedHoverBg: 'var(--joy-palette-grey-100)',
                    outlinedActiveBg: 'var(--joy-palette-grey-200)',

                    plainColor: 'var(--joy-palette-neutral-900)',
                    plainBg: 'var(--joy-palette-neutral-50)',
                    plainHoverBg: 'var(--joy-palette-grey-100)',
                    plainActiveBg: 'var(--joy-palette-grey-200)',

                    softColor: 'var(--joy-palette-neutral-900)',
                    softHoverBg: 'var(--joy-palette-grey-200)',
                    softActiveBg: 'var(--joy-palette-grey-500)',
                },
                warning: {
                    50: '#FFE9DE',
                    300: '#FFC7AD',
                    500: '#FF905C',

                    softColor: 'var(--joy-palette-warning-500)',
                },
            },
        },
    },
    typography: {
        h1: {
            fontFamily: 'VisbyCFBold',
            fontWeight: 'bold',
            fontSize: '34px',
            lineHeight: '51px',
        },
        h2: {
            fontFamily: 'VisbyCFBold',
            fontWeight: 'bold',
            fontSize: '28px',
            lineHeight: '42px',
        },
        h3: {
            fontFamily: 'VisbyCFBold',
            fontWeight: 'bold',
            fontSize: '22px',
            lineHeight: '33px',
        },
        h4: {
            fontFamily: 'VisbyCFBold',
            fontWeight: 'bold',
            fontSize: '19px',
            lineHeight: '28px',
        },
        'body-lg': {
            fontSize: '14px',
            lineHeight: '21px',
        },
        'body-md': {
            fontSize: '14px',
            lineHeight: '21px',
        },
        'body-sm': {
            fontSize: '11px',
            lineHeight: '16px',
        },
        'body-xs': {
            fontSize: '9px',
            lineHeight: '14px',
        },
        'title-lg': {
            fontFamily: 'VisbyCFBold',
            fontSize: '14px',
            lineHeight: '21px',
            fontWeight: 700,
        },
        'title-md': {
            fontFamily: 'VisbyCFBold',
            fontSize: '14px',
            lineHeight: '21px',
            fontWeight: 700,
        },
        'title-sm': {
            fontFamily: 'VisbyCFBold',
            fontSize: '11px',
            lineHeight: '16px',
            fontWeight: 700,
        },
    },
    fontFamily: {
        display: 'VisbyCFRegular',
        body: 'VisbyCFRegular',
    },
});
