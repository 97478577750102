import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectsContext } from '../contexts/project-context';
import { projectActions } from 'src/core/project/service';
import { PageTemplate } from '../layout/PageTemplate';
import { useAppAuth } from '../contexts/auth-context';
import Loader from '../molecules/Loader';

import BackButton from '../molecules/BackButton';
import { Stack } from '@mui/joy';
import { DocumentCards } from '../molecules/documents/DocumentCard';
import DocumentForm from '../molecules/documents/DocumentForm';
import { t } from 'i18next';
import PageTitleCard from '../molecules/accounting/PageTitleCard';

export function LegalDocumentsPage() {
    const { id } = useParams();
    const { authenticatedCall } = useAppAuth();
    const { projectState, dispatch } = useContext(ProjectsContext)!;

    useEffect(() => {
        if (id) {
            authenticatedCall(async (accessToken) => dispatch(projectActions.loadOne(accessToken, id)));
        }
    }, [dispatch, id, authenticatedCall]);

    // init project
    const project = projectState.project;

    // filter documents by type
    const legalDocuments =
        project && project.documents ? project.documents.filter((doc) => doc.type === 'legal-document') : [];

    return (
        <PageTemplate greyBackground>
            <Stack
                sx={{ height: '100%', paddingX: '20px', paddingY: '40px', gap: '16px' }}
                data-test-id="upload-legal-documents-page"
            >
                <BackButton path={`/projects/${project?.id}`} label={t(`projects.generic_data.back`)} />
                <PageTitleCard
                    isPrimary={false}
                    isWarning={false}
                    title={t('legal_documents.page_title')}
                    subtitle={t('legal_documents.page_subtitle')}
                />

                {project && project.id === id ? (
                    <>
                        <DocumentCards documents={legalDocuments} />
                        <DocumentForm
                            projectId={project.id}
                            parentEntityName="project"
                            parentEntityId={project.id}
                            type="legal-document"
                            showTitleInput={true}
                            showSubmitButton={true}
                            callback={() =>
                                authenticatedCall(async (accessToken) =>
                                    dispatch(projectActions.loadOne(accessToken, project.id)),
                                )
                            }
                        />
                    </>
                ) : (
                    <Loader />
                )}
            </Stack>
        </PageTemplate>
    );
}
