import { Card, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Project } from '../../../core/project/domain';
import { theme } from '../../theme';

interface MissionLineProps {
    value: string;
    id: number;
    dataTestId?: string;
}

function MissionLine({ value, id, dataTestId }: MissionLineProps) {
    const { t } = useTranslation();
    return (
        <Card variant="plain" sx={{ marginTop: '10px', backgroundColor: theme.palette.grey[100] }}>
            {/* <Typography level="title-md" data-test-id={dataTestId}> */}
            {id === 0 ? (
                <span style={{ fontWeight: 'bolder' }}> {t('projects.generic_data.client_needs')}</span>
            ) : (
                <span style={{ fontWeight: 'bold' }}> {t('projects.generic_data.project_configuration')}</span>
            )}

            <div dangerouslySetInnerHTML={{ __html: value }} data-test-id={dataTestId} />
            {/* </Typography> */}
        </Card>
    );
}

interface InputProps {
    project: Project;
}

export default function MissionCard({ project }: InputProps) {
    const { t } = useTranslation();

    return (
        <Card variant="plain">
            <Typography level="h4">{t('projects.generic_data.mission_title')}</Typography>
            {project.missions
                .sort((m1, m2) => m1.order - m2.order)
                .map(({ title }, index) => (
                    <MissionLine value={title} id={index} dataTestId={`mission-${index}`} key={`mission-${index}`} />
                ))}
        </Card>
    );
}
