import { useEffect } from 'react';
import { getUserLang } from '../i18n';
import { useAppAuth } from '../contexts/auth-context';
import { TypeformCSATFormIds } from 'src/utils/constants';

const TypeformEmbedCSAT = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//embed.typeform.com/next/embed.js';
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);
    const { user } = useAppAuth();
    const formId = getUserLang()
        ? TypeformCSATFormIds[getUserLang() as keyof typeof TypeformCSATFormIds]
        : TypeformCSATFormIds.fr;
    const hiddenFields = `email=${user?.email}`;
    console.log(hiddenFields);
    return <div data-tf-live={formId} data-tf-hidden={hiddenFields}></div>;
};

export default TypeformEmbedCSAT;
