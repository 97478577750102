import { Stack } from '@mui/joy';
import { useContext, useEffect } from 'react';
import { Project } from 'src/core/project/domain';
import { ProjectsContext } from '../contexts/project-context';
import { projectActions } from 'src/core/project/service';
import { PageTemplate } from '../layout/PageTemplate';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../molecules/BackButton';
import ProjectOverviewCard from '../molecules/projects/ProjectOverviewCard';
import Loader from '../molecules/Loader';
import MenuLine from '../molecules/MenuLine';
import { useAppAuth } from '../contexts/auth-context';
import { t } from 'i18next';
import getStatusKey from 'src/utils/getStatusKey';

export function isHiddenTab(key: string, countries: Array<string> | undefined, project: Project | null) {
    const countryCode = project?.address?.countryCode ?? '';
    const countryCodeUpper = countryCode.toUpperCase();
    if (countries && countries.includes(countryCodeUpper)) {
        return true;
    }

    const isIQ = !project?.surveyMandatory;
    const isRemoteSurvey = project?.remoteSurvey;

    if (key === 'quotes') {
        if (countryCodeUpper === 'PT') {
            if (isIQ || isRemoteSurvey) {
                return true;
            }
        } else if (countryCodeUpper === 'DE') {
            if (isIQ || isRemoteSurvey) {
                return true;
            }
        }
    }
    return false;
}

export function isDisabledTab(key: string, disabledFor: Array<string>, project: Project | null) {
    const statusKey = getStatusKey(project);

    // disabled for status
    if (disabledFor.includes(statusKey) || disabledFor.includes('*')) {
        return true;
    }

    if (key === 'billings') {
        if (!project?.purchaseOrders || project?.purchaseOrders.length === 0) {
            return true;
        }
    }
    return false;
}

const tabs = [
    {
        key: 'generic-data',
        disabledFor: ['project_rejected'],
    },
    {
        key: 'technical-data',
        disabledFor: ['project_rejected'],
    },
    {
        key: 'quotes',
        disabledFor: ['project_proposed', 'project_rejected', 'survey_to_plan', 'survey_planned'],
    },
    {
        key: 'delivery',
        disabledFor: [
            'project_proposed',
            'project_rejected',
            'survey_to_plan',
            'survey_planned',
            'survey_conducted',
            'quote_proposed',
            'waiting_for_new_quote',
            'quote_validated',
            'quote_signed',
            'quote_obsolete',
        ],
    },
    {
        key: 'pre-installation',
        disabledFor: [
            'project_proposed',
            'project_rejected',
            'survey_to_plan',
            'survey_planned',
            'survey_conducted',
            'quote_proposed',
            'waiting_for_new_quote',
            'quote_validated',
            'quote_signed',
            'quote_obsolete',
        ],
    },
    {
        key: 'installation',
        disabledFor: [
            'project_proposed',
            'project_rejected',
            'survey_to_plan',
            'survey_planned',
            'survey_conducted',
            'quote_proposed',
            'waiting_for_new_quote',
            'quote_validated',
            'quote_signed',
            'quote_obsolete',
        ],
    },
    {
        key: 'legal-documents',
        disabledFor: [
            'project_proposed',
            'project_rejected',
            'survey_to_plan',
            'survey_planned',
            'survey_conducted',
            'quote_proposed',
            'waiting_for_new_quote',
            'quote_validated',
            'quote_signed',
            'quote_obsolete',
            'delivery',
            'installation',
        ],
        countries: ['ES', 'IT'],
    },
    {
        key: 'billings',
        disabledFor: [
            'project_proposed',
            'project_rejected',
            'survey_to_plan',
            'survey_planned',
            'survey_conducted',
            'quote_proposed',
            'waiting_for_new_quote',
            'quote_validated',
            'quote_signed',
            'quote_obsolete',
        ],
    },
    {
        key: 'messaging',
        disabledFor: ['*'],
    },
    {
        key: 'note',
        disabledFor: ['*'],
    },
];

export function ProjectOverviewPage() {
    const { authenticatedCall } = useAppAuth();
    const { projectState, dispatch } = useContext(ProjectsContext)!;
    const { id } = useParams();
    useEffect(() => {
        if (id) {
            authenticatedCall(async (accessToken) => dispatch(projectActions.loadOne(accessToken, id)));
        }
    }, [dispatch, id, authenticatedCall]);
    const project: Project | null = projectState?.project ?? null;

    // const statusKey = useMemo(() => {
    //     let statusKey = getStatusKey(project);
    //     return statusKey;
    // }, [project]);

    const navigate = useNavigate();

    return (
        <PageTemplate greyBackground>
            <Stack
                sx={{ height: '100%', paddingX: '20px', paddingY: '40px', gap: '16px' }}
                data-test-id="project-overview"
            >
                <BackButton path="/projects" page={projectState.page} label="projects.back_to_listing" />
                {project && project?.id === id ? <ProjectOverviewCard project={project} /> : <Loader />}

                {tabs.map(
                    ({ key, disabledFor, countries }) =>
                        // if countries is not defined, show the tab
                        // if countries is defined, show the tab only if the country is in the list
                        !isHiddenTab(key, countries, project) && (
                            <MenuLine
                                label={t(`projects.${key.replaceAll('-', '_')}.button`)}
                                dataTestId={`${key}-link`}
                                soon={['messaging', 'note'].includes(key) ? true : false}
                                onClick={() => navigate(key)}
                                disabled={isDisabledTab(key, disabledFor, project)}
                                sx={{ minHeight: '48px' }}
                                key={`menu-${key}`}
                            />
                        ),
                )}
            </Stack>
        </PageTemplate>
    );
}
