const LeftBlackArrowIcon = () => {
    return (
        <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Groupe 12">
                <path id="Ligne 2" d="M5.5 1L1.5 5" stroke="#17181A" strokeWidth="1.5" strokeLinecap="round" />
                <path id="Ligne 3" d="M5.5 9L1.5 5" stroke="#17181A" strokeWidth="1.5" strokeLinecap="round" />
                <path id="Ligne 4" d="M12.5 5H1.5" stroke="#17181A" strokeWidth="1.5" strokeLinecap="round" />
            </g>
        </svg>
    );
};

export default LeftBlackArrowIcon;
