import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Project } from '../../core/project/domain';
import formatDate from '../../utils/formatDate';
import Tag from '../molecules/Tag';
import getStatusKey from '../../utils/getStatusKey';
import formatHours from 'src/utils/formatHours';
import { getUserLang } from '../i18n';

export default function ProjectStatusTag({ project }: { project: Project }) {
    const { t } = useTranslation();

    const statusKey = useMemo(() => {
        return getStatusKey(project);
    }, [project]);

    const color = useMemo(() => {
        switch (statusKey) {
            case 'project_proposed':
                return 'danger';
            case 'survey_to_plan':
            case 'survey_conducted':
            case 'waiting_for_new_quote':
            case 'shipping_in_progress':
            case 'waiting_for_billing_upload':
                return 'warning';
            case 'quote_proposed':
            case 'quote_validated':
            case 'project_completed':
            case 'project_rejected':
            case 'project_lost':
                return 'grey';
            default:
                return 'primary';
        }
    }, [statusKey]);

    return (
        <Tag
            color={color}
            data-test-id="project-status"
            data-test-value={project.status}
            data-test-project-name-value={project.client.name}
            label={t(`projects.status_overview_tag.${statusKey}`, {
                installation_date: project.installation?.startDate
                    ? formatDate(
                          project.installation?.startDate,
                          false,
                          false,
                          getUserLang(),
                          project.address.countryCode,
                      )
                    : '',
                installation_hour: formatHours(new Date(project.installation?.startDate!), t, false),
                survey_date: project?.survey?.startDate
                    ? formatDate(project.survey?.startDate, false, false, getUserLang(), project.address.countryCode)
                    : '',
                hour: formatHours(new Date(project.survey?.startDate!), t, false),
            })}
            warningIcon={project.isFocus}
        />
    );
}
