import React from 'react';

const WarningIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Frame 319">
                <circle id="Ellipse 312" cx="8.00006" cy="8.00006" r="8.00006" fill="#FE8A8A" />
                <g id="Group 73">
                    <circle id="Ellipse 311" cx="8.00018" cy="11.7619" r="1.2951" fill="white" />
                    <path
                        id="Ligne 4"
                        d="M7.99993 2.94366L8 8.93164"
                        stroke="white"
                        strokeWidth="1.72483"
                        strokeLinecap="round"
                    />
                </g>
            </g>
        </svg>
    );
};

export default WarningIcon;
