import { createContext, PropsWithChildren, useEffect } from 'react';
import { Logger } from 'src/core/logger';

export const LoggerContext = createContext<Logger | null>(null);

export function LoggerProvider(
    props: PropsWithChildren<{
        logger: Logger;
    }>,
) {
    useEffect(() => {
        // Catch all errors
        window.onerror = function (msg: string | Event, file: string | undefined, line: number | undefined): boolean {
            props.logger.error('Error in ' + file + ' on line ' + line + ': ' + msg);
            return false;
        };
    }, [props.logger]);

    return <LoggerContext.Provider value={props.logger}>{props.children}</LoggerContext.Provider>;
}
