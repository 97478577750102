import { PropsWithChildren, useCallback } from 'react';
import { AppState, Auth0Provider, User, useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

export type AppUser = User & {
    ip_app_metadata?: {
        lang?: string;
    };
};

export function AuthenticationProvider(
    props: PropsWithChildren<{
        domain: string;
        clientId: string;
        audience: string;
    }>,
) {
    const navigate = useNavigate();

    const onRedirectCallback = (appState?: AppState, user?: User): void => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider
            domain={props.domain}
            clientId={props.clientId}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: props.audience,
                scope: 'openid profile email offline_access',
            }}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens
            cacheLocation="localstorage"
        >
            {props.children}
        </Auth0Provider>
    );
}

export function useAppAuth() {
    const { loginWithRedirect, getAccessTokenSilently, ...other } = useAuth0<AppUser>();

    const authenticatedCall = useCallback(
        async (call: (accessToken: string) => Promise<void>) => {
            try {
                const accessToken = await getAccessTokenSilently({ cacheMode: 'off' });
                await call(accessToken);
            } catch (err: any) {
                if (err.error === 'login_required') {
                    await loginWithRedirect();
                } else if (err.error === 'consent_required') {
                    await loginWithRedirect();
                } else {
                    throw err;
                }
            }
        },
        [getAccessTokenSilently, loginWithRedirect],
    );

    return {
        authenticatedCall,
        loginWithRedirect,
        getAccessTokenSilently,
        ...other,
    };
}
