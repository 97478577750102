import React from 'react';
import { ProjectsProvider } from './contexts/project-context';
import { ProjectReducer } from '../core/project/service';
import '@fontsource/inter';
import { LoggerProvider } from './contexts/logger-context';
import { I18n } from './i18n';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './router';
import { AuthenticationProvider } from './contexts/auth-context';
import { Logger } from 'src/core/logger';
import { Config } from '..';
import { BackendApi } from 'src/providers/data-source-backend';
import { BackendDataSourceProvider } from './contexts/backend-data-source-context';

export default function App(props: {
    logger: Logger;
    projectService: ProjectReducer;
    config: Config;
    dataSourceBackend: BackendApi;
}) {
    return (
        <LoggerProvider logger={props.logger}>
            <BackendDataSourceProvider dataSourceBackend={props.dataSourceBackend}>
                <ProjectsProvider projectsReducer={props.projectService}>
                    <BrowserRouter>
                        <AuthenticationProvider {...props.config.auth}>
                            <I18n>
                                <AppRoutes />
                            </I18n>
                        </AuthenticationProvider>
                    </BrowserRouter>
                </ProjectsProvider>
            </BackendDataSourceProvider>
        </LoggerProvider>
    );
}
