import React from 'react';
import { Stack } from '@mui/joy';
import { PageTemplate } from '../layout/PageTemplate';
import Loader from '../molecules/Loader';

export function LoaderPage({ greyBackground = true }: { greyBackground?: boolean }) {
    return (
        <PageTemplate greyBackground={greyBackground}>
            <Stack height="100vh" justifyContent="center" alignItems="center">
                <Loader />
            </Stack>
        </PageTemplate>
    );
}
