import { Box, Chip, Checkbox as JoyCheckbox, Stack, Typography, checkboxClasses } from '@mui/joy';
import { useCallback, useState } from 'react';
import CheckedIcon from '../../atoms/icons/CheckIcon';
import { theme } from '../../theme';

interface InputProps {
    label: string;
    disabled?: boolean;
    check?: boolean;
    onChange?: (isChecked: boolean) => void;
}

export default function Checkbox({ label, check, onChange, ...props }: InputProps) {
    const [checked, setChecked] = useState<boolean>(check ? check : false);

    const handleChange = useCallback(() => {
        const newChecked = !checked;
        setChecked(newChecked);
        if (onChange) {
            onChange(newChecked);
        }
    }, [checked, onChange]);

    return (
        <Box display="flex" alignItems="center">
            <Chip
                color={checked ? 'primary' : 'grey'}
                variant={'soft'}
                sx={{ padding: '8px 10px', borderRadius: '5px' }}
            >
                <Stack direction="row" justifyContent="space-evenly" spacing={1}>
                    <JoyCheckbox
                        checked={checked}
                        checkedIcon={<CheckedIcon />}
                        label={<Typography level={checked ? 'title-sm' : 'body-sm'}>{label}</Typography>}
                        onChange={handleChange}
                        sx={{
                            '--Checkbox-size': '16px',
                            position: 'relative',
                            [`& .${checkboxClasses.checkbox}`]: {
                                borderColor: theme.palette.grey[200],
                            },
                        }}
                        {...props}
                    />
                </Stack>
            </Chip>
        </Box>
    );
}
