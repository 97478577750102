import { Card, Link, Stack, Table, Typography } from '@mui/joy';
import { Delivery, DeliveryItem } from 'src/core/project/domain';
import { useTranslation } from 'react-i18next';
import Tag from '../Tag';
import LinkIcon from 'src/ui/atoms/icons/LinkIcon';
import { useContext, useState } from 'react';
import Checkbox from '../forms/Checkbox';
import Button from '../Button';
import { ProjectsContext } from 'src/ui/contexts/project-context';
import { projectActions } from 'src/core/project/service';
import { useAppAuth } from 'src/ui/contexts/auth-context';

interface DeliveryCardProps {
    delivery: Delivery;
}

export default function DeliveryCard({ delivery }: DeliveryCardProps) {
    const { t } = useTranslation();
    const { authenticatedCall } = useAppAuth();
    const { dispatch, projectState } = useContext(ProjectsContext)!;
    const [selectedItems, setSelectedItems] = useState<string[]>(
        delivery.items.filter((i) => i.received === true).map((i) => i.name),
    );

    const confirm = async () => {
        if (delivery.items.length === selectedItems.length) {
            delivery.status = 'received';
            await authenticatedCall(async (accessToken) => {
                dispatch(projectActions.updateDeliveryStatus(accessToken, delivery));
            });
        }
    };

    const handleCheckboxChange = async (item: DeliveryItem, isChecked: boolean) => {
        item.received = isChecked;
        await authenticatedCall(async (accessToken) => {
            try {
                // dispatch(projectActions.updateDelivery(accessToken, delivery));
                if (isChecked) {
                    setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item.name]);
                } else {
                    setSelectedItems((prevSelectedItems) => prevSelectedItems.filter((i) => i !== item.name));
                }
            } catch (error) {
                console.error('Error updating delivery:', error);
            }
        });
    };

    return (
        <Card variant="plain">
            <Stack direction="row" justifyContent="space-between">
                <Stack>
                    <Typography level="h4" marginBottom="8px" data-test-id="delivery-number">
                        {`${t('delivery.package')} ${delivery.referenceNumber}`}&nbsp;
                        {delivery.poNumber && `${t('delivery.po_number')} ${delivery.poNumber}`}
                    </Typography>

                    {delivery.trackingUrl ? (
                        <Link href={delivery.trackingUrl} target="_blank" rel="noreferrer" underline="none">
                            <Tag
                                color={delivery.status === 'shipped' ? 'primary' : 'secondary'}
                                label={t(`delivery.status.${delivery.status}`)}
                                warningIcon={delivery.hasIssues}
                            ></Tag>
                            &nbsp;
                            <LinkIcon />
                        </Link>
                    ) : (
                        <Tag
                            color={delivery.status === 'shipped' ? 'primary' : 'secondary'}
                            label={t(`delivery.status.${delivery.status}`)}
                            warningIcon={delivery.hasIssues}
                        ></Tag>
                    )}
                </Stack>
                <Stack>
                    <Typography level="body-lg">{`${delivery.address.street1}`}</Typography>
                    <Typography level="body-lg">{`${delivery.address.postalCode} ${delivery.address.city}`}</Typography>
                </Stack>
            </Stack>

            <Table size="lg">
                <thead>
                    <tr>
                        <th
                            style={{
                                width:
                                    delivery.address.street1 === projectState.project?.installerAddress.street1 &&
                                    delivery.address.city === projectState.project?.installerAddress.city
                                        ? '50%'
                                        : '70%',
                            }}
                        >
                            {t('delivery.description')}
                        </th>
                        <th style={{ textAlign: 'center' }}>{t('delivery.quantity')}</th>
                        {delivery.address.street1 === projectState.project?.installerAddress.street1 &&
                        delivery.address.city === projectState.project?.installerAddress.city ? (
                            <th> </th>
                        ) : undefined}
                    </tr>
                </thead>

                <tbody>
                    {delivery.items.map((deliveryItem, key) => (
                        <>
                            <tr key={key}>
                                <td>
                                    <div data-test-id="delivery-item-name" data-test-value={deliveryItem.name}>
                                        {deliveryItem.name}
                                    </div>
                                    <div
                                        data-test-id="delivery-item-serial-number"
                                        data-test-value={deliveryItem.serialNumbers.join(' / ')}
                                    >
                                        {`${t('delivery.serialNumber')} : ${deliveryItem.serialNumbers.join(' / ')}`}
                                    </div>
                                </td>

                                <td
                                    style={{ textAlign: 'center' }}
                                    data-test-id="delivery-item-quantity"
                                    data-test-value={deliveryItem.quantity}
                                >
                                    {deliveryItem.quantity}
                                </td>
                                {delivery.address.street1 === projectState.project?.installerAddress.street1 &&
                                delivery.address.city === projectState.project?.installerAddress.city ? (
                                    <td align="right">
                                        <Checkbox
                                            label={t('delivery.received')}
                                            onChange={(isChecked) => handleCheckboxChange(deliveryItem, isChecked)}
                                            check={deliveryItem.received}
                                            disabled={delivery.status === 'received' ? true : false}
                                        />
                                    </td>
                                ) : undefined}
                            </tr>
                        </>
                    ))}
                </tbody>
            </Table>
            {delivery.status === 'shipped' &&
            delivery.address.street1 === projectState.project?.installerAddress.street1 &&
            delivery.address.city === projectState.project?.installerAddress.city ? (
                <Button
                    color={selectedItems.length === delivery.items.length ? 'secondary' : 'grey'}
                    variant={selectedItems.length === delivery.items.length ? 'solid' : 'soft'}
                    sx={{ width: '100%' }}
                    onClick={() => confirm()}
                >
                    <Typography
                        level="title-lg"
                        sx={{ color: selectedItems.length === delivery.items.length ? 'white' : 'grey' }}
                    >
                        {t('delivery.confirm')}
                    </Typography>
                </Button>
            ) : undefined}
        </Card>
    );
}
