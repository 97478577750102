import { Card, Typography } from '@mui/joy';

import { theme } from 'src/ui/theme';

export default function PageTitleCard({
    isPrimary,
    isWarning,
    title,
    subtitle,
}: {
    isPrimary: boolean;
    isWarning: boolean;
    title: string;
    subtitle: string;
}) {
    let titleCard;
    let subtitleCard;
    let color = '';

    if (isWarning) {
        color = theme.palette.warning[100];
    } else if (isPrimary) {
        color = theme.palette.primary[100];
    }

    let borderColor = '';
    if (isWarning) {
        borderColor = theme.palette.warning[500];
    } else if (isPrimary) {
        borderColor = theme.palette.primary[700];
    }

    titleCard = title;
    subtitleCard = subtitle;

    return (
        <Card
            variant={isWarning ? 'outlined' : 'plain'}
            sx={{
                backgroundColor: color,
                borderColor,
            }}
            color="warning"
            data-test-id={`page-title-card-${title}`}
        >
            <Typography level="h4">{titleCard}</Typography>
            <Typography>{subtitleCard}</Typography>
        </Card>
    );
}
