export interface Action<Type extends string, Payload> {
    type: Type;
    payload: Payload;
}

export const createAction = <Type extends string, Payload>(type: Type, payload: Payload): Action<Type, Payload> => ({
    type,
    payload,
});

export type ActionExtractor<ActionDict extends { [index: string]: any }> = ReturnType<ActionDict[keyof ActionDict]>;
