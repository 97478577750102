import React from 'react';

const SearchIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group 2">
                <g id="Ellipse 8">
                    <path
                        id="Vector"
                        d="M3.63239 7.95652C3.63239 11.374 6.44658 14.163 9.94119 14.163C13.4358 14.163 16.25 11.374 16.25 7.95652C16.25 4.53904 13.4358 1.75 9.94119 1.75C6.44658 1.75 3.63239 4.53904 3.63239 7.95652Z"
                        stroke="#1B2A50"
                        strokeWidth="1.5"
                    />
                    <path
                        id="Vector_2"
                        d="M9.94102 14.2174C6.4324 14.2174 3.58809 11.4143 3.58809 7.95655C3.58809 4.49876 6.4324 1.69568 9.94102 1.69568C13.4496 1.69568 16.2939 4.49876 16.2939 7.95655C16.2939 11.4143 13.4496 14.2174 9.94102 14.2174Z"
                        stroke="#1B2A50"
                        strokeWidth="1.5"
                    />
                </g>
                <path id="Ligne 58" d="M1.00002 17L5 13.058" stroke="#1B2A50" strokeWidth="1.5" strokeLinecap="round" />
            </g>
        </svg>
    );
};

export default SearchIcon;
