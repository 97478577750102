import { Project } from 'src/core/project/domain';

const getStatusKey = (project: Project | null) => {
    // console.log('project.status', project.status);
    if (!project) {
        return '';
    }

    let result: string = project.status;
    if (project.status === 'delivery') {
        let deliveryCompleted = true;
        // if deliveries are expected
        if (project.deliveries && project.deliveries.length > 0) {
            // check if all deliveries received
            for (let i = 0; i < project.deliveries.length; i++) {
                if (project.deliveries[i].status !== 'received') {
                    deliveryCompleted = false;
                }
            }
        }
        console.log('isDeliveryCompleted', deliveryCompleted);

        if (!deliveryCompleted) {
            // not all deliveries are received
            result = 'shipping_in_progress';
        } else {
            result = 'delivery';
        }
    } else if (project.status === 'installation') {
        // check if survey is completed
        let isSurveyCompleted = false;
        let isPreInstallationFormCompleted = false;

        const surveyObject = project?.survey;
        console.log('survey object', surveyObject);
        const surveyDate: Date | null = surveyObject?.startDate ? new Date(surveyObject?.startDate) : null;
        console.log('survey date', surveyDate);
        const surveyStatus: string | null = surveyObject?.status ? surveyObject?.status : null;
        console.log('survey status', surveyStatus);

        if (surveyStatus === 'completed' || (surveyDate && surveyDate <= new Date())) {
            isSurveyCompleted = true;
        }

        if (project.documents && project.documents.length > 0) {
            for (let i = 0; i < project.documents.length; i++) {
                if (project.documents[i].type === 'survey') {
                    isSurveyCompleted = true;
                }
                if (project.documents[i].type === 'pre-installation') {
                    isPreInstallationFormCompleted = true;
                }
            }
        }

        console.log('isSurveyCompleted', isSurveyCompleted);
        console.log('isPreInstallationFormCompleted', isPreInstallationFormCompleted);

        // checking if installation is planned
        console.log('project.installation', project.installation);
        if (project.installation && project.installation.startDate) {
            console.log('project.installation.startDate', project.installation.startDate);
            // check installation start date
            if (new Date(project.installation.startDate) <= new Date()) {
                console.log('(date) project.installation.startDate', new Date(project.installation.startDate));
                // all received + installation planned and start date in the past
                if (!isSurveyCompleted && !isPreInstallationFormCompleted) {
                    // no survey and no pre-installation form
                    result = 'waiting_for_pre_installation_form';
                } else {
                    result = 'ready_to_install'; // all good to go
                }
            } else {
                // installation planned but start date in the future
                result = 'installation_planned';
            }
        } else {
            // no installation planned
            result = 'installation_to_plan';
        }
    }

    console.log('getStatusKey result', result);
    return result;
};
export default getStatusKey;
