import { Card, Grid, Stack, Typography } from '@mui/joy';
import Button from '../Button';
import { useTranslation } from 'react-i18next';
import { Billing, Document, PurchaseOrder } from 'src/core/project/domain';
import { theme } from '../../theme';
import formatDate from 'src/utils/formatDate';
import { getUserLang } from '../../i18n';
import { ProjectsContext } from '../../contexts/project-context';
import { useContext } from 'react';
import { BackendDataSourceContext } from '../../contexts/backend-data-source-context';
import { useAppAuth } from 'src/ui/contexts/auth-context';
import DownloadIcon from 'src/ui/atoms/icons/Download';
import previewFile from 'src/utils/previewFile';
import { useNavigate } from 'react-router-dom';
import RightSecondaryArrowIcon from 'src/ui/atoms/icons/RightSecondaryArrowIcon';
import AliceCarousel from 'react-alice-carousel';
import BillingCard from '../billing/BillingCard';

export function PurchaseOrderResume({
    item,
    purchaseOrder,
    hasGlobal,
    billings,
}: {
    item: Document;
    purchaseOrder: PurchaseOrder;
    hasGlobal: boolean;
    billings?: Array<Billing>;
}) {
    const { t } = useTranslation();
    const { authenticatedCall } = useAppAuth();
    const { projectState } = useContext(ProjectsContext)!;
    const dataSourceBackend = useContext(BackendDataSourceContext)!;
    const navigate = useNavigate();

    const onClick = () => {
        const project = projectState.project;
        if (item && item.id) {
            authenticatedCall(async (accessToken) => {
                previewFile(project, item.fileKey, item.title, dataSourceBackend, accessToken);
            });
        }
    };

    const isPaid: boolean = (billings?.reduce((acc, cur) => (acc += +cur.amount), 0) ?? 0) >= purchaseOrder.amount;
    return (
        <Card variant={'plain'} color="grey" sx={{ margin: 1, backgroundColor: theme.palette.grey[100] }}>
            <Stack spacing={1}>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    <Grid xs="auto">
                        <Typography level="title-lg">Bon de commande :</Typography>
                        <Typography level="title-lg">{purchaseOrder.name}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    <Grid xs={6}>
                        <Typography level="body-md">{t('billing.date')}</Typography>
                        <Typography level="title-lg">
                            {formatDate(
                                purchaseOrder.date,
                                false,
                                false,
                                getUserLang(),
                                projectState.project?.address.countryCode,
                            )}
                        </Typography>
                    </Grid>
                    <Grid xs="auto">
                        <Typography level="body-md">{t('quote.amount_short')}</Typography>
                        <Typography level="title-lg">{purchaseOrder.amount} €</Typography>
                    </Grid>
                </Grid>
                <Button
                    label={t('billing.purchase_order.title')}
                    color="grey"
                    variant="outlined"
                    startDecorator={<DownloadIcon />}
                    onClick={onClick}
                />
                {!isPaid && (
                    <Button
                        label={
                            billings && billings.length > 0
                                ? t(`billing.new_partial_billing`)
                                : t(`billing.new_billing`)
                        }
                        color="primary"
                        variant="solid"
                        endDecorator={<RightSecondaryArrowIcon />}
                        disabled={hasGlobal}
                        onClick={() => {
                            const currentUrl = window.location.pathname;
                            const urlSegments = currentUrl.split('/');
                            urlSegments.pop();

                            urlSegments.push('purchase-orders/' + item.parentEntityId + '/new-billings');
                            const newUrl = urlSegments.join('/');
                            navigate(newUrl);
                        }}
                    />
                )}
                {billings && billings.length > 0 && (
                    <AliceCarousel
                        disableButtonsControls={true}
                        responsive={{ 0: { items: 1 }, 568: { items: 2 }, 1024: { items: 3 } }}
                        controlsStrategy="responsive"
                        items={billings.map((billing) => (
                            <BillingCard item={billing} small showDetailsButton />
                        ))}
                    />
                )}
            </Stack>
        </Card>
    );
}
