const UserIconBox = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.55615 13.0342C3.55615 11.2667 4.98895 9.83395 6.75641 9.83395H11.0234C12.7909 9.83395 14.2237 11.2667 14.2237 13.0342V13.0342C14.2237 14.2125 13.2685 15.1677 12.0902 15.1677H5.68966C4.51135 15.1677 3.55615 14.2125 3.55615 13.0342V13.0342Z"
                stroke="#8C94A7"
                strokeWidth="1.00026"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.5568 4.50019C11.5568 5.97307 10.3628 7.16707 8.88991 7.16707C7.41703 7.16707 6.22303 5.97307 6.22303 4.50019C6.22303 3.02731 7.41703 1.83331 8.88991 1.83331C10.3628 1.83331 11.5568 3.02731 11.5568 4.50019Z"
                stroke="#8C94A7"
                strokeWidth="1.00026"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default UserIconBox;
