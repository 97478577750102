import { Card, Grid, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Billing, Project, PurchaseOrder } from 'src/core/project/domain';
import { PurchaseOrderResume } from './PurchaseOrderResume';
import RightSecondaryArrowIcon from 'src/ui/atoms/icons/RightSecondaryArrowIcon';
import Button from '../Button';
import { useNavigate } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import BillingCard from '../billing/BillingCard';

export function PurchaseOrderCards({
    project,
    totalPurchaseOrdersAmount,
    hasBilled,
    hasGlobal,
}: {
    project: Project | null;
    totalPurchaseOrdersAmount: number | null;
    hasBilled: boolean;
    hasGlobal: boolean;
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const globalBillings: Array<Billing> = project?.billings.filter((b) => !b.purchaseOrder) ?? [];
    const poNumbers: number = project?.purchaseOrders?.length ?? 0;

    const poDocuments = project?.documents.filter((doc) => doc.type === 'purchase-order');
    if (poDocuments && poDocuments.length <= 0) {
        return null;
    }

    const POsWithLatestDoc = project?.purchaseOrders?.filter((po: PurchaseOrder) => {
        const latestDoc = poDocuments
            ?.filter((d) => d.parentEntityId === po.id)
            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0];

        return latestDoc !== undefined;
    });

    let totalPOsWithLatestDocAmount = 0;
    if (POsWithLatestDoc) {
        for (const po of POsWithLatestDoc) {
            if (po && po.amount) {
                totalPOsWithLatestDocAmount += Number(po.amount);
            }
        }
    }

    return (
        <>
            <Card variant="plain">
                <Typography level="h4">{t('billing.purchase_order.title')}</Typography>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    <Grid xs={6}>
                        <Typography level="body-md">{t('billing.purchase_order.title')}</Typography>
                        <Typography level="title-lg">{POsWithLatestDoc?.length}</Typography>
                    </Grid>
                    <Grid xs="auto">
                        <Typography level="body-md">{t('quote.amount_short')}</Typography>
                        <Typography level="title-lg">{totalPOsWithLatestDocAmount} €</Typography>
                    </Grid>
                </Grid>
                {!hasGlobal && !hasBilled && poNumbers > 1 && (
                    <Button
                        label={t(`billing.new_global_billing`)}
                        color="primary"
                        variant="solid" //
                        endDecorator={<RightSecondaryArrowIcon />}
                        onClick={() => {
                            const currentUrl = window.location.pathname;
                            const urlSegments = currentUrl.split('/');
                            urlSegments.pop();

                            urlSegments.push('new-billings');
                            const newUrl = urlSegments.join('/');
                            navigate(newUrl);
                        }}
                    />
                )}
                {globalBillings && globalBillings.length !== 0 && (
                    <AliceCarousel
                        disableButtonsControls={true}
                        responsive={{ 0: { items: 1 }, 568: { items: 2 }, 1024: { items: 3 } }}
                        controlsStrategy="responsive"
                        items={globalBillings.map((billing, index) => (
                            <BillingCard item={billing} small showDetailsButton />
                        ))}
                    />
                )}

                {project?.purchaseOrders?.map((po: PurchaseOrder) =>
                    (() => {
                        const latestDoc = poDocuments
                            ?.filter((d) => d.parentEntityId === po.id)
                            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0];

                        let poBillings: Array<Billing> | undefined = project.billings?.filter(
                            (b) => b?.purchaseOrder?.id === po.id,
                        );

                        // return latestDoc ? <DocumentCard key={latestDoc.id} item={latestDoc}></DocumentCard> : null;
                        return latestDoc ? (
                            <PurchaseOrderResume
                                item={latestDoc}
                                purchaseOrder={po}
                                key={po.id}
                                hasGlobal={hasGlobal}
                                billings={poBillings}
                            ></PurchaseOrderResume>
                        ) : null;
                    })(),
                )}
            </Card>
        </>
    );
}
