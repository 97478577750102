import { Stack } from '@mui/joy';
import React, { useContext, useEffect } from 'react';
import { ProjectsContext } from '../contexts/project-context';
import { projectActions } from 'src/core/project/service';
import { PageTemplate } from '../layout/PageTemplate';
import { useParams } from 'react-router-dom';
import BackButton from '../molecules/BackButton';
import Loader from '../molecules/Loader';
import { useAppAuth } from '../contexts/auth-context';
import InstallationActionsCard from '../molecules/projects/InstallationActionsCard';

export function InstallationDataPage() {
    const { id } = useParams();
    const { projectState, dispatch } = useContext(ProjectsContext)!;
    const { authenticatedCall } = useAppAuth();

    useEffect(() => {
        if (id) {
            authenticatedCall(async (accessToken) => dispatch(projectActions.loadOne(accessToken, id)));
        }
    }, [dispatch, id, authenticatedCall]);

    return (
        <PageTemplate greyBackground>
            <Stack
                sx={{ height: '100%', paddingX: '20px', paddingY: '40px', gap: '16px' }}
                data-test-id="installation-page"
            >
                <BackButton path={`/projects/${projectState?.project?.id}`} label="projects.generic_data.back" />
                {projectState.project && projectState.project.id === id ? (
                    <>
                        <InstallationActionsCard project={projectState.project} />
                        {/* To uncomment when we retrieve SoS fields */}
                        {/* {(['waiting_for_billing_upload', 'waiting_for_billing_validation', 'project_completed'].find(
                            (e) => e === projectState.project?.status,
                        ) ||
                            projectState.project.installation?.status === 'completed') &&
                            projectState.project.installation && (
                                <>
                                    <PicturesCard pictures={pictures} />
                                    <ElectricalInstallationCard
                                        electricalInstallation={
                                            projectState.project.installation.electricalInstallation
                                        }
                                    />
                                    <ChargingStationsCard
                                        installedChargingStations={
                                            projectState.project.installation.installedChargingStations
                                        }
                                    />
                                    <ProtectionAndPowerSupplyCard
                                        protectionAndPowerSupply={
                                            projectState.project.installation.protectionAndPowerSupply
                                        }
                                    />
                                    <InstallationHandoverToClientCard
                                        installationHandoverToClient={
                                            projectState.project.installation.installationHandoverToClient
                                        }
                                    />
                                    {projectState.project.installation.clientSignature.signature && (
                                        <ClientSignatureCard
                                            clientSignature={projectState.project.installation.clientSignature}
                                        />
                                    )}
                                </>
                            )} */}
                    </>
                ) : (
                    <Loader />
                )}
            </Stack>
        </PageTemplate>
    );
}
