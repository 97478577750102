import React, { ReactNode } from 'react';
import { Stack, Card, Typography, Box } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { theme } from '../theme';
import CheckIcon from '../atoms/icons/CheckIcon';
import Button from './Button';
import RightArrow from '../atoms/icons/RightSecondaryArrowIcon';

interface PrimaryButtonProps {
    actionDataTestId: string;
    primaryActionLabel: string;
    onPrimaryClick: Function;
    disabled?: boolean;
    secondaryActionLabel?: string;
    onSecondaryClick?: Function;
    startDecorator?: ReactNode;
    optional?: boolean;
}

function PrimaryButton({
    actionDataTestId,
    onPrimaryClick,
    disabled,
    secondaryActionLabel,
    onSecondaryClick,
    startDecorator,
    primaryActionLabel,
    optional,
}: PrimaryButtonProps) {
    const { t } = useTranslation();
    return (
        <Button
            data-test-id={actionDataTestId}
            onClick={() => onPrimaryClick()}
            disabled={disabled}
            startDecorator={
                ((secondaryActionLabel && onSecondaryClick) || startDecorator) && (
                    <Box sx={{ opacity: disabled ? 0.5 : 1 }}>{startDecorator ?? <CheckIcon />}</Box>
                )
            }
            endDecorator={
                !(secondaryActionLabel && onSecondaryClick) &&
                !startDecorator && (
                    <Box sx={{ opacity: disabled ? 0.5 : 1 }}>
                        <RightArrow />
                    </Box>
                )
            }
            label={t(primaryActionLabel)}
            variant={optional ? 'outlined' : 'solid'}
        />
    );
}

interface LifeCycleActionCardInterface {
    title: string;
    secondTitle?: string;
    subtitle?: string | ReactNode;
    primaryActionLabel?: string;
    otherPrimaryActionLabel?: string;
    secondaryActionLabel?: string;
    onPrimaryClick?: () => void;
    onSecondaryClick?: () => void;
    onOtherPrimaryClick?: () => void;
    actionDataTestId: string;
    otherActionDataTestId: string;
    declineDataTestId: string;
    disabled?: boolean;
    startDecorator?: ReactNode;
    optional?: boolean;
}

export default function LifeCycleActionCard({
    title,
    secondTitle,
    subtitle,
    primaryActionLabel,
    secondaryActionLabel,
    onPrimaryClick,
    onSecondaryClick,
    disabled,
    actionDataTestId,
    otherActionDataTestId,
    declineDataTestId,
    otherPrimaryActionLabel,
    onOtherPrimaryClick,
    startDecorator,
    optional,
}: LifeCycleActionCardInterface) {
    const { t } = useTranslation();

    if (optional) {
        return (
            <>
                <Card
                    orientation="vertical"
                    sx={{
                        border: 'none',
                        padding: '20px',
                        background: disabled
                            ? theme.palette.grey[200]
                            : `radial-gradient(222.6% 119.7% at 15.59% 15.13%, ${theme.palette.primary[300]} 0%, ${theme.palette.warning[50]} 100%)`,
                    }}
                >
                    <Typography level="title-lg" data-test-id="life-cycle-action-card-title">
                        {t(title)}
                    </Typography>
                    <Stack justifyContent="space-between" direction="row">
                        {onPrimaryClick && (
                            <PrimaryButton
                                actionDataTestId={actionDataTestId}
                                startDecorator={startDecorator}
                                primaryActionLabel={primaryActionLabel!}
                                onPrimaryClick={onPrimaryClick}
                                disabled={disabled}
                                secondaryActionLabel={secondaryActionLabel}
                                onSecondaryClick={onSecondaryClick}
                            />
                        )}

                        {onSecondaryClick && secondaryActionLabel && (
                            <Button
                                data-test-id={declineDataTestId}
                                disabled={disabled}
                                variant="underlined"
                                color="neutral"
                                onClick={onSecondaryClick}
                                label={t(secondaryActionLabel)}
                            />
                        )}
                    </Stack>
                    {subtitle &&
                        ('string' === typeof subtitle ? (
                            <Typography level="body-lg">{t(subtitle)}</Typography>
                        ) : (
                            <>{subtitle}</>
                        ))}
                </Card>
                <Card
                    orientation="vertical"
                    sx={{
                        border: 'none',
                        padding: '20px',
                        background: theme.palette.grey[100],
                    }}
                >
                    {secondTitle &&
                        ('string' === typeof secondTitle ? (
                            <Typography level="body-lg">{t(secondTitle)}</Typography>
                        ) : (
                            <>{secondTitle}</>
                        ))}
                    {otherPrimaryActionLabel && onOtherPrimaryClick && (
                        <Stack justifyContent="space-between" direction="row">
                            <PrimaryButton
                                primaryActionLabel={otherPrimaryActionLabel}
                                actionDataTestId={otherActionDataTestId}
                                startDecorator={startDecorator}
                                onPrimaryClick={onOtherPrimaryClick}
                                disabled={disabled}
                                secondaryActionLabel={secondaryActionLabel}
                                onSecondaryClick={onSecondaryClick}
                                optional={optional}
                            />
                        </Stack>
                    )}
                </Card>
            </>
        );
    }

    return (
        <Card
            orientation="vertical"
            sx={{
                border: 'none',
                padding: '20px',
                background: disabled
                    ? theme.palette.grey[200]
                    : `radial-gradient(222.6% 119.7% at 15.59% 15.13%, ${theme.palette.primary[300]} 0%, ${theme.palette.warning[50]} 100%)`,
            }}
        >
            <Typography level="title-lg" data-test-id="life-cycle-action-card-title">
                {t(title)}
            </Typography>
            {subtitle &&
                ('string' === typeof subtitle ? (
                    <Typography level="body-lg">{t(subtitle)}</Typography>
                ) : (
                    <>{subtitle}</>
                ))}
            <Stack justifyContent="space-between" direction="row">
                {onPrimaryClick && (
                    <PrimaryButton
                        actionDataTestId={actionDataTestId}
                        startDecorator={startDecorator}
                        primaryActionLabel={primaryActionLabel!}
                        onPrimaryClick={onPrimaryClick}
                        disabled={disabled}
                        secondaryActionLabel={secondaryActionLabel}
                        onSecondaryClick={onSecondaryClick}
                    />
                )}
                {onSecondaryClick && secondaryActionLabel && (
                    <Button
                        data-test-id={declineDataTestId}
                        disabled={disabled}
                        variant="underlined"
                        color="neutral"
                        onClick={onSecondaryClick}
                        label={t(secondaryActionLabel)}
                    />
                )}
            </Stack>
            {otherPrimaryActionLabel && onOtherPrimaryClick && (
                <Stack justifyContent="space-between" direction="row">
                    <PrimaryButton
                        primaryActionLabel={otherPrimaryActionLabel}
                        actionDataTestId={otherActionDataTestId}
                        startDecorator={startDecorator}
                        onPrimaryClick={onOtherPrimaryClick}
                        disabled={disabled}
                        secondaryActionLabel={secondaryActionLabel}
                        onSecondaryClick={onSecondaryClick}
                    />
                </Stack>
            )}
        </Card>
    );
}
