import { Box, Typography } from '@mui/joy';
import { theme } from 'src/ui/theme';

export default function ModalHeader({ label }: { label: string }) {
    return (
        <Box
            height="50px"
            padding={2}
            sx={{
                backgroundColor: theme.palette.neutral[50],
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
            }}
        >
            <Typography level="title-lg">{label}</Typography>
        </Box>
    );
}
