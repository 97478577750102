import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import App from './ui/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './ui/reportWebVitals';
import { CssBaseline, CssVarsProvider, StyledEngineProvider } from '@mui/joy';
import { theme } from './ui/theme';
import { buildAxiosInstance } from './providers/axios_connector';
import { BackendLogger } from './providers/backend-logger';
import { BackendApi } from './providers/data-source-backend';
import { ProjectReducer, projectReducer } from './core/project/service';
import { AxiosInstance } from 'axios';

// Config
export type Config = {
    auth: {
        domain: string;
        clientId: string;
        audience: string;
    };
};

const loadConfig = async (axios_connector: AxiosInstance): Promise<Config> => {
    const defaultConfig: Config = {
        auth: {
            domain: `${process.env.AUTH0_DOMAIN}`, // default should be prod config
            clientId: `${process.env.AUTH0_CLIENT_ID}`,
            audience: `${process.env.AUTH0_AUDIENCE}`,
        },
    };

    const backendConfigResponse = await axios_connector.get('config');
    defaultConfig.auth = backendConfigResponse.data;

    console.log('defaultConfig', defaultConfig);

    return defaultConfig;
};

(async function () {
    const axios_connector = buildAxiosInstance('/api/v1');

    // Config
    const config = await loadConfig(axios_connector);

    // Providers
    const logger = new BackendLogger(axios_connector);
    const project_data_source = new BackendApi(axios_connector, logger);

    // Service
    const projectService: ProjectReducer = projectReducer(project_data_source);

    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
    root.render(
        <React.StrictMode>
            <StyledEngineProvider injectFirst>
                <CssVarsProvider theme={theme}>
                    <CssBaseline />
                    <App
                        logger={logger}
                        dataSourceBackend={project_data_source}
                        config={config}
                        projectService={projectService}
                    />
                </CssVarsProvider>
            </StyledEngineProvider>
        </React.StrictMode>,
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register();

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals(console.log);
})();
