const isDate = (date: any): date is Date => {
    if (!date) return false;

    // if ('toLocaleDateString' in date) {
    return date instanceof Date;
    //     return true;
    // }

    // return false;
};
export default isDate;
