const LeftGreyArrowIcon = () => {
    return (
        <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group 6">
                <path id="Ligne 2" d="M5 1.5L1 5.5" stroke="#8C94A7" strokeWidth="2" strokeLinecap="round" />
                <path id="Ligne 3" d="M5 9.5L1 5.5" stroke="#8C94A7" strokeWidth="2" strokeLinecap="round" />
                <path id="Ligne 4" d="M12 5.5H1" stroke="#8C94A7" strokeWidth="2" strokeLinecap="round" />
            </g>
        </svg>
    );
};

export default LeftGreyArrowIcon;
