const ProjectIcon = () => {
    return (
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Frame 320">
                <circle id="Ellipse 319" cx="9.3078" cy="5.85077" r="5.74725" fill="#1B2A50" />
                <path
                    id="Subtract"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.6286 19.8967C17.2003 19.8967 17.6899 19.4685 17.6899 18.8967C17.6899 14.2674 13.9372 10.5146 9.30786 10.5146C4.67857 10.5146 0.925781 14.2674 0.925781 18.8967C0.925781 19.4685 1.4154 19.8967 1.98714 19.8967H16.6286Z"
                    fill="#1B2A50"
                />
            </g>
        </svg>
    );
};

export default ProjectIcon;
