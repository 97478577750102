import { Card, Typography } from '@mui/joy';
import { ProjectConfiguration } from 'src/core/project/domain';
import { useTranslation } from 'react-i18next';
import InfoLine from 'src/ui/atoms/InfoLine';

export default function ConfigurationCard({ configuration }: { configuration: ProjectConfiguration }) {
    const { t } = useTranslation();

    const card = (
        <Card variant="plain">
            <Typography level="h4">{t('projects.technical_data.configuration')}</Typography>
            <Typography level="body-sm">{t('projects.technical_data.configuration_subtitle')}</Typography>
            <InfoLine
                label={t('projects.technical_data.electrical_installation_type')}
                value={configuration.electricalInstallationType}
                dataTestId="project-electrical-installation-type"
            />
            <InfoLine
                label={t('projects.technical_data.protection_brand')}
                value={configuration.protectionBrand}
                dataTestId="project-protection-brand"
            />
            <InfoLine
                label={t('projects.technical_data.protections_type')}
                value={configuration.protectionsType}
                dataTestId="project-protection-type"
            />
            <InfoLine
                label={t('projects.technical_data.subscribed_power')}
                value={configuration.subscribedPower ? `${configuration.subscribedPower} Kva` : undefined}
                dataTestId="project-subscribed-power"
            />
            <InfoLine
                label={t('projects.technical_data.consumption_peak')}
                value={configuration.consumptionPeak ? `${configuration.consumptionPeak} Kva` : undefined}
                dataTestId="project-consumption-peak"
            />
            <InfoLine
                label={t('projects.technical_data.ground_value')}
                value={configuration.groundValue ? `${configuration.groundValue} Kva` : undefined}
                dataTestId="project-ground-value"
            />
            <InfoLine
                label={t('projects.technical_data.neutral_system')}
                value={configuration.neutralValue ? `${configuration.neutralValue} Kva` : undefined}
                dataTestId="project-neutral-system"
            />
            <InfoLine
                label={t('projects.technical_data.existing_sheath')}
                value={configuration.existingSheath}
                dataTestId="project-existing-sheath"
            />
            <InfoLine
                label={t('projects.technical_data.unblocked_sheath')}
                value={configuration.unblockedSheath}
                dataTestId="project-unblocked-sheath"
            />
        </Card>
    );

    if (
        !(
            configuration.electricalInstallationType &&
            configuration.protectionBrand &&
            configuration.protectionsType &&
            configuration.subscribedPower &&
            configuration.consumptionPeak &&
            configuration.groundValue &&
            configuration.neutralValue &&
            configuration.existingSheath &&
            configuration.unblockedSheath
        )
    ) {
        return;
    }

    return card;
}
