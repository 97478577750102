import { Box, Button, Card, Grid, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Billing, BillingStatus } from 'src/core/project/domain';
import { useContext } from 'react';
import { ProjectsContext } from 'src/ui/contexts/project-context';
import formatDate from 'src/utils/formatDate';
import { getUserLang } from 'src/ui/i18n';
import BillingStatusTag from './BillingStatusTag';
import { useNavigate } from 'react-router-dom';
import EyeIcon from 'src/ui/atoms/icons/EyeIcon';

export default function BillingCardCard({
    item,
    small,
    showDetailsButton,
}: {
    item: Billing | null;
    small?: boolean;
    showDetailsButton?: boolean;
}) {
    const { t } = useTranslation();
    const { projectState } = useContext(ProjectsContext)!;
    const navigate = useNavigate();

    const billingId = item?.id;
    const projectId = projectState?.project?.id ?? '';

    const onClick = () => {
        navigate(`/projects/${projectId}/billings/${billingId}`);
    };

    return (
        <Card variant={small ? 'outlined' : 'plain'}>
            <Stack flexDirection="row" justifyContent="space-between">
                <Typography level="h4" data-test-id={`billing-reference-0`} data-test-value={item?.reference}>
                    {t(`billing.title`, { reference: item?.reference })}
                </Typography>
                <BillingStatusTag status={item?.status as BillingStatus} />
            </Stack>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                {item?.amount && (
                    <Grid xs={6}>
                        <Typography color="grey" level="title-sm">
                            {t(`billing.amount_short`)}
                        </Typography>
                        <Typography level="title-sm" data-test-id={`billing-amount-0`}>
                            {item?.amount} €
                        </Typography>
                    </Grid>
                )}
                {item?.date && (
                    <Grid xs="auto">
                        <Typography color="grey" level="title-sm">
                            {t(`billing.date`)}
                        </Typography>
                        <Typography level="title-sm" data-test-id={`billing-date-0`}>
                            {formatDate(
                                item.date,
                                false,
                                false,
                                getUserLang(),
                                projectState.project?.address.countryCode,
                            )}
                        </Typography>
                    </Grid>
                )}
            </Grid>

            {showDetailsButton && (
                <Button
                    color="grey"
                    variant="soft"
                    startDecorator={
                        <Box>
                            <EyeIcon />
                        </Box>
                    }
                    onClick={onClick}
                />
            )}
        </Card>
    );
}
