import { LinearProgress, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { theme } from '../theme';

interface ProgressBarProps {
    percentage: number;
    label?: string;
}

export default function ProgressBar({ percentage, label }: ProgressBarProps) {
    const { t } = useTranslation();
    return (
        <Stack>
            {label && <Typography level="title-lg">{t(label)}</Typography>}
            <LinearProgress
                color="primary"
                sx={{
                    backgroundColor: percentage > 0 ? theme.palette.primary[300] : theme.palette.grey[100],
                    color: theme.palette.primary[500],
                    marginTop: '7px',
                }}
                value={percentage}
                variant="soft"
                determinate
            />
        </Stack>
    );
}
