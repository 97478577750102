import { ReactNode } from 'react';
import { Stack, SvgIcon, Typography, buttonClasses, typographyClasses } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { ChevronRightIcon } from '../atoms/icons/ChevronRightIcon';
import Tag from './Tag';
import Button from './Button';
import { SxProps } from '@mui/joy/styles/types';

interface MenuLineProps {
    label: string;
    soon?: boolean;
    icon?: ReactNode;
    onClick: Function;
    disabled?: boolean;
    sx?: SxProps;
    dataTestId?: string;
}

export default function MenuLine({ icon, soon = false, label, disabled, onClick, sx, dataTestId }: MenuLineProps) {
    const { t } = useTranslation();
    return (
        <Button
            color="secondary"
            disabled={disabled}
            onClick={() => onClick()}
            data-test-id={dataTestId}
            sx={{
                backgroundColor: 'neutral.50',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'space-between',
                justifyContent: 'space-between',
                height: '48px',
                padding: '12px 20px',
                width: '100%',
                '&:active': {
                    backgroundColor: 'secondary.500',
                    [`.${typographyClasses['title-lg']}`]: {
                        color: 'neutral.50',
                    },
                },
                [`& .${buttonClasses.endDecorator}, & .${buttonClasses.startDecorator}`]: {
                    opacity: disabled ? 0.5 : 1,
                },
                ...sx,
            }}
            startDecorator={
                <Stack direction="row" alignItems="center">
                    <SvgIcon sx={{ marginRight: '8px' }}>{icon}</SvgIcon>
                    <Typography level="title-lg">{t(label)}</Typography>
                </Stack>
            }
            endDecorator={soon ? <Tag label={t('soon')} disabled /> : <ChevronRightIcon size={16} />}
        />
    );
}
