const FlagFrenchIcon = () => {
    return (
        <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3995_2881)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 0.0332031H29.245V21.9662H0V0.0332031Z"
                    fill="#FAF9F6"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 0.0332031H9.747V21.9662H0V0.0332031Z"
                    fill="#002654"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.498 0.0332031H29.245V21.9662H19.498V0.0332031Z"
                    fill="#CE1126"
                />
            </g>
            <defs>
                <clipPath id="clip0_3995_2881">
                    <rect y="0.0332031" width="29.245" height="21.933" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default FlagFrenchIcon;
