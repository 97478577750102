import { Card, Typography } from '@mui/joy';
import { ProjectEstimate } from 'src/core/project/domain';
import { useTranslation } from 'react-i18next';
import InfoLine from 'src/ui/atoms/InfoLine';

export default function QuotingCard({ estimate }: { estimate: ProjectEstimate }) {
    const { t } = useTranslation();

    const card = (
        <Card variant="plain">
            <Typography level="h4">{t('projects.technical_data.quoting')}</Typography>
            <Typography level="body-sm">{t('projects.technical_data.quoting_subtitle')}</Typography>

            <InfoLine
                label={t('projects.technical_data.charger_number')}
                value={estimate.chargerNumber}
                dataTestId="project-charger-number"
            />
            <InfoLine
                label={t('projects.technical_data.charge_power')}
                value={estimate.chargePower ? `${estimate.chargePower} kW` : undefined}
                dataTestId="project-charge-power"
            />
            <InfoLine
                label={t('projects.technical_data.total_power_of_installed_chargers')}
                value={
                    estimate.totalPowerOfInstalledChargers ? `${estimate.totalPowerOfInstalledChargers} kW` : undefined
                }
                dataTestId="project-total-power-of-installed-chargers"
            />
            <InfoLine
                label={t('projects.technical_data.installation_type')}
                value={estimate.installationType}
                dataTestId="project-installation-type"
            />
            <InfoLine
                label={t('projects.technical_data.main_user')}
                value={estimate.mainUser}
                dataTestId="project-main-user"
            />
            <InfoLine
                label={t('projects.technical_data.chargers_power_source')}
                value={estimate.chargersPowerSource}
                dataTestId="project-chargers-power-source"
            />
            <InfoLine
                label={t('projects.technical_data.additionnal_switch_board')}
                value={estimate.additionnalSwitchBoard}
                dataTestId="project-additionnal-switch-board"
            />
            <InfoLine
                label={t('projects.technical_data.adding_surge_protector')}
                value={estimate.addingSurgeProtector}
                dataTestId="project-adding-surge-protector"
            />
        </Card>
    );

    if (
        !(
            estimate.chargerNumber &&
            estimate.chargePower &&
            estimate.totalPowerOfInstalledChargers &&
            estimate.installationType &&
            estimate.mainUser &&
            estimate.chargersPowerSource &&
            estimate.additionnalSwitchBoard &&
            estimate.addingSurgeProtector
        )
    ) {
        return;
    }

    return card;
}
