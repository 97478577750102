import { Box, Chip, SvgIcon, Typography } from '@mui/joy';
import { Color } from 'src/ui/types/joy-ui';
import WarningIcon from '../atoms/icons/WarningIcon';
import { theme } from '../theme';

interface TagProps {
    color?: Color;
    disabled?: boolean;
    label: string;
    warningIcon?: boolean;
    'data-test-project-name-value'?: string;
}

export default function Tag({ disabled, label, warningIcon, color, ...props }: TagProps) {
    const sx = color === 'primary' ? { color: theme.palette.primary[700] } : {};
    return (
        <Box display="flex" alignItems="center">
            <Chip
                {...props}
                color={color}
                variant={disabled ? 'solid' : 'soft'}
                sx={{ padding: '8px 12px' }}
                disabled={disabled}
            >
                <Typography color={color} level="title-sm" sx={sx}>
                    {label}
                </Typography>
            </Chip>
            {warningIcon && (
                <SvgIcon
                    data-test-id="project-focus"
                    data-test-project-name-value={props['data-test-project-name-value']}
                    sx={{ marginLeft: '8px' }}
                    viewBox="0 0 24 16"
                >
                    <WarningIcon />
                </SvgIcon>
            )}
        </Box>
    );
}
