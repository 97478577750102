import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectsContext } from '../contexts/project-context';
import { projectActions } from 'src/core/project/service';
import { PageTemplate } from '../layout/PageTemplate';
import { useAppAuth } from '../contexts/auth-context';
import Loader from '../molecules/Loader';

import BackButton from '../molecules/BackButton';
import { Stack } from '@mui/joy';
import { DocumentCards } from '../molecules/documents/DocumentCard';
import ImagesCard from '../molecules/projects/ImagesCard';

import DocumentForm, { DocumentDto } from '../molecules/documents/DocumentForm';
import { t } from 'i18next';
import PageTitleCard from '../molecules/accounting/PageTitleCard';

export function SurveyPage() {
    const { authenticatedCall } = useAppAuth();
    const { projectState, dispatch } = useContext(ProjectsContext)!;
    const { id } = useParams();
    useEffect(() => {
        if (id) {
            authenticatedCall(async (accessToken) => dispatch(projectActions.loadOne(accessToken, id)));
        }
    }, [dispatch, id, authenticatedCall]);
    const project = projectState.project;

    // filter documents by type
    const pdfDocuments =
        project && project.documents
            ? project.documents.filter(
                  (doc) => doc.type && doc.type.includes('survey') && doc.mimeType && doc.mimeType.includes('pdf'),
              )
            : [];

    const imageDocuments =
        project && project.documents
            ? project.documents.filter(
                  (doc) =>
                      doc.type &&
                      doc.type.includes('survey') &&
                      doc.mimeType &&
                      (doc.mimeType.includes('jpg') || doc.mimeType.includes('jpeg') || doc.mimeType.includes('png')),
              )
            : [];

    return (
        <PageTemplate greyBackground>
            <Stack
                sx={{ height: '100%', paddingX: '20px', paddingY: '40px', gap: '16px' }}
                data-test-id="upload-survey-document-page"
            >
                <BackButton path={`/projects/${project?.id}`} label={t(`projects.generic_data.back`)} />
                <PageTitleCard
                    isPrimary={false}
                    isWarning={false}
                    title={t('survey.page_title')}
                    subtitle={t('survey.page_subtitle')}
                />

                {project && project.id === id ? (
                    <>
                        <ImagesCard projectId={project.id} documents={imageDocuments} />
                        <DocumentCards documents={pdfDocuments} />
                        <DocumentForm
                            projectId={project.id}
                            parentEntityName="project"
                            parentEntityId={project.id}
                            type="survey"
                            types={[]}
                            showTitleInput={true}
                            showSubmitButton={true}
                            callback={(documents: DocumentDto[]) => {
                                console.log('documents updated', documents);
                            }}
                        />
                    </>
                ) : (
                    <Loader />
                )}
            </Stack>
        </PageTemplate>
    );
}
