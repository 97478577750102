import { Stack } from '@mui/joy';
import React, { useContext, useEffect, useMemo } from 'react';
import { ProjectsContext } from '../contexts/project-context';
import { projectActions } from 'src/core/project/service';
import { PageTemplate } from '../layout/PageTemplate';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../molecules/BackButton';
import ProjectOverviewCard from '../molecules/projects/ProjectOverviewCard';
import Loader from '../molecules/Loader';
import MenuLine from '../molecules/MenuLine';
import { useAppAuth } from '../contexts/auth-context';
import { t } from 'i18next';
import getStatusKey from 'src/utils/getStatusKey';

const tabs = [
    {
        key: 'generic-data',
        disabledFor: ['project_rejected'],
    },
    {
        key: 'technical-data',
        disabledFor: ['project_rejected'],
    },
    {
        key: 'quotes',
        disabledFor: ['project_proposed', 'project_rejected', 'project_accepted', 'survey_planned'],
    },
    {
        key: 'delivery',
        disabledFor: [
            'project_proposed',
            'project_rejected',
            'project_accepted',
            'survey_planned',
            'survey_conducted',
            'quote_proposed',
            'waiting_for_new_quote',
            'quote_validated',
            'quote_signed',
            'quote_obsolete',
        ],
    },
    {
        key: 'pre-installation',
        disabledFor: [
            'project_proposed',
            'project_rejected',
            'project_accepted',
            'survey_planned',
            'survey_conducted',
            'quote_proposed',
            'waiting_for_new_quote',
            'quote_validated',
            'quote_signed',
            'quote_obsolete',
        ],
    },
    {
        key: 'installation',
        disabledFor: [
            'project_proposed',
            'project_rejected',
            'project_accepted',
            'survey_planned',
            'survey_conducted',
            'quote_proposed',
            'waiting_for_new_quote',
            'quote_validated',
            'quote_signed',
            'quote_obsolete',
        ],
    },
    {
        key: 'legal-documents',
        disabledFor: [
            'project_proposed',
            'project_rejected',
            'project_accepted',
            'survey_planned',
            'survey_conducted',
            'quote_proposed',
            'waiting_for_new_quote',
            'quote_validated',
            'quote_signed',
            'quote_obsolete',
            'delivery_and_installation',
            'waiting_for_sign_off_sheet_signature',
        ],
        countries: ['ES', 'IT', 'DE'],
    },
    {
        key: 'billings',
        disabledFor: [
            'project_proposed',
            'project_rejected',
            'project_accepted',
            'survey_planned',
            'survey_conducted',
            'quote_proposed',
            'waiting_for_new_quote',
            'quote_validated',
            'quote_signed',
            'quote_obsolete',
        ],
    },
    {
        key: 'messaging',
        disabledFor: ['*'],
    },
    {
        key: 'note',
        disabledFor: ['*'],
    },
];

export function ProjectOverviewPage() {
    const { id } = useParams();
    const { authenticatedCall } = useAppAuth();
    const { projectState, dispatch } = useContext(ProjectsContext)!;
    const navigate = useNavigate();

    const project = projectState.project;
    const countryCode = project?.address.countryCode ?? '';

    useEffect(() => {
        if (id) {
            authenticatedCall(async (accessToken) => dispatch(projectActions.loadOne(accessToken, id)));
        }
    }, [dispatch, id, authenticatedCall]);

    const statusKey = useMemo(() => {
        let statusKey = getStatusKey(project);
        return statusKey;
    }, [project]);

    const disabledTabs: Array<string> = [];

    // set billings tab visiblity
    const purchaseOrders = project?.purchaseOrders;
    if (!purchaseOrders || purchaseOrders.length === 0) {
        // no purchase orders => hide billings tab
        disabledTabs.push('billings');
    }

    return (
        <PageTemplate greyBackground>
            <Stack
                sx={{ height: '100%', paddingX: '20px', paddingY: '40px', gap: '16px' }}
                data-test-id="project-overview"
            >
                <BackButton path="/projects" page={projectState.page} label="projects.back_to_listing" />
                {projectState.project && projectState.project.id === id ? (
                    <ProjectOverviewCard project={projectState.project} />
                ) : (
                    <Loader />
                )}

                {tabs.map(
                    ({ key, disabledFor, countries }) =>
                        // if countries is not defined, show the tab
                        // if countries is defined, show the tab only if the country is in the list
                        (!countries || countries.includes(countryCode)) &&
                        // check if key is 'pre-installation' and projectState.project?.surveyMandatory is true
                        !(key === 'pre-installation' && projectState.project?.surveyMandatory) && (
                            <MenuLine
                                label={t(`projects.${key.replaceAll('-', '_')}.button`)}
                                dataTestId={`${key}-link`}
                                soon={['messaging', 'note'].includes(key) ? true : false}
                                onClick={() => navigate(key)}
                                disabled={
                                    disabledFor.includes(statusKey) ||
                                    disabledFor.includes('*') ||
                                    disabledTabs.includes(key)
                                }
                                sx={{ minHeight: '48px' }}
                                key={`menu-${key}`}
                            />
                        ),
                )}
            </Stack>
        </PageTemplate>
    );
}
