import { useContext } from 'react';
import { Project, PurchaseOrder } from 'src/core/project/domain';
import { ProjectsContext } from 'src/ui/contexts/project-context';
import { Typography } from '@mui/joy';
import { t } from 'i18next';
import { PurchaseOrderCards } from '../accounting/PurchaseOrderCard';

export function Billings() {
    const { projectState } = useContext(ProjectsContext)!;

    let project: Project | null = projectState.project;

    const hasGlobal: boolean =
        (project?.billings
            .filter((billing) => billing?.purchaseOrder === null)
            .filter((billing) => billing?.status !== 'rejected').length ?? 0) > 0;

    const purchaseOrders: Array<PurchaseOrder> = project?.purchaseOrders ?? [];

    let totalBillingsAmount: number = 0;
    let totalRejectedBillingsAmount: number = 0;
    project?.billings.forEach((billing) => {
        if (billing && billing.amount) {
            totalBillingsAmount += Number(billing.amount);
            if (billing.status === 'rejected') {
                totalRejectedBillingsAmount += Number(billing.amount);
            }
        }
    });

    let totalPurchaseOrdersAmount: number = 0;
    for (const po of purchaseOrders) {
        if (po && po.amount) {
            totalPurchaseOrdersAmount += Number(po.amount);
        }
    }

    return (
        <>
            {purchaseOrders && purchaseOrders.length !== 0 ? (
                <PurchaseOrderCards
                    project={project}
                    totalPurchaseOrdersAmount={totalPurchaseOrdersAmount}
                    hasBilled={totalBillingsAmount - totalRejectedBillingsAmount !== 0} //  || amountReviewed > 0
                    hasGlobal={hasGlobal}
                />
            ) : (
                <Typography level="h3" textAlign={'center'}>
                    {t('billing.no_purchase_orders')}
                </Typography>
            )}
        </>
    );
}
