import { CircularProgress, Stack } from '@mui/joy';
import { theme } from '../theme';

export default function Loader() {
    return (
        <Stack height="100%" alignItems="center" justifyContent="center">
            <CircularProgress sx={{ '--CircularProgress-progressColor': theme.palette.primary[500] }} />
        </Stack>
    );
}
