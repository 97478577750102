import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/joy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getUrlLang } from '../i18n';

export const LogoutButton = () => {
    const { logout } = useAuth0();
    const { t } = useTranslation();
    const urlLang = getUrlLang();

    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin + urlLang,
            },
        });
    };

    return (
        <Button className="button__logout" onClick={handleLogout} data-test-id="logout">
            {t('auth.signout_button')}
        </Button>
    );
};
