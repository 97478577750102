const formatDate = (
    date: string | Date | undefined,
    replaceSlash = true,
    withLongText = false,
    lang?: string,
    country?: string,
) => {
    if (!date) return;

    console.log(country);

    let formattedDate = new Date(date).toLocaleDateString(
        country ? (lang ? lang + '-' + country : country + '-' + country) : 'en-EN',
        withLongText
            ? {
                  year: 'numeric',
                  day: 'numeric',
                  month: 'long',
              }
            : {},
    );

    return replaceSlash ? formattedDate.replaceAll('/', '-') : formattedDate;
};
export default formatDate;
