import { Card, Typography } from '@mui/joy';
import { ProjectEnergyManagement } from 'src/core/project/domain';
import { useTranslation } from 'react-i18next';
import InfoLine from 'src/ui/atoms/InfoLine';

export default function EnergyManagementCard({ energyManagement }: { energyManagement: ProjectEnergyManagement }) {
    const { t } = useTranslation();

    const card = (
        <Card variant="plain">
            <Typography level="h4">{t('projects.technical_data.energy_management')}</Typography>
            <Typography level="body-sm">{t('projects.technical_data.energy_management_subtitle')}</Typography>

            <InfoLine
                label={t('projects.technical_data.tic_link')}
                value={energyManagement.ticLink}
                dataTestId="project-tic-link"
            />
        </Card>
    );

    if (!energyManagement.ticLink) {
        return;
    }

    return card;
}
