import { Card, IconButton, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useContext, useMemo, useState } from 'react';

import { projectActions } from '../../../core/project/service';
import DarkCloseIcon from '../../atoms/icons/DarkCloseIcon';
import { useAppAuth } from '../../contexts/auth-context';
import { ProjectsContext } from '../../contexts/project-context';
import FloatingLabelInput from '../forms/FloatingLabelInput';
import Button from '../Button';
import FileInput from '../forms/FileInput';
import RadioInput from '../forms/RadioInput';

interface TypeOption {
    label: string;
    value: string;
}

export interface DocumentDto {
    id?: string;
    name: string;
    title: string;
    fileKey?: string;
    size: number;
    mimeType: string;
    type: string;
    parentEntityId?: string;
    parentEntityName: string;
    projectId: string;
    createdAt?: Date;
    updatedAt?: Date;
    body: string;
}

export default function DocumentForm({
    projectId,
    parentEntityName,
    parentEntityId,
    type,
    types,
    showTitleInput,
    showSubmitButton,
    callback,
}: {
    projectId: string;
    parentEntityName: string;
    parentEntityId: string;
    type: string;
    types: TypeOption[];
    showTitleInput: boolean;
    showSubmitButton: boolean;
    callback?: (documents: DocumentDto[]) => void;
}) {
    const { t } = useTranslation();
    const { authenticatedCall } = useAppAuth();
    const { dispatch } = useContext(ProjectsContext)!;
    const [title, setTitle] = useState<string>('');

    const [localDocuments, setLocalDocuments] = useState<DocumentDto[]>([]);
    const [uploading, setUploading] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const [selectedType, setSelectedType] = useState<string>(type);

    const updateLocalDocuments = (documents: DocumentDto[]) => {
        setLocalDocuments(documents);
        if (callback) {
            callback(documents);
        }
    };

    const convertAndSaveFile = (file: File | null): Promise<string | null> => {
        return new Promise((resolve, reject) => {
            if (file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result as string);
                reader.onerror = (error) => {
                    console.error('Error: ', error);
                    reject(error);
                };
            } else {
                resolve(null);
            }
        });
    };

    const addFilesToDocuments = async (newFiles: File[]) => {
        setFiles((prev) => [...prev, ...newFiles]);

        const newDocuments: Array<DocumentDto> = [];
        for (const file of newFiles) {
            try {
                const body = await convertAndSaveFile(file);
                const mimeType = file?.type ?? '';
                const name = file?.name ?? '';
                const size = file?.size ?? 0;
                newDocuments.push({
                    name,
                    title: title + ` ${newDocuments.length + 1}`,
                    size,
                    mimeType,
                    type: selectedType,
                    parentEntityId,
                    parentEntityName,
                    projectId,
                    body: body ?? '',
                });
            } catch (error) {
                console.error('Error processing file:', file?.name, error);
            }
        }

        const documents: DocumentDto[] = [...localDocuments, ...newDocuments];
        updateLocalDocuments(documents);
    };

    const handleRemoveDocument = (index: number) => {
        setFiles(files.filter((_, i) => i !== index));
        const documents = localDocuments.filter((_, i) => i !== index);
        updateLocalDocuments(documents);
    };

    const isStateValid =
        useMemo(() => !localDocuments.some((f) => !f.title || !f.body), [localDocuments]) &&
        localDocuments.length > 0 &&
        title;

    const submit = async () => {
        setUploading(true);
        try {
            await authenticatedCall(async (accessToken) => {
                dispatch(projectActions.createDocuments(accessToken, localDocuments));
            });
            if (callback) {
                callback(localDocuments);
            }
            setFiles([]);
            updateLocalDocuments([]);
            setTitle('');
        } catch (e: any) {
            console.error(e.error);
        } finally {
            setUploading(false);
        }
    };

    return (
        <>
            <Card variant="outlined">
                {localDocuments.map((doc, index) => (
                    <Card variant="plain" key={index} sx={{ padding: '0px' }}>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography level="h4" alignSelf="center"></Typography>
                            {localDocuments.length > 0 && (
                                <IconButton
                                    color="neutral"
                                    onClick={() => {
                                        handleRemoveDocument(index);
                                    }}
                                >
                                    <DarkCloseIcon />
                                </IconButton>
                            )}
                        </Stack>

                        <Button
                            color="grey"
                            variant={files?.length !== 0 ? 'solid' : 'soft'}
                            onClick={() => {}}
                            sx={{ padding: '32px', height: '152px', width: '100%' }}
                        >
                            <Stack alignItems="center">
                                <Typography sx={{ color: 'white' }} level="body-md">
                                    {files[index]?.name}
                                </Typography>
                            </Stack>
                        </Button>
                    </Card>
                ))}
                <FileInput onChange={addFilesToDocuments} files={files} setFiles={setFiles} />
                {showTitleInput && (
                    <FloatingLabelInput
                        label={t('file_management.title')}
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value);
                            const updatedDocs = [...localDocuments];
                            for (let i = 0; i < updatedDocs.length; i++) {
                                updatedDocs[i].title = e.target.value + ` ${i + 1}`;
                            }
                            updateLocalDocuments(updatedDocs);
                        }}
                    />
                )}

                {types && types.length > 1 && (
                    <RadioInput
                        selectedValue={selectedType}
                        choices={types}
                        onChange={(value) => {
                            if (value) {
                                setSelectedType(value.toString());
                                if (localDocuments) {
                                    for (let i = 0; i < localDocuments.length; i++) {
                                        localDocuments[i].type = value.toString();
                                    }
                                    updateLocalDocuments(localDocuments);
                                }
                            }
                        }}
                    />
                )}

                {showSubmitButton && (
                    <Button
                        onClick={submit}
                        disabled={!isStateValid}
                        loading={uploading}
                        label={t('file_management.submit')}
                    />
                )}
            </Card>
        </>
    );
}
