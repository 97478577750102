import { ReactNode } from 'react';
import { Stack, Card, Typography, Box } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { theme } from '../theme';
import Button from './Button';
import RightArrow from '../atoms/icons/RightSecondaryArrowIcon';
import EyeIcon from '../atoms/icons/EyeIcon';

interface TwoActionsCardInterface {
    title?: string | ReactNode;
    subtitle?: string | ReactNode;
    primaryLabel?: string;
    secondaryLabel?: string;
    onPrimaryButtonClick?: Function;
    onSecondaryButtonClick?: Function;
    primaryActionDataTestId: string;
    secondaryActionDataTestId?: string;
    primaryRightArrow?: boolean;
    secondaryRightArrow?: boolean;
    color?: 'primary' | 'danger' | undefined;
}

export default function TwoActionsCard({
    title = 'projects.accept_decline_title',
    subtitle = 'projects.accept_decline_subtitle',
    primaryLabel = 'projects.accept',
    secondaryLabel = 'projects.decline',
    color = undefined,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    primaryActionDataTestId,
    secondaryActionDataTestId,
    primaryRightArrow,
    secondaryRightArrow,
}: TwoActionsCardInterface) {
    const { t } = useTranslation();
    let border: string = 'none';
    let background: string = theme.palette.neutral[50];
    if ('primary' === color) {
        border = `solid 1px ${theme.palette.primary[700]}`;
        background = theme.palette.primary[100];
    } else if ('danger' === color) {
        background = theme.palette.warning[50];
        border = `solid 1px ${theme.palette.warning[500]}`;
    }

    return (
        <Card
            orientation="vertical"
            sx={{
                border,
                padding: '20px',
                background,
            }}
        >
            {'string' === typeof title ? <Typography level="h4">{t(title)}</Typography> : title}
            {'string' === typeof subtitle ? <Typography level="body-lg">{t(subtitle)}</Typography> : subtitle}
            <Stack justifyContent="space-between">
                {onPrimaryButtonClick && (
                    <Button
                        data-test-id={primaryActionDataTestId}
                        onClick={() => onPrimaryButtonClick()}
                        endDecorator={primaryRightArrow && <RightArrow />}
                        variant={undefined === color ? 'soft' : 'solid'}
                        label={
                            t(primaryLabel.split(':')[0]) +
                            ' ' +
                            (primaryLabel.split(':')[1]
                                ? primaryLabel.split(':')[1] + ' : '
                                : t(primaryLabel.split(':')[1])) +
                            t(primaryLabel.split(':')[2])
                        }
                        startDecorator={
                            primaryLabel.split(':')[1] ? (
                                <Box>
                                    <EyeIcon />
                                </Box>
                            ) : null
                        }
                        color={'danger' === color ? 'secondary' : undefined === color ? 'grey' : 'primary'}
                    />
                )}
                {onSecondaryButtonClick && (
                    <Button
                        data-test-id={secondaryActionDataTestId}
                        variant="outlined"
                        color="secondary"
                        onClick={() => onSecondaryButtonClick()}
                        label={t(secondaryLabel)}
                        endDecorator={secondaryRightArrow && <RightArrow />}
                        sx={{ marginTop: onPrimaryButtonClick ? '12px' : 0 }}
                    />
                )}
            </Stack>
        </Card>
    );
}
