import { Card, Stack, Typography } from '@mui/joy';
import React, { useContext, useEffect, useMemo } from 'react';
import { ProjectsContext } from '../contexts/project-context';
import { projectActions } from 'src/core/project/service';
import { PageTemplate } from '../layout/PageTemplate';
import { useParams } from 'react-router-dom';
import BackButton from '../molecules/BackButton';
import Loader from '../molecules/Loader';
import PicturesCard from '../molecules/projects/PicturesCard';
import ConfigurationCard from '../molecules/projects/ConfigurationCard';
import QuotingCard from '../molecules/projects/QuotingCard';
import EnergyManagementCard from '../molecules/projects/EnergyManagementCard';
import { useAppAuth } from '../contexts/auth-context';
import TechnicalSurveyActionsCard from '../molecules/projects/TechnicalSurveyActionsCard';
import { DocumentCards } from '../molecules/documents/DocumentCard';
import { t } from 'i18next';

export function ProjectTechnicalDataPage() {
    const { id } = useParams();
    const { projectState, dispatch } = useContext(ProjectsContext)!;
    const { authenticatedCall } = useAppAuth();

    const project = projectState.project;
    const pdfDocuments = project?.documents.filter((document) => document.type === 'project-pdf') ?? [];
    console.log(pdfDocuments);
    useEffect(() => {
        if (id) {
            authenticatedCall(async (accessToken) => dispatch(projectActions.loadOne(accessToken, id)));
        }
    }, [dispatch, id, authenticatedCall]);

    const shouldGetActual = useMemo(
        () =>
            projectState.project?.status &&
            !['project_proposed', 'project_rejected', 'project_accepted', 'survey_planned'].includes(
                projectState.project?.status,
            ),
        [projectState.project?.status],
    );

    return (
        <PageTemplate greyBackground>
            <Stack
                sx={{ height: '100%', paddingX: '20px', paddingY: '40px', gap: '16px' }}
                data-test-id="technical-data-page"
            >
                <BackButton path={`/projects/${projectState?.project?.id}`} label="projects.generic_data.back" />
                {projectState.project && projectState.project.id === id ? (
                    <>
                        <TechnicalSurveyActionsCard project={projectState.project} />
                        <PicturesCard pictures={projectState.project.pictures} />
                        <Card variant="plain">
                            <Typography level="h4">{t('projects.technical_data.pdf_documents')}</Typography>
                            <Typography level="body-sm">
                                {t('projects.technical_data.pdf_documents_subtitle')}
                            </Typography>

                            <DocumentCards documents={pdfDocuments} />
                        </Card>
                        <ConfigurationCard
                            configuration={
                                shouldGetActual
                                    ? projectState.project.actualConfiguration
                                    : projectState.project.proposedConfiguration
                            }
                        />
                        <QuotingCard
                            estimate={
                                shouldGetActual
                                    ? projectState.project.actualEstimate
                                    : projectState.project.proposedEstimate
                            }
                        />
                        <EnergyManagementCard
                            energyManagement={
                                shouldGetActual
                                    ? projectState.project.actualEnergyManagement
                                    : projectState.project.proposedEnergyManagement
                            }
                        />
                    </>
                ) : (
                    <Loader />
                )}
            </Stack>
        </PageTemplate>
    );
}
