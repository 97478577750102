// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/VisbyCF-Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/VisbyCF-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/VisbyCF-Heavy.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'VisbyCFBold';
    src:
        local('VisbyCF-Bold'),
        url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
}

@font-face {
    font-family: 'VisbyCFRegular';
    src:
        local('VisbyCF-Regular'),
        url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('opentype');
}

@font-face {
    font-family: 'VisbyCFBlack';
    src:
        local('VisbyCF-Black'),
        url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('opentype');
}
`, "",{"version":3,"sources":["webpack://./src/ui/font.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B;;kEAEsD;AAC1D;;AAEA;IACI,6BAA6B;IAC7B;;kEAEyD;AAC7D;;AAEA;IACI,2BAA2B;IAC3B;;kEAEuD;AAC3D","sourcesContent":["@font-face {\n    font-family: 'VisbyCFBold';\n    src:\n        local('VisbyCF-Bold'),\n        url('./fonts/VisbyCF-Bold.otf') format('opentype');\n}\n\n@font-face {\n    font-family: 'VisbyCFRegular';\n    src:\n        local('VisbyCF-Regular'),\n        url('./fonts/VisbyCF-Regular.otf') format('opentype');\n}\n\n@font-face {\n    font-family: 'VisbyCFBlack';\n    src:\n        local('VisbyCF-Black'),\n        url('./fonts/VisbyCF-Heavy.otf') format('opentype');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
