import { Modal, ModalClose, ModalDialog, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useCallback, useContext, useEffect, useState } from 'react';
import formatDate from 'src/utils/formatDate';
import isDate from 'src/utils/isDate';
import HoursChoice from './HoursChoice';
import ModalSummary from './ModalSummary';
import ModalButtons from './ModalButtons';
import { getUserLang } from 'src/ui/i18n';
import { ProjectsContext } from 'src/ui/contexts/project-context';

interface DateModalProps {
    open: boolean;
    setOpen: Function;
    projectId: string;
    entity: 'survey' | 'installation';
    askEndDate: boolean;
}

type ValuePiece = Date | null;

function isDayPast(date: Date): boolean {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    if (newDate > today) {
        return true;
    }
    return false;
}

export type DateValue = ValuePiece | [ValuePiece, ValuePiece];
export default function DateModal({ open, setOpen, projectId, entity, askEndDate }: DateModalProps) {
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState<DateValue>(null);
    const [isStartHourSelected, setIsStartHourSelected] = useState<boolean>(false);
    const [startHourSelected, setStartHourSelected] = useState<number | null>(null);
    const [endDate, setEndDate] = useState<DateValue>(null);
    const [isEndHourSelected, setIsEndHourSelected] = useState<boolean>(false);
    const [endHourSelected, setEndHourSelected] = useState<number | null>(null);
    const [step, setStep] = useState<number>(1);
    const { projectState } = useContext(ProjectsContext)!;

    const checkNextDisabled = useCallback(() => {
        if (step === 1) {
            return !startDate;
        }
        if (step === 2) {
            return !isStartHourSelected;
        }
        if (askEndDate) {
            if (step === 3) {
                return !endDate;
            }
            if (step === 4) {
                return !isEndHourSelected;
            }
        } else if (step === 3) {
            return true;
        }
        return false;
    }, [step, startDate, endDate, isStartHourSelected, isEndHourSelected, askEndDate]);

    let stepCalendar, stepHours: Array<number>;
    let stepSummary: number;
    if (askEndDate) {
        stepCalendar = [1, 3];
        stepHours = [2, 4];
        stepSummary = 5;
    } else {
        stepCalendar = [1];
        stepHours = [2];
        stepSummary = 3;
    }

    const getSubtitle = useCallback(() => {
        if (step === 1) {
            return <Typography level="body-lg">{t(`date_modal.modal_date_subtitle_start`)}</Typography>;
        }

        if (step === 2) {
            const date = isDate(startDate)
                ? formatDate(startDate, false, true, getUserLang(), projectState.project?.address.countryCode)
                : '';
            return (
                <Stack>
                    <Typography level="title-lg">
                        {t(`date_modal.modal_date_subtitle_start_hour_1`, { date })}
                    </Typography>
                    <Typography level="body-lg">{t(`date_modal.modal_date_subtitle_start_hour_2`)}</Typography>
                </Stack>
            );
        }

        if (askEndDate) {
            if (step === 3) {
                return <Typography level="body-lg">{t(`date_modal.modal_date_subtitle_end`)}</Typography>;
            }

            if (step === 4) {
                const date = isDate(endDate)
                    ? formatDate(endDate, false, true, getUserLang(), projectState.project?.address.countryCode)
                    : '';
                return (
                    <Stack>
                        <Typography level="title-lg">
                            {t(`date_modal.modal_date_subtitle_end_hour_1`, { date })}
                        </Typography>
                        <Typography level="body-lg">{t(`date_modal.modal_date_subtitle_end_hour_2`)}</Typography>
                    </Stack>
                );
            }
        }
    }, [step, askEndDate, t, startDate, projectState.project?.address.countryCode, endDate]);

    useEffect(() => {
        setIsStartHourSelected(false);
        setStartHourSelected(null);
        setEndDate(null);
        setIsEndHourSelected(false);
        setEndHourSelected(null);
    }, [startDate]);

    useEffect(() => {
        setIsEndHourSelected(false);
        setEndHourSelected(null);
    }, [endDate]);

    const initialize = () => {
        setStartDate(null);
        setIsStartHourSelected(false);
        setStartHourSelected(null);
        setEndDate(null);
        setIsEndHourSelected(false);
        setEndHourSelected(null);
        setStep(1);
    };

    return (
        <Modal open={open} onClose={() => setOpen(false)} data-test-id={`plan-${entity}-modal`}>
            <ModalDialog sx={{ border: 'none' }}>
                <Stack id="modal" height="100%" justifyContent="center" sx={{ overflow: 'scroll' }}>
                    <ModalClose />
                    <Typography level="h4">{t(`${entity}.modal_date_title`)}</Typography>
                    {getSubtitle()}
                    {stepCalendar.find((e) => e === step) && (
                        <Calendar
                            onChange={step === 1 ? setStartDate : setEndDate}
                            value={step === 1 ? startDate : endDate}
                            minDate={step === 3 && isDate(startDate) ? new Date(startDate.getTime()) : new Date()}
                            defaultActiveStartDate={
                                step === 3 && isDate(startDate) ? new Date(startDate.getTime()) : new Date()
                            }
                        />
                    )}
                    {stepHours.find((e) => e === step) && (
                        <HoursChoice
                            date={step === 2 ? startDate : endDate}
                            setDate={step === 2 ? setStartDate : setEndDate}
                            setIsSelected={step === 2 ? setIsStartHourSelected : setIsEndHourSelected}
                            hourSelected={step === 2 ? startHourSelected : endHourSelected}
                            setHourSelected={step === 2 ? setStartHourSelected : setEndHourSelected}
                            setEndDate={askEndDate ? null : setEndDate}
                            activeStartDate={
                                (step === 2 && isDate(startDate) && isDayPast(startDate)) ||
                                (isDate(endDate) && isDayPast(endDate))
                                    ? undefined
                                    : new Date()
                            }
                        />
                    )}
                    {step === stepSummary && (
                        <ModalSummary startDate={startDate} endDate={askEndDate ? endDate : null} />
                    )}
                    <ModalButtons
                        step={step}
                        setStep={setStep}
                        setOpen={setOpen}
                        projectId={projectId}
                        checkNextDisabled={checkNextDisabled}
                        startDate={startDate}
                        endDate={endDate}
                        initialize={initialize}
                        entity={entity}
                        finalStep={stepSummary}
                    />
                </Stack>
            </ModalDialog>
        </Modal>
    );
}
