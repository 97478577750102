import { useCallback, useRef, useState } from 'react';

type Reducer<State, Action> = (state: State, action: Action) => Promise<State>;
type Dispatcher<Action> = (action: Action) => Promise<void>;

export function useAsyncReducer<State extends { error: unknown }, Action>(
    reducer: Reducer<State, Action>,
    initialState: State,
): [State, Dispatcher<Action>] {
    const stateRef = useRef(initialState);
    const [, updateState] = useState({}); // use only to force re-render when stateRef is updated

    // In order to avoid infinit re-render loop, `dispatch` mustn't be recreated when `state` is changed. Therefore we can't use `useState` to manage `state`, we use `useRef` instead.
    const dispatch = useCallback(
        async (action: Action) => {
            try {
                stateRef.current = await reducer(structuredClone(stateRef.current), action);
            } catch (err) {
                stateRef.current = { ...stateRef.current, error: err };
            }
            updateState({});
        },
        [reducer],
    );

    return [stateRef.current, dispatch];
}
