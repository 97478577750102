import React, { ReactNode } from 'react';
import { ButtonProps, ColorPaletteProp, Button as JoyButton } from '@mui/joy';
import { Color, Variant } from '../types/joy-ui';
import { theme } from '../theme';
import { Typography } from '@mui/joy';
import { SxProps } from '@mui/joy/styles/types';

interface ButtonInterface extends Omit<ButtonProps, 'variant'> {
    color?: Color;
    variant?: Variant | 'underlined';
    disabled?: boolean;
    loading?: boolean;
    children?: ReactNode;
    label?: string;
    bold?: boolean;
    sx?: SxProps;
}

export default function Button({
    color = 'primary',
    variant = 'solid',
    disabled,
    label,
    bold = true,
    children,
    loading = false,
    sx,
    ...props
}: ButtonInterface) {
    // We force color in theme palette to be as ColorPropType to be accepted by Typography
    const textColor =
        variant === 'underlined'
            ? (theme.palette[color!][`outlinedBorder`] as ColorPaletteProp)
            : (theme.palette[color!][`${variant}Color`] as ColorPaletteProp);

    return (
        <JoyButton
            {...props}
            loading={loading}
            color={color}
            disabled={disabled}
            variant={variant === 'underlined' ? 'plain' : variant}
            sx={
                variant === 'underlined' && !sx
                    ? {
                          backgroundColor: 'transparent',
                          borderBottom: `1px solid ${
                              disabled ? theme.palette.neutral[400] : theme.palette[color!][`outlinedBorder`]
                          }`,
                          borderRadius: 1,
                          padding: 0,
                          margin: '5px',
                          minHeight: 0,
                          '&:hover': {
                              borderColor: theme.palette.neutral[500],
                              color: theme.palette.neutral[500],
                              background: 'none',
                          },
                          '&:active': {
                              borderColor: theme.palette.neutral[400],
                              color: theme.palette.neutral[400],
                              background: 'none',
                          },
                      }
                    : variant === 'outlined' && !sx
                    ? {
                          background: 'transparent',
                          borderColor: 'black',
                          color: theme.palette.secondary[500],
                      }
                    : sx
            }
        >
            {label && (
                <Typography level={bold ? 'title-lg' : 'title-md'} color={textColor}>
                    {label}
                </Typography>
            )}
            {children}
        </JoyButton>
    );
}
