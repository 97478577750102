import { useContext, useEffect, useState } from 'react';
import { useAppAuth } from '../../contexts/auth-context';
import { projectActions } from 'src/core/project/service';
import { Quote, Project } from 'src/core/project/domain';
import { ProjectsContext } from 'src/ui/contexts/project-context';
import QuoteForm from './QuoteForm';
import AliceCarousel from 'react-alice-carousel';
import QuoteCard from './QuoteCard';
import QuoteStatusCard from './QuoteStatusCard';

export function Quotes() {
    const { authenticatedCall } = useAppAuth();

    const { projectState, dispatch } = useContext(ProjectsContext)!;
    const [showForm, setShowForm] = useState<boolean>(false);

    // init project
    let project: Project | null = projectState.project;
    const projectId: string = project && project.id ? project.id : '';
    const quotes: Array<Quote | null> = project?.quotes ?? [];
    const rejectedQuotes = quotes?.filter((quote) => quote?.status === 'rejected');
    const projectStatus = project?.status ?? '';

    useEffect(() => {
        let showForm = true;
        // always keep the form open for new quotes
        if (project && project.quotes) {
            const newQuotes = project.quotes.filter((quote) => quote.status === 'signed');
            if (newQuotes.length > 0) {
                showForm = false;
            }
        }
        setShowForm(showForm);
    }, [project]);

    return (
        <>
            <>{<QuoteStatusCard status={projectStatus} onClick={() => setShowForm(true)} />}</>

            {quotes && quotes.length !== 0 && (
                <AliceCarousel
                    disableButtonsControls={true}
                    responsive={{ 0: { items: 1 }, 568: { items: 2 }, 1024: { items: 3 } }}
                    controlsStrategy="responsive"
                    items={quotes.map((quote, index) => (
                        <QuoteCard item={quote} small showDetailsButton />
                    ))}
                />
            )}

            {rejectedQuotes && rejectedQuotes.length !== 0 && (
                <AliceCarousel
                    disableButtonsControls={true}
                    responsive={{ 0: { items: 1 }, 568: { items: 2 }, 1024: { items: 3 } }}
                    controlsStrategy="responsive"
                    items={rejectedQuotes.map((quote, index) => (
                        <QuoteCard item={quote} small />
                    ))}
                />
            )}

            {showForm && (
                <QuoteForm
                    projectId={projectId}
                    callback={() => {
                        authenticatedCall(async (accessToken) => {
                            dispatch(projectActions.loadOne(accessToken, projectId));
                        });
                    }}
                />
            )}
        </>
    );
}
