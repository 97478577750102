import { useContext, useEffect, useState } from 'react';
import { useAppAuth } from '../../contexts/auth-context';
import { projectActions } from 'src/core/project/service';
import { Billing, Project, PurchaseOrder } from 'src/core/project/domain';
import { ProjectsContext } from 'src/ui/contexts/project-context';
import BillingForm from './BillingForm';
import AliceCarousel from 'react-alice-carousel';
import BillingCard from './BillingCard';
import { Typography } from '@mui/joy';
import { t } from 'i18next';
import { PurchaseOrderCards } from '../accounting/PurchaseOrderCard';

export function Billings() {
    const { authenticatedCall } = useAppAuth();
    const { projectState, dispatch } = useContext(ProjectsContext)!;
    const [showForm, setShowForm] = useState(false);

    // init project
    let project: Project | null = projectState.project;
    const projectId: string = project && project.id ? project.id : '';
    const billings: Array<Billing> = project?.billings ?? [];
    const purchaseOrders: Array<PurchaseOrder> = project?.purchaseOrders ?? [];
    const rejectedBillings = billings?.filter((billing) => billing?.status === 'rejected');

    let totalBillingsAmount: number = 0;
    for (const billing of billings) {
        if (billing && billing.amount) {
            totalBillingsAmount += Number(billing.amount);
        }
    }
    console.log('totalBillingsAmount', totalBillingsAmount);

    let totalPurchaseOrdersAmount: number = 0;
    for (const po of purchaseOrders) {
        if (po && po.amount) {
            totalPurchaseOrdersAmount += Number(po.amount);
        }
    }
    console.log('totalPurchaseOrdersAmount', totalPurchaseOrdersAmount);

    let totalRejectedBillingsAmount: number = 0;
    for (const billing of rejectedBillings) {
        if (billing && billing.amount) {
            totalRejectedBillingsAmount += Number(billing.amount);
        }
    }
    console.log('totalRejectedBillingsAmount', totalRejectedBillingsAmount);

    useEffect(() => {
        // show the form when billings amount is less than the sum of purchase orders amount
        let billedAmount = totalBillingsAmount - totalRejectedBillingsAmount;
        let displayForm = false;
        if (totalPurchaseOrdersAmount !== 0 && billedAmount < totalPurchaseOrdersAmount) {
            displayForm = true; // remaining amount to be billed
        }

        setShowForm(displayForm);
    }, [project, totalBillingsAmount, totalRejectedBillingsAmount, totalPurchaseOrdersAmount]);

    return (
        <>
            {purchaseOrders && purchaseOrders.length !== 0 ? (
                <PurchaseOrderCards project={project} />
            ) : (
                <Typography level="h3" textAlign={'center'}>
                    {t('billing.no_purchase_orders')}
                </Typography>
            )}

            {billings && billings.length !== 0 && (
                <AliceCarousel
                    disableButtonsControls={true}
                    responsive={{ 0: { items: 1 }, 568: { items: 2 }, 1024: { items: 3 } }}
                    controlsStrategy="responsive"
                    items={billings.map((billing, index) => (
                        <BillingCard item={billing} small showDetailsButton />
                    ))}
                />
            )}

            {rejectedBillings && rejectedBillings.length !== 0 && (
                <AliceCarousel
                    disableButtonsControls={true}
                    responsive={{ 0: { items: 1 }, 568: { items: 2 }, 1024: { items: 3 } }}
                    controlsStrategy="responsive"
                    items={rejectedBillings.map((billing, index) => (
                        <BillingCard item={billing} small />
                    ))}
                />
            )}

            {showForm && (
                <BillingForm
                    projectId={projectId}
                    callback={() => {
                        authenticatedCall(async (accessToken) => {
                            dispatch(projectActions.loadOne(accessToken, projectId));
                        });
                    }}
                />
            )}
        </>
    );
}
