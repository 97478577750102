import { Box, Button, List, ListDivider, ListItem, Stack, SvgIcon, Typography } from '@mui/joy';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectsContext } from '../contexts/project-context';
import ProjectOverview from '../molecules/projects/ProjectOverview';
import { projectActions } from 'src/core/project/service';
import Pagination from '../molecules/Pagination';
import { PageTemplate } from '../layout/PageTemplate';
import { useAppAuth } from '../contexts/auth-context';
import { theme } from '../theme';
import FilterIcon from '../atoms/icons/FilterIcon';
import RightSecondaryArrowIcon from '../atoms/icons/RightSecondaryArrowIcon';
import { Project, User } from 'src/core/project/domain';
import getStatusKey from 'src/utils/getStatusKey';
import SearchIcon from '../atoms/icons/SearchIcon';
import CloseIcon from '../atoms/icons/CloseIcon';
import Loader from '../molecules/Loader';

export function Projects() {
    const pageSize = 10;
    const { authenticatedCall, user } = useAppAuth();
    const { t } = useTranslation();
    const { projectState, dispatch } = useContext(ProjectsContext)!;
    const [loading, setLoading] = useState(true);
    const [{ fromIdx, toIdx }, setPage] = useState({
        fromIdx: (projectState.page - 1) * pageSize,
        toIdx: projectState.page * pageSize - 1,
    });
    const [hideSidebar, setHideSidebar] = useState(true);
    const [hideSearchbar, setHideSearchbar] = useState(true);
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [projectsToDisplay, setProjectsToDisplay] = useState<Project[]>([]);
    const [initialized, setInitialized] = useState(false);
    const [query, setQuery] = useState('');

    useEffect(() => {
        if (!initialized && projectState.projects.length > 0) {
            setProjectsToDisplay(projectState.projects);
            setSelectedUsers([]);
            setSelectedFilters([]);
            setQuery('');
            setInitialized(true);
        }
    }, [projectState.projects, initialized]);

    const filters = [
        'new',
        'technical_visit',
        'quote',
        'negotiation',
        'delivery_and_planning',
        'documents_and_invoicing',
        'inactive',
    ];

    interface FilteredProjects {
        new: Project[];
        technical_visit: Project[];
        quote: Project[];
        negotiation: Project[];
        delivery_and_planning: Project[];
        documents_and_invoicing: Project[];
        inactive: Project[];
        else: Project[];
    }

    let userList: { user: User; projects: FilteredProjects }[];
    if (projectState.projects.length !== 0) {
        userList = projectState.projects.reduce((acc: { user: User; projects: FilteredProjects }[], project) => {
            const existingUser = acc.find((u) => u.user.authId === project.user.authId);
            let projects: FilteredProjects = {
                new: [],
                technical_visit: [],
                quote: [],
                negotiation: [],
                delivery_and_planning: [],
                documents_and_invoicing: [],
                inactive: [],
                else: [],
            };

            if (['project_proposed'].includes(project.status)) {
                if (existingUser) {
                    existingUser.projects.new.push(project);
                } else {
                    projects.new.push(project);
                    acc.push({
                        user: project.user,
                        projects: projects,
                    });
                }
            } else if (['survey_to_plan', 'survey_planned'].includes(project.status)) {
                if (existingUser) {
                    existingUser.projects.technical_visit.push(project);
                } else {
                    projects.technical_visit.push(project);
                    acc.push({
                        user: project.user,
                        projects: projects,
                    });
                }
            } else if (['survey_conducted'].includes(project.status)) {
                if (existingUser) {
                    existingUser.projects.quote.push(project);
                } else {
                    projects.quote.push(project);
                    acc.push({
                        user: project.user,
                        projects: projects,
                    });
                }
            } else if (
                ['quote_proposed', 'waiting_for_new_quote', 'quote_validated', 'quote_signed'].includes(project.status)
            ) {
                if (existingUser) {
                    existingUser.projects.negotiation.push(project);
                } else {
                    projects.negotiation.push(project);
                    acc.push({
                        user: project.user,
                        projects: projects,
                    });
                }
            } else if (['installation_planned'].includes(getStatusKey(project))) {
                if (project.installation?.startDate && new Date(project.installation?.startDate) >= new Date()) {
                    if (existingUser) {
                        existingUser.projects.delivery_and_planning.push(project);
                    } else {
                        projects.delivery_and_planning.push(project);
                        acc.push({
                            user: project.user,
                            projects: projects,
                        });
                    }
                } else {
                    if (existingUser) {
                        existingUser.projects.documents_and_invoicing.push(project);
                    } else {
                        projects.documents_and_invoicing.push(project);
                        acc.push({
                            user: project.user,
                            projects: projects,
                        });
                    }
                }
            } else if (['installation_to_plan', 'shipping_in_progress'].includes(getStatusKey(project))) {
                if (existingUser) {
                    existingUser.projects.delivery_and_planning.push(project);
                } else {
                    projects.delivery_and_planning.push(project);
                    acc.push({
                        user: project.user,
                        projects: projects,
                    });
                }
            } else if (
                [
                    'waiting_for_pre_installation_form',
                    'ready_to_install',
                    'waiting_for_legal_documents_upload',
                    'waiting_for_billing_upload',
                    'waiting_for_billing_validation',
                ].includes(getStatusKey(project))
            ) {
                if (existingUser) {
                    existingUser.projects.documents_and_invoicing.push(project);
                } else {
                    projects.documents_and_invoicing.push(project);
                    acc.push({
                        user: project.user,
                        projects: projects,
                    });
                }
            } else if (
                ['project_completed', 'project_standby', 'project_lost', 'project_rejected'].includes(project.status)
            ) {
                if (existingUser) {
                    existingUser.projects.inactive.push(project);
                } else {
                    projects.inactive.push(project);
                    acc.push({
                        user: project.user,
                        projects: projects,
                    });
                }
            } else {
                if (existingUser) {
                    existingUser.projects.else.push(project);
                } else {
                    projects.else.push(project);
                    acc.push({
                        user: project.user,
                        projects: projects,
                    });
                }
            }
            return acc;
        }, []);
    }

    useEffect(() => {
        const loadProjects = async () => {
            try {
                await authenticatedCall(async (accessToken) => {
                    await dispatch(projectActions.loadAll(accessToken));
                });
            } catch (error) {
                console.error('Failed to load projects', error);
            } finally {
                setLoading(false);
            }
        };
        loadProjects();
    }, [dispatch, authenticatedCall]);

    const onPageChange = useCallback(
        (fromIdx: number, toIdx: number) => {
            setPage({ fromIdx, toIdx });
        },
        [setPage],
    );

    const toggleSidebar = () => {
        setHideSidebar(!hideSidebar);
        if (hideSidebar) {
            setHideSearchbar(true);
            setInitialized(false);
        }
    };

    const collapseSearchBar = () => {
        setHideSearchbar(!hideSearchbar);
        setInitialized(false);
    };

    const search = () => {
        let searchResult: Project[] = [];
        for (let p of projectState.projects) {
            if (
                Object.values(p.address || {})
                    .filter((value) => typeof value === 'string')
                    .some((value) => value.toLowerCase().includes(query?.toLowerCase())) ||
                Object.values(p.client || {})
                    .filter((value) => typeof value === 'string')
                    .some((value) => value.toLowerCase().includes(query?.toLowerCase())) ||
                Object.values(p.technicalContact || {})
                    .filter((value) => typeof value === 'string')
                    .some((value) => value.toLowerCase().includes(query?.toLowerCase())) ||
                (p.purchaseOrders &&
                    p.purchaseOrders.some((po) => po.number?.toLowerCase().includes(query?.toLowerCase()))) ||
                (p.quotes && p.quotes.some((quote) => quote.reference?.toLowerCase().includes(query?.toLowerCase()))) ||
                (p.billings &&
                    p.billings.some((billing) => billing.reference?.toLowerCase().includes(query?.toLowerCase())))
            ) {
                searchResult.push(p);
            }
        }
        setProjectsToDisplay(searchResult);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            search();
        }
    };

    const handleFilterChange = (filter: string) => {
        // status filtering
        if (filters.includes(filter)) {
            if (!selectedFilters.includes(filter)) {
                setSelectedFilters([...selectedFilters, filter]); //add the filter to selectedFilters
                if (selectedUsers.length === 0) {
                    // pink
                    let projectsList: Project[] = [];
                    userList.forEach((item) => {
                        projectsList = projectsList.concat(item.projects[filter as keyof FilteredProjects]);
                        for (let key of selectedFilters) {
                            projectsList = projectsList.concat(item.projects[key as keyof FilteredProjects]);
                        }
                    });
                    setProjectsToDisplay(projectsList);
                } else {
                    //blue
                    let projectsList: Project[] = [];
                    userList
                        .filter((item) => [...selectedUsers].includes(item.user.id))
                        .forEach((item) => {
                            projectsList = projectsList.concat(item.projects[filter as keyof FilteredProjects]);
                            for (let key of selectedFilters) {
                                projectsList = projectsList.concat(item.projects[key as keyof FilteredProjects]);
                            }
                        });
                    setProjectsToDisplay(projectsList);
                }
            } else {
                setSelectedFilters(selectedFilters.filter((u) => u !== filter)); //remove the filter from selectFilters
                if (selectedUsers.length === 0) {
                    if (selectedFilters.length === 1) {
                        // plain
                        let projectsList: Project[] = [];
                        userList.forEach((item) => {
                            projectsList = projectsList.concat(Object.values(item.projects).flat());
                        });
                        setProjectsToDisplay(projectsList);
                    } else {
                        // pink
                        let projectsList: Project[] = [];
                        userList.forEach((item) => {
                            for (let key of selectedFilters) {
                                if (key === filter) {
                                    continue;
                                }
                                projectsList = projectsList.concat(item.projects[key as keyof FilteredProjects]);
                            }
                        });
                        setProjectsToDisplay(projectsList);
                    }
                } else {
                    if (selectedFilters.length === 1) {
                        // grey
                        let projectsList: Project[] = [];
                        userList
                            .filter((item) => [...selectedUsers].includes(item.user.id))
                            .forEach((item) => {
                                projectsList = projectsList.concat(Object.values(item.projects).flat());
                            });
                        setProjectsToDisplay(projectsList);
                    } else {
                        // blue
                        let projectsList: Project[] = [];
                        userList
                            .filter((item) => [...selectedUsers].includes(item.user.id))
                            .forEach((item) => {
                                for (let key of selectedFilters) {
                                    if (key === filter) {
                                        continue;
                                    }
                                    projectsList = projectsList.concat(item.projects[key as keyof FilteredProjects]);
                                }
                            });
                        setProjectsToDisplay(projectsList);
                    }
                }
            }
        }
        // user filtering
        else {
            if (!selectedUsers.includes(filter)) {
                setSelectedUsers([...selectedUsers, filter]); //add the user to selectedUsers
                if (selectedFilters.length === 0) {
                    // grey
                    let projectsList: Project[] = [];
                    userList
                        .filter((item) => [...selectedUsers, filter].includes(item.user.id))
                        .forEach((item) => {
                            projectsList = projectsList.concat(Object.values(item.projects).flat());
                        });
                    setProjectsToDisplay(projectsList);
                } else {
                    //blue
                    let projectsList: Project[] = [];
                    userList
                        .filter((item) => [...selectedUsers, filter].includes(item.user.id))
                        .forEach((item) => {
                            for (let key of selectedFilters) {
                                projectsList = projectsList.concat(item.projects[key as keyof FilteredProjects]);
                            }
                        });
                    setProjectsToDisplay(projectsList);
                }
            } else {
                setSelectedUsers(selectedUsers.filter((u) => u !== filter)); //remove the user from selectedUsers
                if (selectedFilters.length === 0) {
                    if (selectedUsers.length === 1) {
                        // plain
                        let projectsList: Project[] = [];
                        userList.forEach((item) => {
                            projectsList = projectsList.concat(Object.values(item.projects).flat());
                        });
                        setProjectsToDisplay(projectsList);
                    } else {
                        // grey
                        let projectsList: Project[] = [];
                        userList
                            .filter((item) => [...selectedUsers].includes(item.user.id) && item.user.id !== filter)
                            .forEach((item) => {
                                projectsList = projectsList.concat(Object.values(item.projects).flat());
                            });
                        setProjectsToDisplay(projectsList);
                    }
                } else {
                    if (selectedUsers.length === 1) {
                        // pink
                        let projectsList: Project[] = [];
                        userList.forEach((item) => {
                            for (let key of selectedFilters) {
                                projectsList = projectsList.concat(item.projects[key as keyof FilteredProjects]);
                            }
                        });
                        setProjectsToDisplay(projectsList);
                    } else {
                        // blue
                        let projectsList: Project[] = [];
                        userList
                            .filter((item) => [...selectedUsers].includes(item.user.id) && item.user.id !== filter)
                            .forEach((item) => {
                                for (let key of selectedFilters) {
                                    projectsList = projectsList.concat(item.projects[key as keyof FilteredProjects]);
                                }
                            });
                        setProjectsToDisplay(projectsList);
                    }
                }
            }
        }
    };

    function Sidebar() {
        return (
            <div
                style={{
                    width: '320px',
                    backgroundColor: '#f0f0f0',
                    height: '100%',
                    position: 'fixed',
                    overflow: 'scroll',
                    top: 0,
                    right: 0,
                    zIndex: 1,
                }}
            >
                <Stack direction="column" sx={{ justifyContent: 'space-between', paddingX: '20px', paddingY: '35px' }}>
                    <Button
                        color="grey"
                        variant="plain"
                        sx={{ paddingLeft: '0', paddingRight: '0', width: 'fit-content' }}
                        onClick={toggleSidebar}
                    >
                        <Stack direction="row" alignItems="center">
                            <SvgIcon
                                alignmentBaseline="central"
                                sx={{ marginRight: '8px', width: '13px', height: '11px' }}
                                viewBox="0 0 13 11"
                            >
                                <RightSecondaryArrowIcon />
                            </SvgIcon>
                            <Typography level="h3" sx={{ color: 'grey.500' }}>
                                {t('filter.close')}
                            </Typography>
                        </Stack>
                    </Button>
                    {userList.length > 1 || (userList.length === 1 && userList[0].user.authId !== user?.sub) ? (
                        <>
                            <Typography fontWeight={'bold'}>{t('filter.user', { Number: userList.length })}</Typography>

                            {userList.map((item, idx) => (
                                <div key={item.user.id}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            onChange={() => handleFilterChange(item.user.id)}
                                            checked={selectedUsers.includes(item.user.id)}
                                        />
                                        {item.user.email}
                                        {/* {`${item.user.email} (${Object.values(item.projects).reduce(
                                            (sum, list) => sum + list.length,
                                            0,
                                        )})`} */}
                                    </label>
                                </div>
                            ))}
                        </>
                    ) : null}
                    <Typography fontWeight={'bold'}>
                        {t('filter.status.all', { Number: projectState.projects.length })}
                    </Typography>
                    <label>
                        <input
                            type="checkbox"
                            onChange={() => handleFilterChange('new')}
                            checked={selectedFilters.includes('new')}
                        />
                        {t('filter.status.new', {
                            Number: userList
                                .map((item) => item.projects.new.length)
                                .reduce((sum, length) => sum + length),
                        })}
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            onChange={() => handleFilterChange('technical_visit')}
                            checked={selectedFilters.includes('technical_visit')}
                        />
                        {t('filter.status.technical_visit', {
                            Number: userList
                                .map((item) => item.projects.technical_visit.length)
                                .reduce((sum, length) => sum + length),
                        })}
                    </label>

                    <label>
                        <input
                            type="checkbox"
                            onChange={() => handleFilterChange('quote')}
                            checked={selectedFilters.includes('quote')}
                        />
                        {t('filter.status.quote', {
                            Number: userList
                                .map((item) => item.projects.quote.length)
                                .reduce((sum, length) => sum + length),
                        })}
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            onChange={() => handleFilterChange('negotiation')}
                            checked={selectedFilters.includes('negotiation')}
                        />
                        {t('filter.status.negotiation', {
                            Number: userList
                                .map((item) => item.projects.negotiation.length)
                                .reduce((sum, length) => sum + length),
                        })}
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            onChange={() => handleFilterChange('delivery_and_planning')}
                            checked={selectedFilters.includes('delivery_and_planning')}
                        />
                        {t('filter.status.delivery_and_planning', {
                            Number: userList
                                .map((item) => item.projects.delivery_and_planning.length)
                                .reduce((sum, length) => sum + length),
                        })}
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            onChange={() => handleFilterChange('documents_and_invoicing')}
                            checked={selectedFilters.includes('documents_and_invoicing')}
                        />
                        {t('filter.status.documents_and_invoicing', {
                            Number: userList
                                .map((item) => item.projects.documents_and_invoicing.length)
                                .reduce((sum, length) => sum + length),
                        })}
                    </label>

                    <label>
                        <input
                            type="checkbox"
                            onChange={() => handleFilterChange('inactive')}
                            checked={selectedFilters.includes('inactive')}
                        />
                        {t('filter.status.inactive', {
                            Number: userList
                                .map((item) => item.projects.inactive.length)
                                .reduce((sum, length) => sum + length),
                        })}
                    </label>
                </Stack>
            </div>
        );
    }

    if (loading) {
        return (
            <PageTemplate greyBackground>
                <Stack height="100vh" justifyContent="center" alignItems="center">
                    <Loader />
                </Stack>
            </PageTemplate>
        );
    }

    if (projectState.projects.length === 0 && !loading) {
        return (
            <PageTemplate greyBackground>
                <Stack height="100vh" justifyContent="center" alignItems="center">
                    <Typography level="h3" textAlign={'center'} sx={{ color: theme.palette.secondary[500] }}>
                        {t('no_projects')}
                    </Typography>
                </Stack>
            </PageTemplate>
        );
    }

    return (
        <PageTemplate>
            <Stack sx={{ justifyContent: 'space-between', height: '100%', paddingX: '20px', paddingY: '40px' }}>
                <Box>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                        {hideSearchbar ? (
                            <>
                                <div
                                    style={{
                                        marginRight: '15px',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        gap: '0.5em',
                                    }}
                                >
                                    <Typography level="h1" data-test-id="projects_page">
                                        {t('your_projects')}
                                    </Typography>
                                    <Box
                                        onClick={collapseSearchBar}
                                        style={{
                                            marginRight: '20px',
                                        }}
                                    >
                                        <SearchIcon />
                                    </Box>
                                </div>
                            </>
                        ) : (
                            <div
                                style={{
                                    padding: '5px',
                                    marginRight: '15px',
                                    paddingTop: '10px',
                                    borderRadius: '810px',
                                    border: '1px solid #ccc',
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'start',
                                    gap: '0.5em',
                                    alignItems: 'center',
                                }}
                            >
                                <Box
                                    onClick={collapseSearchBar}
                                    style={{
                                        marginLeft: '15px',
                                    }}
                                >
                                    <CloseIcon />
                                </Box>

                                <input
                                    type="text"
                                    value={query}
                                    onChange={(e) => setQuery(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    tabIndex={0}
                                    role="button"
                                    style={{
                                        width: '100%',
                                        paddingBottom: '8px',
                                        border: 'none',
                                    }}
                                />
                                <Box
                                    onClick={search}
                                    style={{
                                        marginRight: '15px',
                                    }}
                                >
                                    <SearchIcon />
                                </Box>
                            </div>
                        )}

                        <Button
                            variant="outlined"
                            sx={{
                                border: 'lightgrey',
                                width: 'fit-content',
                                background: 'lightgrey',
                                color: 'black',
                            }}
                            startDecorator={
                                <Box>
                                    <FilterIcon />
                                </Box>
                            }
                            onClick={toggleSidebar}
                        >
                            {t('filter.open')}
                        </Button>
                    </Stack>
                    <List>
                        {projectsToDisplay
                            .filter((_, idx) => idx >= fromIdx && idx <= toIdx)
                            .map((project, idx) => (
                                <div key={project.id}>
                                    <ListItem sx={{ paddingX: '0px', paddingY: '0px', marginY: '16px' }}>
                                        <Stack width="100%">
                                            <ProjectOverview project={project} />
                                        </Stack>
                                    </ListItem>
                                    {idx < projectsToDisplay.length - 1 ? <ListDivider inset="gutter" /> : ''}
                                </div>
                            ))}
                    </List>
                </Box>
                <Pagination
                    count={projectsToDisplay.length}
                    pageSize={pageSize}
                    onPageChange={onPageChange}
                    fromId={fromIdx}
                    entity="project"
                />
            </Stack>
            {hideSidebar ? null : <Sidebar />}
        </PageTemplate>
    );
}
