import React from 'react';

const FlashIconBox = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.27093 1.9996H9.72788C11.2232 1.9996 11.9709 1.9996 12.542 2.29062C13.0444 2.5466 13.4529 2.95506 13.7089 3.45745C13.9999 4.02859 13.9999 4.77626 13.9999 6.2716V9.7284C13.9999 11.2237 13.9999 11.9714 13.7089 12.5426C13.4529 13.0449 13.0444 13.4534 12.542 13.7094C11.9709 14.0004 11.2232 14.0004 9.72788 14.0004H6.27093C4.77559 14.0004 4.02792 14.0004 3.45677 13.7094C2.95438 13.4534 2.54592 13.0449 2.28994 12.5426C1.99893 11.9714 1.99893 11.2237 1.99893 9.7284V6.2716C1.99893 4.77626 1.99893 4.02859 2.28994 3.45745C2.54592 2.95506 2.95438 2.5466 3.45677 2.29062C4.02792 1.9996 4.77559 1.9996 6.27093 1.9996Z"
                stroke="#00F4CD"
                strokeWidth="1.00026"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.97955 4.38998L9.72119 7.87327H6.23791L7.97955 11.3566"
                stroke="#00F4CD"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default FlashIconBox;
