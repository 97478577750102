const RadioButtonIcon = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="RadioButton">
                <circle id="Background" cx="8" cy="8.5" r="8" fill="#00F4CD" />
                <path
                    id="Check"
                    d="M6.66769 11.4006C6.57091 11.403 6.47465 11.3857 6.38475 11.3497C6.29484 11.3138 6.21318 11.26 6.14469 11.1916L4.19169 9.23757C4.06553 9.10206 3.99685 8.92289 4.00011 8.73777C4.00337 8.55265 4.07831 8.37602 4.20917 8.24503C4.34002 8.11405 4.51659 8.03893 4.7017 8.03549C4.88682 8.03204 5.06606 8.10054 5.20169 8.22657L6.66669 9.69057L10.6817 5.68057C10.8181 5.55931 10.9958 5.49482 11.1782 5.50033C11.3607 5.50583 11.5341 5.58091 11.663 5.71017C11.7919 5.83944 11.8665 6.0131 11.8714 6.19557C11.8764 6.37804 11.8114 6.55549 11.6897 6.69157L7.18969 11.1916C7.12325 11.258 7.04435 11.3106 6.95752 11.3465C6.87069 11.3824 6.77764 11.4007 6.68369 11.4006H6.66769Z"
                    fill="#1B2A50"
                />
            </g>
        </svg>
    );
};

export default RadioButtonIcon;
