import React, { ReactNode } from 'react';
import { Box, Divider, List, ListItem, Stack, SvgIcon, Typography } from '@mui/joy';
import ProjectIcon from '../atoms/icons/ProjectIcon';
import LogoutIcon from '../atoms/icons/LogoutIcon';
import ProfileIcon from '../atoms/icons/ProfileIcon';
import ProfileDarkIcon from '../atoms/icons/ProfileDarkIcon';
import { theme } from '../theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

interface MenuElementProps {
    label: string;
    onClick?: Function;
    icon: ReactNode;
    selected: boolean;
    selectedIcon?: ReactNode;
    testId: string;
}

const MenuElement = function ({ label, icon, onClick, selected, selectedIcon, testId }: MenuElementProps) {
    const { t } = useTranslation();

    return (
        <Stack
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            onClick={onClick ? () => onClick() : undefined}
            data-test-id={testId}
        >
            <Box padding="3px">
                {selectedIcon && selected ? (
                    <>{selectedIcon}</>
                ) : (
                    <SvgIcon
                        fill={selected ? theme.palette.secondary[500] : 'transparent'}
                        stroke={selected ? theme.palette.neutral[50] : theme.palette.secondary[500]}
                    >
                        {icon}
                    </SvgIcon>
                )}
            </Box>
            <Typography level={selected ? 'title-sm' : 'body-sm'}>{t(label)}</Typography>
        </Stack>
    );
};

export default function BottomBar() {
    const location = useLocation();
    const navigate = useNavigate();
    const { logout } = useAuth0();

    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    };

    const elementsList = [
        {
            label: 'menu.projects',
            icon: ProjectIcon,
            path: '/projects',
            testId: 'menu-projects',
        },
        {
            label: 'menu.profile',
            icon: ProfileIcon,
            path: '/profile',
            selectedIcon: ProfileDarkIcon,
            testId: 'menu-profile',
        },
        {
            label: 'menu.logout',
            icon: LogoutIcon,
            onClick: handleLogout,
            testId: 'menu-logout',
        },
    ];

    return (
        <>
            <Divider />
            <List
                orientation="horizontal"
                sx={{
                    display: 'flex',
                    height: '100px',
                    justifyContent: 'space-around',
                    padding: '16px 0 36px 0',
                }}
            >
                {elementsList.map(({ label, icon, path, onClick, selectedIcon, testId }) => {
                    let isSelected = false;
                    if (location.pathname === '/' && path === '/') {
                        isSelected = true;
                    } else if (path === '/') {
                        isSelected = false;
                    } else if (path && -1 !== location.pathname.indexOf(path)) {
                        isSelected = true;
                    }

                    return (
                        <ListItem key={label}>
                            <MenuElement
                                label={label}
                                icon={icon()}
                                onClick={path ? () => navigate(path) : onClick}
                                selected={isSelected}
                                selectedIcon={selectedIcon ? selectedIcon() : undefined}
                                testId={testId}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </>
    );
}
